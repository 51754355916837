.banner-cat {
  background-image: url("../images/banner_cat.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  height: 78px;
  width: 100px;
}
.banner-dog {
  background-image: url("../images/banner_dog.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  height: 78px;
  width: 100px;
}
.help-title {
  padding: 30px 0;
  font-size: 22px;
  line-height: 24px;
  color: $title-primary;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
}

@media (min-width: 767px) {
  .banner-cat {
    height: 110px;
    width: 140px;
  }
  .banner-dog {
    height: 110px;
    width: 140px;
  }
  .help-title {
    padding: 32px 0 32px;
    font-size: 28px;
    line-height: 36px;
  }
}

@media (min-width: 991px) {
  .banner-cat {
    height: 180px;
    width: 230px;
  }
  .banner-dog {
    height: 180px;
    width: 230px;
  }
  .help-title {
    padding: 0;
    font-size: 36px;
    line-height: 59px;
  }
}
