.shift-box {
  box-shadow: 0 2px 4px 0 rgba(65, 40, 148, 0.2);
  background: #fff;
  padding-bottom: 120px;
  width: 100%;
  padding-top: 32px;
  overflow: hidden;
  height: 100%;
  border-radius: 12px;
  &.fixed-height{
    height: 440px;
  }
  &.fixed-height-tall{
    height: 612px;
  }
  &.selected-border {
    border: 2px solid #412894;
    .shift-box-head {
      border: 2px solid #412894;
    }
  }
  &.selected-border-hover {
    border: 2px solid #412894;
    .shift-box-head {
      border: 2px solid #412894;
    }
  }
  .shift-box-head {
    font-family: "Jost", sans-serif;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    font-size: 16px;
    line-height: 24px;
    color: $title-primary;
    height: 32px;
    padding: 8px;
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    background: #fff;
    &.map-adjust {
      width: 100%;
      left: 0;
      right: 0;
    }
    &.blue-bar {
      border-bottom: $title-primary solid 4px;
      // z-index: 1; // caused bug with shiftGrid.js zip dropdown, doesn't appear to be needed
      // ??? accordion still used here ??? commented out to see if used
      //   .accordion__item {
      //     .accordion__button {
      //       cursor: pointer;
      //       font-family: "Poppins", sans-serif;
      //       &:after {
      //         content: "";
      //         width: 10px;
      //         height: 10px;
      //         background: url(/3fe763edc617262d9aa460915db179c4.png) center center no-repeat;
      //         position: absolute;
      //         right: 15px;
      //         top: 0;
      //         bottom: 0;
      //         margin: 22px auto 0;
      //         transform-style: preserve-3d;
      //       }
      //     }
      //     .accordion__button[aria-expanded="true"] {
      //       &:after {
      //         transform: rotateX(-180deg);
      //         margin: 22px auto 0;
      //       }
      //     }
      //     .accordion__panel {
      //       overflow: hidden;
      //     }
      //   }
    }
    span {
      display: block;
    }
  }
  .shift-box-body {
    padding: 8px 8px 0;
    .top-row {
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      .img-wrap {
        width: 72px;
        height: 72px;
        border-radius: 100px;
        overflow: hidden;
        box-shadow: 0 2px 8px 0 rgba(65, 40, 148, 0.09);
        float: left;
        img {
          width: 72px;
          height: 72px;
          border-radius: 100px;
        }
      }
      .img-wrap-small {
        width: 42px;
        height: 42px;
        border-radius: 100px;
        overflow: hidden;
        box-shadow: 0 2px 8px 0 rgba(65, 40, 148, 0.09);
        float: left;
        img {
          width: 42px;
          height: 42px;
          border-radius: 100px;
        }
      }
      .name {
        width: calc(100% - 72px);
        float: left;
        padding-left: 16px;
        h6 {
         font-weight: 500;
         font-size: 16px;
         line-height: 24px;
         color: #303031;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         margin-bottom: 4px;
        }
        a {
          font-weight: 500;
          letter-spacing: 1px;
          display: inline-block;
        }
      }
    }
    p {
      @extend .body-text;
      @extend .gray-base;
      margin-bottom: 14px;
      span {
        font-weight: 600;
      }
    }
    .view-profile-button {
      @extend .view-profile-button-v2;
    }
  }
  .shft-box-footer {
    padding: 15px 12px 0;
    min-height: 70px;
    max-height: 120px;
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
    text-align: center;
    &.map-view {
      top: 361px;
      &.expired-shft {
        top: 343px;
      }
      &.requested-shift {
        top: 340px;
      }
      &.tall {
        top: 533px;
        &.expired-shft {
          top: 515px;
        }
      }
    }
    // display: flex;
    display: block;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    // align-items: flex-end!important;
    span {
      display: block;
      &.danger {
        color: #ff4747;
        text-align: center;
        font-size: 14px;
      }
    }
    .btn {
      min-width: inherit;
      margin-top: 8px;
      &-primary-v2 {
        font-weight: 500 !important;
        font-family: "Jost", sans-serif !important;
        background-color: $title-primary !important;
      }
    }
    .message {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: #404040;
      margin: 0;
      span.countFixWidth {
        color: #418b6f;
        // width: 145px;
        // display: inline-block;
        // text-align: left;
      }
    }
  }
  .moreInfoMessage {
    background: #fff;
    z-index: 999;
    position: relative;
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
  .event-box-body {
    margin-top: 50px;
    padding: 0px 12px 0;
    .top-row {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      .img-wrap {
        width: 72px;
        height: 72px;
        border-radius: 100px;
        overflow: hidden;
        box-shadow: 0 2px 8px 0 rgba(65, 40, 148, 0.09);
        float: left;
        img {
          width: 72px;
          height: 72px;
          border-radius: 100px;
        }
      }
      .name {
        width: calc(100% - 72px);
        float: left;
        padding-left: 16px;
        h6 {
          @extend .body-text;
          color: $gray-light-400;
          margin-bottom: 4px;
        }
        a {
          font-weight: 500;
          letter-spacing: 1px;
          display: inline-block;
        }
      }
    }
    p {
      @extend .body-text;
      @extend .gray-base;
      margin-bottom: 14px;
      span {
        font-weight: 600;
      }
    }
    .view-profile-button {
      @extend .view-profile-button-v2;
    }
  }
}

.event-box-head {
  font-family: "Jost", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 28px;
  color: $green-dark;
  padding: 8px;
  min-height: 67px;
  position: absolute;
  left: 15px;
  right: 15px;
  top: 0;
  background: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  &.map-adjust {
    width: 100%;
    left: 0;
    right: 0;
  }
  &.green-bar {
    border-bottom: #339570 solid 4px;
  }
  span {
    display: block;
  }
}
.event-tag {
  font-family: "Poppins", sans-serif;
  display: inline-block;
  padding: 0px 8px;
  background: #e6f2ee;
  border-radius: 5px;
  font-size: 12px;
  line-height: 20px;
  color: $dark-gray;
}

.event-button {
  background-color: #007a4c;
  height: 45px;
  width: 113px;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
  border-radius: 24px;
  border: none;
  margin: 0 auto;
  :hover {
    opacity: 0.8;
  }
}

.event-label {
  /* Label */

  position: static;
  left: 14.81%;
  right: 14.81%;
  top: 0%;
  bottom: 0%;

  /* Body/Small Body Text */

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Grey/Base */

  color: #535353;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

.shift-tag {
  font-family: "Poppins", sans-serif;
  display: inline-block;
  padding: 0px 8px;
  background: $light-blue;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
  color: $dark-gray;
}

.shift-label {
  /* Label */

  position: static;
  left: 14.81%;
  right: 14.81%;
  top: 0%;
  bottom: 0%;

  /* Body/Small Body Text */

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #535353;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

// ---- mobile view
// 767, 576
@media all and (max-width: 767px) {
  .shift-box,
  .internship-card {
    box-shadow: 0 2px 8px 0 rgba(65, 40, 148, 0.09);
    padding-bottom: 120px;
    width: 100%;
    padding-top: 31px;
    overflow: hidden;
    .shift-box-head {
      font-size: 18px;
      line-height: 29px;
      color: $title-primary;
      height: 32px;
      position: absolute;
      padding: 8px;
      left: 15px;
      right: 15px;
      top: 0;
      background: #fff;
      &.map-adjust {
        width: 100%;
        left: 0;
        right: 0;
      }
    }
    .shift-box-body {
      padding: 12px 12px 0;
    }
    .shft-box-footer {
      padding: 15px 12px 0;
    }
  }
}

.shift-box-flex {
  color: #535353;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px !important;
  span {
    color: #ACACAD;
    font-weight: 500;
  }
}
.shift-box-light {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #59595A;
  margin-bottom: 4px;
  span {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    color: #59595A !important;
    margin-bottom: 0px !important;
  }
}

.callout-button {
  border-bottom: none !important;
  img {
    height: 20px;
    width: 20px;
  }
  a {
    margin-left: 5px;
    font-weight: 500;
    line-height: 1;
    color: #9ea2a1 !important;
    border-bottom: 1px solid;
    font-size: 13px;
    &:hover {
      color: black !important;
    }
  }
}

.shift-box-watermark {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0px;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  div {
    user-select: none;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 70px;
    color: rgba(52, 32, 117, 0.28);
    transform: rotate(-45deg);
  }
}
