$roo-dark-purple: #412894;
$roo-light-green: #007a4c;
$roo-light-purple: #5862a3;
$roo-light-purple-400: #6753a9;
$roo-base-background: #edf0fd;

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

#root,
body {
  button.close.rbt-close {
    position: absolute;
    right: 17px;
    top: calc(50% - 12px);
  }
  .rbc-overlay {
    width: 230px !important;
    max-height: 400px !important;
    overflow: auto !important;
  }
  .well-bg {
    background: $well-bg;
  }
  .page-wrapper {
    overflow: hidden;
  }
  .keep-scroll-on-load {
    height: 1000px;
  }
  &.no-scroll {
    overflow: hidden;
  }
  .modal {
    box-shadow: 0 2px 15px 0 rgba(65, 40, 148, 0.22);
    .overlay {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #3333337a;
      opacity: 0;
    }
    .modal-dialog {
      max-width: 690px;
      align-items: normal;
      padding: 0 20px;
      max-height: calc(100% - (1.75rem * 2));
      &.sm-width {
        max-width: 600px;
      }
    }
    .lg-model {
      max-width: 950px;
      max-height: calc(100% - (1.75rem * 3));
      padding: 30px;
    }
    .lg-modal-height {
      padding-right: 0;
      max-height: 930px;
      top: -4%;
      overflow-x: hidden;
    }
    &.sm-modal {
      .modal-dialog {
        align-items: center;
        top: 50%;
        margin: auto;
        padding: 0 20px;
        transform: translateY(-50%) !important;
        justify-content: center;
      }
    }
    .modal-header {
      padding: 0;
      height: 30px;
      border: 0;
      .close {
        width: 22px;
        right: 26px;
        top: 24px;
        position: absolute;
        opacity: 1;
        background: url("../images/close_ic.svg") no-repeat center center;
        @include transition;
        &:hover {
          opacity: 0.7;
          @include transition;
        }
        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):hover {
          outline: none;
          box-shadow: none;
        }
      }
      .close-dark-grey {
        width: 22px;
        right: 30px;
        top: 32px;
        position: absolute;
        opacity: 1;
        background: url("../images/close_dark_grey.svg") no-repeat center center;
        transform: scale(1.3);
        @include transition;
        &:hover {
          opacity: 0.7;
          @include transition;
        }
        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):hover {
          outline: none;
          box-shadow: none;
        }
      }
    }
    .modal-content {
      border: none;
      opacity: 0;
      border-radius: 4px;
      transform: translate(0, -25%);
      max-height: 90vh;
      .modal-header {
        z-index: 9;
        &.h-auto {
          height: auto;
        }
      }
      .no-scroll-body {
        overflow-x: hidden !important;
      }
      .modal-body {
        padding: 28px;
        overflow: auto;
        display: flex;
        .close-btn {
          border: none;
          background-color: #fff;
          cursor: pointer;
          img {
            height: 14px;
            width: 14px;
          }
          @include transition;
          &:hover {
            opacity: 0.7;
            @include transition;
          }
          &:not(:disabled):not(.disabled):focus,
          &:not(:disabled):not(.disabled):hover {
            outline: none;
            box-shadow: none;
          }
        }
        h3 {
          color: $title-primary;
          @extend .primaryFont;
          font-size: 24px;
          line-height: 34px;
          font-weight: normal;
          span {
            font-size: 18px;
          }
          &.long {
            font-size: 22px;
            line-height: 30px;
          }
          &.long-2 {
            font-size: 20px;
            line-height: 28px;
          }
        }
        h4 {
          color: $title-primary;
          @extend .primaryFont;
          font-size: 14px;
          line-height: 26px;
          font-weight: normal;
        }
        p,
        ol li,
        ul li {
          @extend .secondaryFont;
          color: $text-secondary;
          font-weight: normal;
        }
        .addressWrap {
          margin-left: 20px;
        }

        label,
        label div h4 {
          font-size: 18px;
          color: $title-secondary;
          letter-spacing: 1.5px;
        }
        form .btn {
          width: 200px;
          text-transform: uppercase;
          margin-top: 20px;
        }
        .messages {
          font-size: 26px;
          line-height: 46px;
          text-align: center;
          width: 100%;
          &.success-message {
            color: #412894;
            &.sm {
              font-size: 22px;
              line-height: 30px;
              margin-bottom: 0;
            }
            &.tandc-box {
              .text-danger {
                font-size: 14px !important;
                display: block;
              }
            }
            .message-body {
              font-size: 16px;
              line-height: 26px;
            }
          }
          &.stripe-error-message {
            color: #412894;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            width: 100%;
            margin-bottom: 10px;
          }
          &.stripe-error-header {
            color: #412894;
            font-weight: 600;
            font-size: 18px;
          }
        }
      }
      .modal-body-wrapper {
        display: flex;
        flex-direction: column;
        .body-content {
          overflow: auto;
        }
      }
      .privacy-table {
        .table-responsive {
          td {
            word-break: break-word;
          }
        }
      }
    }
    &.fade.show {
      .overlay {
        opacity: 1;
        animation: 200ms ease normal forwards 1 overlay;
        -webkit-animation: 200ms ease normal forwards 1 overlay;
      }
      .modal-content {
        animation: 200ms ease normal forwards 1 fadein;
        -webkit-animation: 200ms ease normal forwards 1 fadein;
        opacity: 1;
        transform: translate(0, 0);
      }
    }
    .sm-modal-close {
      display: none;
    }
  }
  .photo-guide {
    padding: 10px 0;
    // padding: 26px 0;
    // border-bottom: 1px solid #B9C1EC;
    ul {
      margin: 20px 0 10px;
      list-style: none;
      border: 1px solid #d8dcf4;
      background-color: #fafafc;
      padding: 50px 38px;
      display: none;
      li {
        padding-bottom: 40px;
        &:last-child {
          padding: 0;
        }
        h3 {
          @extend .primaryFont;
          color: $text-primary;
          font-size: 30px;
          font-weight: 500;
          line-height: 49px;
          margin: 0;
        }
        span {
          color: $title-secondary-lighter;
          @extend .primaryFont;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 1.67px;
          line-height: 32px;
        }
        h6 {
          @extend .primaryFont;
          color: $text-primary;
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
          margin: 2px 0 10px;
        }
        p {
          @extend .primaryFont;
          color: $para-primary;
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
  .w9-wrap {
    border: 1px dashed #95a3ed;
    border-radius: 20px;
    background-color: rgba(149, 163, 237, 0.05);
    padding: 50px 50px 80px;
    margin: 30px 0;
    text-align: center;
    position: relative;
  }

  .thumb {
    margin-right: 6px;
    width: 18px;
    height: 18px;
    display: block;
    float: left;
    margin-left: 6px;
    transition: all 200ms ease-in-out;
  }

  .picture-thumb-filled {
    background: url("../images/picture_filled.svg") center center no-repeat;
  }

  .picture-thumb-outlined {
    background: url("../images/picture_fill_outline.svg") center center no-repeat;
  }

  .droptarget {
    border-color: white !important;
    border-width: 2px !important;
    background-color: #95a3ed !important;

    padding: 1px !important;
    border-style: dashed !important;
    .drag-here-text {
      display: block;
      margin-top: 65px;
    }
  }

  .profile-photo-wrap {
    margin-top: 48px !important;
    .upload-top-row {
      .drop-wrapp {
        cursor: pointer;
      }
    }
    padding: 0px 10px 17px !important;
    border-radius: 8px !important;
  }

  .upload-photo-wrap {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #b6bff4;
    border-radius: 8px;
    background-color: #edf0fd;
    padding: 20px;
    min-height: 156px;
    width: 100%;
    margin: 16px 0 36px;
    text-align: center;
    position: relative;
    .custom-loader {
      position: absolute;
      left: 1px;
      right: 1px;
      top: 1px;
      bottom: 1px;
      width: auto;
      height: auto;
      z-index: 9;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 18px;
      &:after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 30px;
        height: 30px;
      }
    }

    .drag-drop {
      @extend .secondaryFont;
      color: $text-secondary;
      font-size: 16px;
      line-height: 22px;
      // background: url("../images/upload_arrow.svg") top center no-repeat;
      padding: 40px 0 0;
      @media screen and (max-width: 420px) {
        padding-top: 0px;
        padding-bottom: 20px;
      }
    }
    .drop-wrapp {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 11;
      & > div {
        position: absolute !important;
        width: auto !important;
        height: auto !important;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border: 0 !important;
        background: none !important;
      }
    }
    &.image-in {
      // image AND resume drop
      padding: 0;
      min-height: 68px;
      .upload-top-row {
        display: flex;
        padding: 20px 30px;
        padding-bottom: 50px;
        border-bottom: 2px solid #b9c1ec;
        & > .item {
          &.or {
            flex: 3 0px;
            margin: 10px 20px;
            &:before {
              width: auto;
              height: 2px;
              background: #b9c1ec;
            }
            i {
              line-height: 28px;
            }
          }
          &.upload-btn {
            padding: 8px 0 0px;
            .btn {
              width: 174px;
              max-width: inherit;
              padding: 5px 20px 4px;
            }
          }
        }
        .drag-drop {
          padding: 0 0 0 55px;
          background-position: 6px center;
          height: 50px;
          line-height: 18px;
          font-size: 14px;
        }
        @media screen and (max-width: 420px) {
          display: block;
          .drag-drop {
            padding: 0px;
          }
        }
      }
      .resume-wrap {
        margin: 0;
        padding: 20px;
        list-style: none;
        margin-left: -10px;
        margin-right: -10px;
        text-align: left;
        li {
          padding: 10px;
          height: 100px;
          overflow: hidden;
          position: relative;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
        }
      }
      .thumb-wrap {
        margin: 0;
        padding: 20px;
        list-style: none;
        margin-left: -10px;
        margin-right: -10px;
        overflow: hidden;
        li {
          width: 25%;
          float: left;
          padding: 10px;
          height: 100px;
          overflow: hidden;
          position: relative;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          .img-wrap {
            border: 1px solid #d8dae2;
          }
          img {
            max-width: 100%; //min-height: 100px;
          }
          .actions {
            position: absolute;
            right: 0;
            top: 0;
            padding: 8px 1px;
            span {
              width: 18px;
              height: 18px;
              display: block;
              float: left;
              margin-left: 6px;
              cursor: pointer;
              transition: all 200ms ease-in-out;
              &:hover {
                transform: scale(0.9);
                transition: all 200ms ease-in-out;
              }
            }
            .expand {
              background: url("../images/expand_photo.svg") center center no-repeat;
            }
            .remove {
              background: url("../images/delete_photo.png") center center no-repeat;
            }
          }
        }
      }
    }
    .or {
      margin: 32px 0;
    }
    .btn.btn-primary.btn-outline-primary {
      width: auto;
      min-width: inherit;
      padding: 6px 30px;
      font-weight: 500;
    }
    .upload-btn {
      position: relative;
      padding: 4px;
      .btn {
        position: relative;
        overflow: hidden;
        cursor: pointer;
        box-shadow: 0 2px 3px 0 rgba(134, 134, 134, 0.22);
      }
      input {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
      }
    }
    .img-preview {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      justify-content: center;
      align-items: center;
      background: rgba($color: #000000, $alpha: 0.7);
      display: none;
      .img-wrap {
        max-height: 80%;
        max-width: 80%;
        min-height: 100px;
        min-width: 200px;
        background: #fff;
        border-radius: 4px;
        position: relative; //overflow: hidden;
        box-shadow: #313131 0 0 10px;
        img {
          max-height: 100%;
          max-width: 100%;
        }
        & > span {
          position: absolute;
          height: 34px;
          width: 34px;
          background: #fff url("../images/close-modal.png") center center no-repeat;
          background-size: 19px;
          right: -17px;
          top: -17px;
          border-radius: 100px;
          box-shadow: #313131 0 0 10px;
          cursor: pointer;
        }
      }
    }
    &.profileImageWrap {
      .img-preview {
        max-height: 100%;
        .img-wrap {
          height: 100%;
          max-height: 90%;
          background: transparent;
          box-shadow: none;
        }
      }
    }
  }
  .training-container {
    position: relative;
    padding-top: 60px;
    .lft-arrow-green-dark {
      position: absolute;
      left: 15px;
      top: 0;
      z-index: 10;
      color: #007a4c;
      text-decoration: underline;
    }
    .rt-arrow {
      position: absolute;
      left: auto;
      right: 15px;
      top: 0;
      z-index: 10;
      padding-right: 15px;
      &:before {
        content: "";
        transform: rotate(180deg);
        right: 0;
        left: auto;
      }
    }
    .training-title {
      display: flex;
      flex-direction: column;
      position: relative;
      top: -22px;
      div {
        color: #412894;
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
        @media (max-width: 767px) {
          font-size: 28px;
          line-height: 42px;
          margin-top: 50px;
        }
      }
      span {
        font-family: "Jost", sans-serif;
        font-weight: 500;
        font-size: 32px;
        line-height: 46.24px;
        color: #59595a;
        @media (max-width: 767px) {
          font-size: 24px;
          line-height: 34.68px;
        }
      }
    }
    .training-tracker {
      width: 274px;
      height: 437px;
      padding: 44px 14px 69.25px 16px;
      display: flex;
      flex-direction: column;
      background: #fff;
      border-radius: 12px;
      align-items: flex-start;
      justify-content: space-between;
      @media (max-width: 767px) {
        width: auto;
        padding: 16px 82px 17px 20px;
        height: 87px;
        flex-direction: row;
      }
      span {
        height: 300px;
        width: 0px;
        border-right: 1px solid #412894;
        position: absolute;
        top: 220px;
        left: 43px;
        @media (max-width: 767px) {
          height: 0px;
          width: 190px;
          top: 155px;
          border-right: none;
          border-bottom: 1px solid #412894;
        }
      }
      div {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: #ebebeb;
        padding-left: 7px;
        z-index: 3;
        font-weight: 500;
        font-size: 16px;
        color: #242425;
        &.selected {
          background: #dbdffa;
        }
        &.completed {
          background: #311e6f;
          img {
            position: relative;
            left: -3px;
          }
        }
        &.first {
          padding-left: 9px;
        }
        &.last {
          padding-left: 6px;
        }
        div {
          width: 220px;
          margin-left: 16px;
          background-color: transparent;
          position: relative;
          top: -26px;
          font-family: "Jost", sans-serif;
          font-weight: 400;
          font-size: 20px;
          line-height: 28.9px;
          color: #59595a;
          &.first {
            margin-left: 15px;
          }
          &.last {
            margin-left: 18px;
          }
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 26px;
          }
        }
      }
    }
    .contact-us-mobile {
      width: auto;
      height: 40px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      background-color: #fff;
      margin-top: 20px;
      span {
        font-family: "Jost", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 23.12px;
        color: #412894;
      }
    }
    .contact-us-container {
      height: 181px;
      width: 274px;
      border-radius: 12px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 14px 0 14px 33px;
      margin-top: 20px;
      @media (max-width: 767px) {
        width: auto;
        padding: 12px 0 12px 20px;
      }
      div {
        color: #412894;
        font-family: "Jost", sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 23.12px;
        }
        span {
          font-weight: 400;
          font-size: 20px;
          line-height: 28.9px;
          color: #303031;
          margin-left: 8px;
          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
    .training-slide-container {
      position: relative;
      left: 335px;
      top: -640px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      max-width: 832px;
      @media (max-width: 767px) {
        position: static;
        max-width: auto;
      }
      .training-slide {
        max-width: 832px;
        min-height: 428px;
        border-radius: 16px;
        padding: 36px;
        border: 1px solid #412894;
        box-shadow: 0px 2px 16px rgba(65, 40, 148, 0.2);
        display: flex;
        flex-direction: column;
        background: #fff;
        margin-bottom: 24px;
        &.no-mb {
          margin-bottom: 0;
        }
        &.no-pd {
          padding: 24px 0 0 0;
        }
        &.mobile {
          div {
            font-family: "Jost", sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: #412894;
            padding-left: 20px;
          }
          ul {
            font-weight: 500;
            color: #303031;
            font-size: 12px;
            line-height: 18px;
            padding: 0 20px 0 30px;
            margin: 4px 0 0 0;
          }
          .informal-m {
            background: #f5e7a4;
            height: 49px;
            width: 100%;
            border-radius: 0 0 16px 16px;
            margin-top: 12px;
            font-family: "Jost", sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #100a25;
            display: flex;
            align-items: center;
            padding-right: 50px;
            img {
              padding: 0 8px 0 16px;
            }
          }
          .formal-m {
            background: #f1da77;
            height: 65px;
            width: 100%;
            border-radius: 0 0 16px 16px;
            margin-top: 12px;
            font-family: "Jost", sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #100a25;
            display: flex;
            align-items: center;
            padding-right: 50px;
            img {
              padding: 0 8px 0 16px;
              // display: flex;
              // align-self: flex-start;
              // margin-top: 12px;
            }
          }
          .suspension-m {
            background: #e81616;
            height: 60px;
            width: 100%;
            margin-top: 12px;
            font-family: "Jost", sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #fff;
            display: flex;
            align-items: center;
            padding-right: 50px;
            img {
              padding: 0 8px 0 16px;
              // display: flex;
              // align-self: flex-start;
              // margin-top: 10px;
            }
          }
          .permanent-suspension-m {
            background: #ae1111;
            border-radius: 0 0 16px 16px;
            height: 42px;
            width: 100%;
            font-family: "Jost", sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #fff;
            display: flex;
            align-items: center;
            padding-right: 50px;
            img {
              padding: 0 8px 0 16px;
            }
          }
        }
        @media (max-width: 767px) {
          margin-top: 20px;
          width: auto;
          height: auto;
          min-height: auto;
          padding: 24px 20px 24px 20px;
        }
        .slide-title {
          font-family: "Jost", sans-serif;
          font-weight: 400;
          font-size: 24px;
          line-height: 35px;
          color: #412894;
          margin-bottom: 24px;
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 8px;
          }
        }
        .slide-sub-title {
          font-family: "Jost", sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #412894;
          margin-top: 24px;
          background: url("../images/tiny-roo.svg") no-repeat;
          padding-left: 30px;
          &.underline {
            text-decoration: underline;
            img {
              margin-right: 8px;
            }
          }
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 23px;
            margin-top: 20px;
          }
        }
        // .slide-sub-title:before {
        // }
        .slide-body {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #303031;
          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 18px;
          }
        }
        .slide-body-bold {
          color: #000;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          margin-top: 8px;
          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 18px;
          }
          span {
            font-weight: 700;
          }
        }
        .extra-margin {
          margin-left: 32px;
          @media (max-width: 767px) {
            margin-left: 30px;
          }
        }
        .warning-text {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          line-height: 18px;
          color: #303031;
          font-weight: 700;
          &.white {
            color: #fff;
          }
        }
        .informal-warning {
          width: 150px;
          height: 73px;
          position: relative;
          background: #f5e7a4;
          margin-top: 36px;
        }
        .informal-warning:before {
          content: "";
          position: absolute;
          right: -36.5px;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 36.5px solid #f5e7a4;
          border-top: 36.5px solid transparent;
          border-bottom: 36.5px solid transparent;
        }
        .formal-warning {
          width: 150px;
          height: 73px;
          position: relative;
          background: #f1da77;
          margin: 36px 0 0 36px;
          padding-left: 45px;
        }
        .formal-warning:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 36.5px solid #fff;
          border-top: 36.5px solid transparent;
          border-bottom: 36.5px solid transparent;
        }
        .formal-warning:before {
          content: "";
          position: absolute;
          right: -36.5px;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 36.5px solid #f1da77;
          border-top: 36.5px solid transparent;
          border-bottom: 36.5px solid transparent;
        }
        .suspension {
          width: 150px;
          height: 73px;
          position: relative;
          background: #e81616;
          margin: 36px 0 0 36px;
          padding-left: 50px;
          white-space: nowrap;
          z-index: 3;
        }
        .suspension:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 36.5px solid #fff;
          border-top: 36.5px solid transparent;
          border-bottom: 36.5px solid transparent;
        }
        .suspension:before {
          content: "";
          position: absolute;
          right: -36.5px;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 36.5px solid #e81616;
          border-top: 36.5px solid transparent;
          border-bottom: 36.5px solid transparent;
          z-index: -1;
        }
        .permanent-suspension {
          width: 150px;
          height: 73px;
          position: relative;
          background: #ae1111;
          margin: 36px 0 0 56px;
          padding-left: 50px;
        }
        .permanent-suspension:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 36.5px solid #fff;
          border-top: 36.5px solid transparent;
          border-bottom: 36.5px solid transparent;
        }
        .offenses-container {
          border: 1px solid #412894;
          box-shadow: 0px 2px 16px rgba(65, 40, 148, 0.2);
          border-radius: 16px;
          margin: 34px 12px 0 0;
          padding: 8px 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          &:last-child {
            margin-right: 0;
          }
          span {
            font-family: "Jost", sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            text-decoration: underline;
            color: #412894;
          }
          ul {
            padding-left: 10px;
            color: #242425;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            margin: 8px 0 0 0;
          }
        }
      }
    }
  }
  .carousel {
    position: relative;
    &#trainingSlider {
      height: 600px;
      padding: 50px 30px;
      //overflow: hidden;
      position: relative;
      .finish-training {
        position: absolute;
        right: 100px;
        top: -40px;
        display: none;
      }
    }
    .slick-slider-wrapper .slick-slider {
      padding: 0 50px;
      .slick-arrow.slick-prev,
      .slick-arrow.slick-next {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 20px;
        width: 36px;
        background: url("../images/dropdown_arrow.svg") center center no-repeat;
        background-size: 100% auto;
        opacity: 1;
        cursor: pointer;
        @extend .transition;
        border: 0;
        text-indent: -999em;
        overflow: hidden;
        &:hover {
          opacity: 0.6;
          @extend .transition;
          &::after {
            display: none;
          }
        }
        &:focus {
          outline: none;
        }
      }
      .slick-arrow.slick-prev {
        transform: rotate(90deg);
        left: 0;
        display: none;
      }
      .slick-arrow.slick-next {
        transform: rotate(-90deg);
        right: 0;
      }
      .slick-list {
        border: 1px solid #7b87cc;
        box-shadow: 0 3px 7px 0 #dddddd;
        .slick-track {
          .slick-slide {
            padding: 60px;
            height: 560px;
            display: flex;
            align-items: center;
            & > div:first-child,
            div[tabindex="-1"] {
              display: flex !important;
              width: 100%;
            }
            h3 {
              @extend .primaryFont;
              color: $text-primary;
              font-size: 26px;
              font-weight: 500;
              line-height: 43px;
              position: relative;
              display: inline-block;
              margin-top: -10px;
              &.text-underline:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -6px;
                height: 2px;
                background: #bcc3ec;
              }
            }
            div[class^="col-"],
            div[class*=" col-"] {
              text-align: left;
              &:last-child {
                padding-right: 0;
                text-align: center;
                &.bottom-ill {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                }
              }
              h3 {
                @extend .primaryFont;
                color: $text-primary;
                font-size: 23px;
                font-weight: 500;
                line-height: 30px;
                margin-bottom: 14px;
                margin-top: 5px;
                display: block;
              }
              h4 {
                @extend .primaryFont;
                color: $text-primary;
                font-size: 16px;
                font-weight: 500;
                line-height: 23px;
                margin-bottom: 2px;
              }
              span {
                @extend .primaryFont;
                color: $title-secondary-lighter;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 1.33px;
                line-height: 26px;
              }
              p,
              ul li {
                @extend .primaryFont;
                color: $para-primary;
                font-size: 14px;
                line-height: 23px;
              }
              &.sm-list {
                p {
                  margin-bottom: 4px;
                }
              }
              ul {
                list-style: none;
                margin: 10px 0;
                padding: 0;
                li {
                  padding: 0 0 0 24px;
                  position: relative;
                  &:before {
                    content: "";
                    height: 4px;
                    width: 4px;
                    position: absolute;
                    left: 0;
                    top: 9px;
                    background: $para-primary;
                    border-radius: 10px;
                  }
                  strong {
                    color: $text-primary;
                  }
                }
                &.sm-text {
                  li {
                    font-size: 12px;
                    line-break: 19px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .hospital-shifts-container {
    .shift-box {
      border-bottom: 12px solid $title-primary;
      padding-top: 0px;
      padding-bottom: 0px;
      .shift-box-head {
        position: inherit !important;
      }
      .shift-box-body {
        .top-row {
          padding-bottom: 10px;
        }
      }
      .shft-box-footer {
        display: none !important;
      }
    }

    .hospital-shift-image-holder {
      margin-top: 25px;
      .shift-rounded-image-holder {
        position: relative;
        width: 155.61px;
        height: 155.61px;
        padding: 0px;
        margin: 12.5px 12.5px;

        &:hover {
          .actions {
            opacity: 1;
            background: #262626c4;
          }
        }

        .img-wrap {
          border: 1px solid #d8dae2;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          backface-visibility: hidden;
        }
        .actions {
          opacity: 0;
          position: absolute;
          text-align: center;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;

          span {
            font-size: 16px;

            height: 24px;
            width: 24px;
            display: block;
            float: left;
            margin-left: 6px;
            cursor: pointer;
            transition: all 0.1s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: all 0.1s ease-in-out;
            }
          }
          .expand {
            border-radius: 5px;
            padding: 0;
            float: right;
            background: url("../images/expand-filled.svg") center center no-repeat;
            background-color: #007a4c !important;
            margin: 8px;
          }
          .remove {
            margin: 8px;
            border-radius: 50%;
            background: url("../images/close-filled.svg") center center no-repeat;
          }
          @media (max-width: 767px) {
            top: 15px;
            right: 10px;
            left: 10px;
            width: auto;
          }
        }
        .hospital-shift-thumb {
          width: 100%;
          border-radius: 50%;
          height: 100%;
        }
      }
    }

    .image-cropper-wrap {
      .upload-btn {
        input {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
  }

  .slide-show-box {
    margin: auto;
    .slideshow-holder {
      .slick-slider {
        height: 100%;
        .slick-arrow {
          height: 36px;
          width: 36px;
          overflow: hidden;
          text-indent: -999em;
          outline: none;
          border: 0;
          box-shadow: none;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          cursor: pointer;
          background-color: transparent;
          background-size: auto;
          z-index: 10;
        }
        .slick-list {
          overflow: visible !important;
          height: 100%;
          .slick-track {
            height: 100%;
            .slick-slide {
              max-height: 100%;
              transform: scale(0.8);
              transition: all 0.2s ease-in-out;
              padding: 0px 35px;
              .close-button {
                display: none;
              }
              div:not(.slideshow-image-holder) {
                display: flex !important;
                align-items: center;
                height: 100%;
                .slideshow-image-holder {
                  max-height: 100%;
                }
              }
            }
            .slick-current {
              transform: scale(1) !important;
              transition: all 0.2s ease-in-out;
              padding: 0px !important;
              .close-button {
                display: block !important;
              }
            }
          }

          .slideshow-image-holder {
            .close-button {
              background: url("../images/close-filled.svg") center center no-repeat;
              height: 34px;
              width: 34px;
              border-radius: 50%;
              cursor: pointer;
              border: 4px solid #fff;
              float: right;
              position: absolute;
              right: -20px;
              margin-top: -20px;
            }
            img {
              width: 100%;
              margin: auto;
              box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
            }
          }
        }
        .slick-prev {
          left: -49px;
          background: url("../images/arrow_left_blue.svg") 50% no-repeat;
        }
        .slick-next {
          right: -49px;
          background: url("../images/arrow_left_right.svg") 50% no-repeat;
        }
      }
    }
  }
  .hospital-edit-profile-shift-box {
    max-width: 360px !important;
    margin: 0;
  }
  .hospital-edit-profile {
    .thumb-wrap {
      .thumb-as-upload-wrapper {
        .upload-photo-wrap {
          .upload-top-row {
            max-height: 100%;
            .drop-wrapp {
              cursor: pointer;
            }
          }
          padding: 10px !important;
          margin: 0px !important;
          height: 100%;
        }
      }
      margin: 0;
      margin-top: 20px !important;
      padding: 10px 0 0;
      list-style: none;
      margin-left: -10px;
      margin-right: -10px;
      overflow: hidden;
      flex-wrap: wrap;
      align-items: center;
      display: flex;
      li {
        position: relative;
        width: 210px;
        height: 155.61px;
        padding: 0px;
        margin: 12.5px 12.5px;
        text-align: center;
        display: inline;
        &:hover {
          .actions {
            opacity: 1;
            background: #262626c4;
          }
        }

        .img-wrap {
          border: 1px solid #d8dae2;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          backface-visibility: hidden;
        }
        .actions {
          opacity: 0;
          position: absolute;
          text-align: center;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;

          span {
            font-size: 16px;

            height: 24px;
            width: 24px;
            display: block;
            float: left;
            margin-left: 6px;
            cursor: pointer;
            transition: all 0.1s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: all 0.1s ease-in-out;
            }
          }
          .drag-text {
            user-select: none;
            position: absolute;
            color: #f6f7fc;
            font-family: Poppins, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            width: 100%;
            top: 67px;
            height: 90px;
            margin: 0px;

            &:hover {
              transform: none;
            }
          }
          .expand {
            border-radius: 5px;
            padding: 0;
            float: right;
            background: url("../images/expand-filled.svg") center center no-repeat;
            background-color: #007a4c !important;
            margin: 8px;
          }
          .remove {
            margin: 8px;
            border-radius: 50%;
            background: url("../images/close-filled.svg") center center no-repeat;
          }
        }
      }
    }
    .upload-photo-wrap {
      margin: 0 0 30px;
      border-radius: 8px !important;
      padding: 60px 50px !important;
      margin-top: 15px !important;

      .upload-btn {
        padding-bottom: 60px !important;
      }
    }
  }
  .view-profile {
    .aahaLogo {
      height: 26px;
      margin-left: 16px;
    }
    .page-title {
      font-size: 28px;
      line-height: 46px;
      color: $title-primary;
    }
    form {
      .form-group {
        label {
          padding-bottom: 5px;
        }
        p {
          font-size: 16px;
          line-height: 22px;
          color: $text-secondary;
          @extend .secondaryFont;
        }
      }
    }

    .slick-slider-wrapper {
      .slick-slider {
        padding: 0 50px;
        position: relative;
        .slick-arrow {
          height: 36px;
          width: 36px;
          overflow: hidden;
          text-indent: -999em;
          outline: none;
          border: 0;
          box-shadow: none;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          cursor: pointer;
          @include transition;
          &:hover {
            opacity: 0.7;
            @include transition;
          }
          &.slick-disabled {
            opacity: 0.3;
          }
          &.slick-prev {
            left: 0;
            background: url("../images/slider-prev-arrow.png") center center no-repeat;
            background-size: auto;
          }
          &.slick-next {
            right: 0;
            background: url("../images/slider-next-arrow.png") center center no-repeat;
            background-size: auto;
          }
        }
        .slick-list {
          display: flex;
          min-height: 110px;
          margin-left: -15px;
          margin-right: -15px;
          .slick-track {
            display: flex;
            flex-direction: row;
            .slick-slide {
              padding: 0 15px;
              img {
                max-width: 100%;
                max-height: 130px;
              }
            }
          }
        }
        &.slick-initialized .slick-slide {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &.vet-profile {
      .user-info {
        display: flex;
        padding-bottom: 40px;
        align-items: center;
        .user-pic {
          width: 82px;
          height: 84px;
          margin-right: 20px;
          border-radius: 100px;
          overflow: hidden;
        }
      }
    }
    .ratings li [type="radio"]:checked + label,
    .ratings li [type="radio"]:not(:checked) + label,
    .rangeslider .rangeslider__handle {
      cursor: auto;
    }
    .ratings li [type="radio"]:checked + label:after,
    .ratings li [type="radio"]:not(:checked) + label:after {
      background: #a495d6;
      cursor: auto;
    }
    .sidebar-shift-list {
      display: flex;
      flex-direction: column;
      h6 {
        height: 30px;
      }
      .shift-list {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px !important;
      }
    }
    .actions {
      position: absolute;
      right: 5px;
      top: 5px;
      span {
        width: 18px;
        height: 18px;
        display: block;
        float: left;
        margin-left: 6px;
        cursor: pointer;
        transition: all 200ms ease-in-out;
        &:hover {
          transform: scale(0.9);
          transition: all 200ms ease-in-out;
        }
      }
      .expand {
        background: url("../images/expand_photo.svg") center center no-repeat;
      }
      @media (max-width: 767px) {
        top: 0;
        right: 0;
      }
    }
  }

  .hospital-photos-preview {
    .img-preview {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      justify-content: center;
      align-items: center;
      background: rgba($color: #000000, $alpha: 0.7);
      .img-wrap {
        max-height: 100%;
        background: transparent;
        box-shadow: none;
        position: relative;
        img {
          display: block;
          max-height: 90vh !important;
          object-fit: cover;
        }
        & > span {
          position: absolute;
          height: 34px;
          width: 34px;
          background: #fff url("../images/close-modal.png") center center no-repeat;
          background-size: 19px;
          right: -17px;
          top: -17px;
          border-radius: 100px;
          box-shadow: #313131 0 0 10px;
          cursor: pointer;
        }
        &.single-img {
          max-height: 80%;
          max-width: 80%;
          height: 80%;
        }
      }
      .slick-slider-wrapper .slick-slider {
        padding: 0;
        .slick-slide > div {
          max-height: 90vh;
          max-width: 80vw;
          margin: 0 auto;
        }
        .slick-arrow.slick-prev {
          z-index: 1;
          left: 2%;
        }
        .slick-arrow.slick-next {
          right: 2%;
        }
        .slick-list {
          overflow: initial;
        }
      }
    }
  }

  .map-pin {
    padding-top: 50px;
    text-align: center;
    font-size: 12px;
    color: red;
    background: #fff;
  }
  .sign-now-container {
    width: 100%;
    object {
      button {
        background: red;
      }
    }
  }

  ul.pagination {
    overflow: hidden;
    float: right;
    li {
      float: left;
      margin: 0 5px;
      cursor: pointer;
      a {
        padding: 0 4px;
        color: #59595a;
        &:focus {
          outline: none;
        }
      }
      &.previous,
      &.next {
        width: 24px;
        height: 24px;
        background: url("../images/dropdown_arrow.svg") center center no-repeat;
        background-size: 15px;
        position: relative;
        a {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          cursor: pointer;
          &:hover:after {
            display: none;
          }
          &:focus {
            outline: none;
          }
        }
        &.disabled {
          opacity: 0.5;
          a {
            cursor: default;
          }
        }
      }
      &.previous {
        transform: rotate(90deg);
      }
      &.next {
        transform: rotate(-90deg);
      }
      &.active {
        background: none;
        border-radius: 6px;
        padding: 0 2px;
        border: 1px solid #6d7fe9;
        a {
          color: #59595a;
        }
      }
    }
  }
  .referTab {
    color: #7b87cc;
    font-size: 18px;
    line-height: 28px;
    position: relative;
    padding-right: 24px;
    &.moreInfo {
      color: #007a4c;
      font-size: 15px;
      line-height: 20px;
      position: relative;
      padding-right: 24px;
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 3px;
      border-style: solid;
      border-color: #7b87cc;
      border-width: 0px 2px 2px 0px;
      transform: rotate(45deg);
      transition: border-width 150ms ease-in-out;
      opacity: 1;
      width: 13px;
      height: 13px;
      background: transparent;
      left: auto;
    }
    &:hover,
    &:focus {
      &:after {
        border-color: #82ceb1;
        opacity: 1;
      }
    }
    &.downArrow {
      &:after {
        transform: rotate(-135deg);
        top: 8px;
      }
    }
  }
  .primaryTitle {
    color: #412894;
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 20px;
    font-weight: normal;
  }
  .secondaryTitle {
    color: #757575;
    font-size: 20px;
    font-weight: 500;
    @media all and (min-width: 320px) and (max-width: 767px) {
      font-size: 14px;
    }
  }
  .vet-profile-picture {
    // height: 400px;
    .upload-photo-wrap {
      margin-bottom: 0;
    }
    .pdf-file {
      height: 385px;
      width: 305px;
      background-color: #f0f2fb;
      margin: 0 auto;
    }
    .vet-profile-pic {
      height: 100%;
    }
  }
  .admin-vet-container {
    max-width: 1400px !important;
  }
  .overall-rating-box {
    // border-top: 1px solid #DDDDDD;
    // border-bottom: 1px solid #DDDDDD;
    margin-top: 28px;
    width: 100%;
  }
  .overall_rating {
    padding-top: 28px;
    margin-top: 0px;
    .form-secondary-title {
      margin-bottom: 28px;
      color: #757575;
      font-family: Poppins, sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 29px;
    }
    .row {
      max-width: 650px;
    }
    .comment {
      padding-left: 15px;
      ul {
        li {
          margin-bottom: 30px;
          p {
            color: #535353;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            line-height: 22px;
            margin: 0;
          }
          span {
            color: #949494;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
  .accountcreated_box {
    border-top: 1px solid #dddddd;
    padding-top: 28px;
    p {
      color: #757575;
      font-family: Poppins, sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 29px;
      margin-bottom: 20px;
    }
    span {
      color: #535353;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      line-height: 22px;
      display: block;
    }
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: #7b87cc !important;
  }
  .CircularProgressbar .CircularProgressbar-text {
    fill: #412894 !important;
    font-size: 24px !important;
  }

  .reliability-progress.green .CircularProgressbar-path {
    stroke: green !important;
  }
  .reliability-progress.yellowgreen .CircularProgressbar-path {
    stroke: yellowgreen !important;
  }
  .reliability-progress.yellow .CircularProgressbar-path {
    stroke: yellow !important;
  }
  .reliability-progress.orange .CircularProgressbar-path {
    stroke: orange !important;
  }
  .reliability-progress.red .CircularProgressbar-path {
    stroke: red !important;
  }
  .reliability-progress.primary-color .CircularProgressbar-path {
    stroke: #007a4c !important;
  }
  .reliability-progress.gold .CircularProgressbar-path {
    stroke: gold !important;
  }

  .reliability-progress.green .CircularProgressbar-text {
    fill: green !important;
  }
  .reliability-progress.yellowgreen .CircularProgressbar-text {
    fill: yellowgreen !important;
  }
  .reliability-progress.yellow .CircularProgressbar-text {
    fill: yellow !important;
  }
  .reliability-progress.orange .CircularProgressbar-text {
    fill: orange !important;
  }
  .reliability-progress.red .CircularProgressbar-text {
    fill: red !important;
  }
  .reliability-progress.primary-color .CircularProgressbar-text {
    fill: #007a4c !important;
  }
  .reliability-progress.gold .CircularProgressbar-text {
    fill: gold !important;
  }
}
.first_Time_SignUp_box {
  .form-title {
    color: #412894;
    font-family: Poppins, sans-serif;
    font-size: 28px;
    line-height: 46px;
    margin-bottom: 12px;
  }
  .form-secondary-title {
    color: #757575;
    font-family: Poppins, sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 37px;
    text-align: center;
  }
  .sign-now-message {
    color: #535353;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 22px;
    max-width: 585px;
    width: 100%;
  }
  form {
    margin-top: 33px;
    .form-group {
      width: 100%;
      label {
        span {
          color: #535353;
          font-family: "Open Sans", sans-serif;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}
.sign-now-component {
  .agree {
    font-size: 12px;
  }
  .form-control {
    height: 52px;
  }
  .Select-control {
    border: 1px solid #ced4da;
  }
  label {
    span {
      text-align: justify !important;
      display: inline-block;
    }
  }
}
@keyframes overlay {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0.5;
    transform: translate(0, -20%);
  }
  33% {
    opacity: 0.7;
    transform: translate(0, -14%);
  }
  66% {
    opacity: 0.9;
    transform: translate(0, -7%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0.5;
    transform: translate(0, -20%);
  }
  33% {
    opacity: 0.7;
    transform: translate(0, -14%);
  }
  66% {
    opacity: 0.9;
    transform: translate(0, -7%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.line-through {
  text-decoration: line-through;
}

.updated-amount {
  color: #495fe4;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  background-color: #edf0fd;
  border-radius: 4px;
  display: flex;
  padding: 0 8px;
}
.btn-textwrap {
  white-space: inherit !important;
}
.innerInfo {
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 15px;
}

.notification {
  li {
    background: #495187 !important;
    color: #fff !important;
    padding: 8px 20px !important;
    .close {
      position: absolute;
      right: 20px;
      top: 0px;
    }
  }
}
.bank-notification {
  li {
    background: #fff !important;
    color: #000 !important;
    padding: 8px 20px !important;
    border-radius: 15px !important;
  }
}
.remind-email {
  display: inline-block;
  margin-left: 25px;
  cursor: pointer;
}

.selectAll {
  cursor: pointer;
}
.copylink-btn {
  border: 2px solid $title-primary;
  filter: drop-shadow(0px 2px 3px rgba(134, 134, 134, 0.216146));
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1px;
  color: $title-primary;
  height: 55px;
  background-color: transparent;
}

.shiftMoreInfo {
  margin-bottom: 1rem;
}

.card-top-right-confirm-btn {
  margin-top: 27px;
  margin-right: 13px;
}

.billing-component-wrapper {
  .modal-content {
    width: 85%;
    @media all and (min-width: 320px) and (max-width: 767px) {
      width: 100%;
    }
  }
  .messages {
    font-size: 20px !important;
    line-height: 35px !important;
    padding: 40px !important;

    @media all and (min-width: 320px) and (max-width: 767px) {
      font-size: 14px !important;
      line-height: 20px !important;
      padding: 12px !important;
    }
  }
  .modal-body {
    padding: 20px !important;

    @media all and (min-width: 320px) and (max-width: 767px) {
      button {
        padding: 5px 30px !important;
        font-size: 12px !important;
        line-height: 20px;
      }
    }
  }
  .modal-dialog {
    left: 5%;

    @media all and (min-width: 320px) and (max-width: 767px) {
      left: 0;
    }
  }
}

.bg-check {
  font-family: Open Sans, sans-serif;
  color: #535353;
  font-size: 14px;
}

.confirm-select-request-font {
  font-size: 20px !important;
  @media screen and (max-width: 767px) {
    font-size: 16px !important;
  }
}

.confirm-select-request-select {
  .Select-menu {
    max-height: 150px;
  }
}

.crop-photo-wrap {
  .crop-container {
    max-width: 300px;
    height: 200px;
  }
  .crop-label {
    color: #000000;
    font-size: 14px;
  }
  .upload-btn {
    position: relative;
    padding: 4px;
    .btn {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0 2px 3px 0 rgba(134, 134, 134, 0.22);
    }
    input {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
  .customized-button {
    width: 100% !important;
    line-height: 24px !important;
    min-width: 220px !important;
    border-radius: 50px !important;
    padding: 10px 30px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    letter-spacing: 1.5px !important;
  }
  .shift-image-text {
    font-size: 14px;
    text-align: left;
  }
  .preview-center-image {
    img {
      width: 70% !important;
    }
  }
  .profile-preview {
    height: 190px;
    img {
      min-width: 100px;
      min-height: 100px;
      border-radius: 50%;
    }
  }
  .custom-loader {
    position: absolute;
    left: 110px;
    right: 1px;
    top: 100px;
    bottom: 1px;
    width: auto;
    height: auto;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 18px;
    &:after {
      position: absolute;
      left: 5px;
      right: 1px;
      top: 6px;
      bottom: 1px;
      margin: auto;
      width: 30px;
      height: 30px;
    }
    @media screen and (max-width: 420px) {
      top: 110px;
      left: 130px;
    }
  }
}

.vet-photo-upload {
  .modal-dialog {
    @media screen and (max-width: 420px) {
      margin-top: 20px !important;
    }
  }
}

.hospital-crop-image-wrap {
  margin-top: 20px;
  padding: 20px 15px;
  background: rgba(123, 135, 204, 0.07);
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  .image-list-wrap {
    .crop-image-list {
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      li {
        height: 80px;
        width: 80px;
        padding: 10px;
        overflow: hidden;
        position: relative;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        min-width: 80px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .selected {
        border: 2px solid #007a4c;
      }
    }
  }
  .b-right {
    border-right: 1px solid #cccccc;
    @media (max-width: 767px) {
      border-right: none;
    }
  }
}

.form-subtitle {
  color: #412894;
  margin-bottom: 15px;
}

.first-shift-star {
  margin: 0 auto;
  background: url("../images/star.png") no-repeat center center;
  height: 16px;
}

.pending-review-star {
  height: 20px;
  width: 20px;
  cursor: pointer;

  &:hover {
    background-color: green;
  }
}

.tech-callout-confirmation {
  font-size: 17px !important;
  line-height: 35px !important;
  color: #535353 !important;
  span {
    color: #412894 !important;
    font-size: 19px !important;
  }
}

.contractor-callout-form {
  max-height: 85vh !important;
  .modal-body {
    label {
      color: #412894 !important;
      font-weight: 400 !important;
    }
  }
}

.testimonial-box {
  transform: scale(1.1);
  text-align: left;
  color: #535353;
  img {
    width: 68px;
    height: 68px;
    display: block;
    margin-right: 10px;
  }
  .dvm-name {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .light-gray-text {
    color: #949494;
  }
}

.shift-pricing-loader {
  &:after {
    border-color: #fff transparent #55ad8c transparent !important;
    right: 0 !important;
    bottom: 10px !important;
  }
}

.top-checkbox {
  position: relative;
  left: 7px;
  top: 40px;
}
.delete-checkbox {
  position: relative;
  left: 7px;
  top: 30px;
}

.trash-icon {
  width: 50px;
  img {
    width: 20px;
  }
}

.banner-text {
  color: $text-primary !important;
  font-weight: 400;
}

.banner-text-link {
  color: $primary-color;
  font-weight: 600;
}

.bell-icon-bank-details-banner {
  width: 20px;
  margin-right: 10px;
}

.bulk-delete-form {
  padding: 10px;
  #shift-ids {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    div {
      margin-right: 3px;
    }
  }
  input {
    margin: 20px 0 30px 25px;
  }
}

.earnings-text {
  p {
    font-size: 18px;
    line-height: 27px;
  }
}

.tax-text-container {
  background-color: #fff;
  width: 568px;
  height: 270px;
  border-radius: 15px;
  padding: 28px;
  margin-top: -150px;
  #tax-text {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
  }
  #tax-dates {
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.tax-text-mobile {
  background-color: #fff;
  padding: 28px;
  border-radius: 15px;
}

.tax-message {
  font-weight: 300;
  font-size: 16px;
  line-height: 21.79px;
  font-style: normal;
}

.export-earnings-text {
  margin-bottom: 16px;
  cursor: pointer;
}

.earnings-placeholder {
  height: 235px;
  width: 400px;
  margin-right: 200px;
}

.btn-cancel {
  color: #5ab894;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 6px;
  border-radius: 24px;
  border: 2px solid #007a4c;
  width: 228px;
  height: 48px;
  margin-right: 35px;
}

.btn-export {
  color: #fff;
  background-color: #5ab894;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  padding: 6px;
  border-radius: 24px;
  border: 2px solid #007a4c;
  width: 285px;
  height: 48px;
}

.btn-close {
  img {
    height: 14px;
    width: 14px;
  }
  border: none;
  position: absolute;
  top: 21px;
  right: 30px;
  background-color: #fff;
  cursor: pointer;
}

.no-margin {
  margin-left: -15px;
  margin-right: -15px;
}

.small-check {
  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:before,
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    width: 17px;
    height: 17px;
  }
}
.notification-dot {
  width: 13px;
  height: 13px;
  margin: 10px;
  border-radius: 50%;
  background-color: $expiredShift;
  z-index: 999;
}

.notification-dot-profile-picture {
  position: absolute;
  top: -10px;
  left: 10px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: $expiredShift;
  z-index: 999;
}

.dropdown-notification-dot {
  position: absolute;
  overflow: visible;
  top: 20%;
  left: -5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: $expiredShift;
  transform: translateX(-10px);
  z-index: 999;
}

.dropdown-notification-dot-placeholder {
  // placeholder for future use, remove "placeholder" from className name in HTML
  display: none;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list-item {
  display: block;
  min-width: 180px;
  height: 30px;
}

.tooltip-wrapper {
  position: fixed;
  display: block;
  width: 130px;
  height: 25px;
  &:hover,
  &:focus {
    .tooltip {
      visibility: visible;
      right: 145px;
      opacity: 1;
      transform: scale(1);
    }
  }
}

.tooltip {
  position: relative;
  justify-content: center;
  align-items: center;
  visibility: visible;
  opacity: 0;
  transform: scale(0.5);
  transform-origin: 100% 50%;
  box-sizing: border-box;
  right: 145px;
  width: 130px;
  height: 25px;
  background: #000;
  border-radius: 4px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  vertical-align: middle;
  font-size: 14px;
  color: white;
  text-align: center;
  transition: visibility 0.4s ease-in-out, opacity 0.4s ease-in-out, transform 0.4s ease-in-out,
    left 0.4s ease-in-out;
  z-index: 1000;
  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #000;
    z-index: 1000;
  }
}

.tooltip-placeholder {
  // placeholder for future use, remove "placeholder" from className name in HTML
  display: none;
}

.bank-link-btn {
  position: relative;
  text-align: center;
  z-index: 10;
}

.dashboard-container {
  background-color: #fff;
  height: 500px;
  width: 100%;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 2px 15px rgba(65, 40, 148, 0.1);
  @media screen and (max-width: 428px) {
    height: auto;
  }
}

.dashboard-confirmed-text {
  color: $confirmedShift;
}

.pending-reviews-wrapper {
  display: grid;
  gap: 20px;
}

.review-card-wrapper {
  .card-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-columns: 210px;
    gap: 24px;
  }

  .review-card {
    background-color: #fff;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    justify-content: center;
    justify-items: center;
    text-align: center;
    padding: 9px 0 18px 0;
    height: 317px;
    width: 210px;
    gap: 0;
    box-shadow: 0px 2px 15px rgba(65, 40, 148, 0.1);
    border-radius: 8px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 39px;
  height: 20px;
  box-sizing: border-box;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-round {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 40px;
  border: 1px solid #e8eaf7;
}
.slider-round-blue {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 40px;
  background-color: #fff;
  border: 1px solid #3747ab;
}

.slider-round:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 5%;
  top: 7%;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.slider-round-blue:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 5%;
  top: 7%;
  background-color: #3747ab;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider-round {
  background-color: #5862a3;
}

input:checked + .slider-round-blue {
  background-color: #3747ab;
}

input:focus + .slider-round {
  box-shadow: 0 0 1px #5862a3;
}

input:focus + .slider-round-blue {
  box-shadow: 0 0 1px #3747ab;
}

input:checked + .slider-round:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

input:checked + .slider-round-blue:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
  background-color: #fff;
}

.dashboard-ratings {
  display: flex;
  flex-direction: column;
  width: 210px;
  h5 {
    padding-bottom: 10px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 10px;
  }
}
.height-40 {
  height: 45%;
}
.height-72 {
  height: 72%;
}
.height-94 {
  height: 94%;
}
.dashboard-scroll {
  overflow-y: scroll;
  height: 62%;
}

.dashboard-scroll::-webkit-scrollbar {
  display: none;
}

.dashboard-shift-request {
  height: 100%;
  @media screen and (max-width: 428px) {
    height: auto;
  }
  overflow-x: hidden;
  overflow-y: hidden;
}

// .dashboard-shift-request::-webkit-scrollbar {
//   display: none;
// }

.dashboard-unfilled-shifts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-tour-button {
  border-radius: 50px;
  box-shadow: 0px 2px 4px rgba(86, 86, 86, 0.3);
}

.manage-shifts-modal {
  margin-left: 62%;
  margin-top: 1%;
  width: 500px;
}

.shift-requests-modal {
  margin-left: 12%;
  margin-top: 1%;
  height: 733px;
  width: 682px;
}

.confirmed-schedule-modal {
  margin-top: 1%;
  margin-left: 62%;
  height: 682px;
  width: 492px;
}

.calendar-modal {
  margin-top: 1%;
  margin-left: 25%;
  width: 500px;
}

.recent-reviews-modal {
  margin-left: 8%;
  margin-top: 17%;
  width: 1212px;
}

.reviews-modal {
  margin-left: 8%;
  margin-top: 0%;
  width: 500px;
}

.dashboard-more-menu {
  // visibility: hidden;
  margin-right: 30px;
  ul {
    list-style-type: none;
    box-shadow: 0px 2px 15px rgba(65, 40, 148, 0.1);
    border-radius: 15px;
    background: #fff;
    li {
      button {
        width: max-content;
        background: #fff;
        border: none;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-weight: 400;
        color: $text-secondary;
        clear: both;
        text-align: left;
      }
    }
  }
}

.top-rounded {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.bottom-rounded {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

// TEMP: not working on Safari (Remove later after dynamic dahboard deployment)
// button + .dashboard-more-menu:active,
// button:focus + .dashboard-more-menu {
//   visibility: visible;
// }

.dashboard-more-menu-container {
  height: 0px;
  width: 5%;
  z-index: 10;
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  button:focus {
    outline: 0;
  }
}

.arrow-nav {
  width: 14px;
  height: 21px;
  background: url("../images/arrow-right-green.svg") center center no-repeat;
}

.arrow-90-tran {
  transform: rotate(90deg);
}
.green-circle {
  height: 20px;
  width: 20px;
  border: #007a4c 2px solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.one-grid-block {
  width: 8.3%;
}

.two-grid-blocks {
  width: 16.6%;
}

.blue-horizontal-line {
  border-bottom: 1px solid #3747ab;
  width: 308px;
  height: 8px;
  display: flex;
  justify-content: space-between;
}

.open-promo-dot {
  background: #fff;
  border: 1px solid #e8eaf7;
  height: 16px;
  width: 16px;
  border-radius: 16px;
}

.closed-promo-dot {
  background: #5862a3;
  border: 1px solid #e8eaf7;
  height: 16px;
  width: 16px;
  border-radius: 16px;
}

.kangaroo-small {
  width: 200px;
  height: 200px;
}

.vet-reg-form-wrapper {
  max-width: 540px;
  margin: 0 auto;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 15px rgba(65, 40, 148, 0.1);
  padding: 30px 65px;
  @media (max-width: 450px) {
    padding: 30px 12px;
  }

  @media (min-width: 1200px) {
    .vet-reg-password-section {
      position: relative;
      .password-validation {
        position: absolute;
        right: -375px;
        top: 0px;
        width: 340px;
      }
    }
  }

  .password-validation {
    border: 1px solid #dbdffa;
    background: white;
    box-shadow: 0px 2px 15px rgba(65, 40, 148, 0.2);
    border-radius: 4px;
    margin-top: 24px;
    div {
      padding: 16px;
      color: $roo-dark-purple;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
      }
      img {
        cursor: pointer;
      }
    }
    img {
      width: 14px;
      height: 14px;
      margin-right: 13px;
    }
    li {
      list-style: none;
      padding: 10px 16px;
      color: #59595a;
    }
  }

  .existed-user-error {
    margin-top: 24px;
    p {
      margin: 0;
      color: #e81616;
    }
  }

  .required {
    position: relative;
  }

  h3 {
    font-size: 36px;
    color: $roo-dark-purple;
    padding: 36.5px 0;
  }

  section {
    margin-bottom: 24px;
  }

  label {
    font-size: 20px;
    color: $roo-light-purple;
    margin: 0;
  }

  input {
    border: 1px solid #dbdffa;
    border-radius: 4px;
    height: 48px;
  }

  .vet-reg-term-n-condition {
    .required {
      display: flex;
      p {
        margin-left: 8px;
        font-size: 16px;
        a {
          text-decoration: underline !important;
        }
      }
    }
  }
  button {
    padding: 8px 32px;
    gap: 8px;
    width: 136px;
    height: 45px;
    background: $roo-light-green;
    box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
    border-radius: 24px;
    color: white;
    border: none;
    cursor: pointer;
    margin-bottom: 24px;
  }
}

.new-register-step-wrapper {
  h1,
  p {
    font-style: normal;
  }
  h1 {
    color: $roo-dark-purple;
    font-size: 36px;
    @media screen and (max-width: 991px) {
      font-size: 28px;
      line-height: 40px;
      margin-top: 20px;
    }
  }
  p {
    color: #59595a;
    font-size: 16px;
    @media screen and (max-width: 991px) {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
  }
  .register-page-content {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .onboarding-tracker-container {
      width: 22%;
      min-width: 270px;
      @media screen and (max-width: 480px) {
        // max-height: 160px;
        width: 100%;
      }

      @media screen and (min-width: 480px) and (max-width: 768px) {
        width: 197px;
      }
    }

    .new-register-form {
      width: 68%;
      padding-left: 20px;
      @media (max-width: 980px) {
        width: 60%;
      }
      @media (max-width: 768px) {
        width: 100%;
        padding-left: 0;
      }
      .required {
        position: relative;
      }
      .Select-control,
      .Select-multi-value-wrapper {
        height: 47px !important;
      }

      .Select-control {
        border: 1px solid #dbdffa !important;
      }

      label {
        color: $roo-light-purple;
        margin: 0px;
      }
      input:not([role^="combobox"]) {
        background: #ffffff;
        border: 1px solid #dbdffa;
        border-radius: 4px;
        height: 48px;
      }
      button {
        width: 105px;
        height: 45px;
        background: $roo-light-green;
        box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
        border-radius: 24px;
        border: none;
        color: white;
        cursor: pointer;
      }
      .btn-loader {
        color: transparent;
        div {
          color: transparent !important;
        }
        &::after {
          right: 37px !important;
        }
      }
    }

    .tech-new-register-form {
      width: 68%;
      padding-left: 20px;
      @media (max-width: 980px) {
        width: 60%;
      }
      @media (max-width: 480px) {
        width: 100%;
        padding-left: 0;
      }
      .required {
        position: relative;
      }
      .Select-control,
      .Select-multi-value-wrapper {
        height: 47px !important;
      }

      .Select-control {
        border: 1px solid #dbdffa !important;
      }

      p {
        color: #412894;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
      }

      label {
        font-family: "Jost", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #3747ab;
      }
      input:not([role^="combobox"]) {
        background: #ffffff;
        border: 1px solid #dbdffa;
        border-radius: 8px;
        height: 48px;
      }
      .btn-loader {
        color: transparent;
        div {
          color: transparent !important;
        }
        &::after {
          right: 37px !important;
        }
      }
    }

    .about-me-form {
      display: grid;
      gap: 20px;

      .upload-photo-wrap {
        border: 2px dashed #acacad !important;
        border-radius: 8px !important;
        color: #303031 !important;
      }

      section {
        grid-column: 1 / span 5;
        &:nth-child(1),
        &:nth-child(8) {
          grid-column: 1 / span 2;
        }
        &:nth-child(3) {
          grid-column: 1 / span 3;
        }
        &:nth-child(4) {
          grid-column: 4 / span 2;
        }
        &:nth-child(2),
        &:nth-child(9) {
          grid-column: 3 / span 3;
        }
        &:nth-child(5) {
          grid-column: 1 / span 2;
        }
        &:nth-child(6) {
          grid-column: 3 / span 1;
        }
        &:nth-child(7) {
          grid-column: 4 / span 2;
        }
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: none;
        section {
          grid-column: 1 / span 5 !important;
        }
      }
    }

    .education-form {
      .form-label {
        color: $roo-light-purple;
      }
      .form-control {
        height: 48px !important;
        background: #ffffff !important;
        border: 1px solid #dbdffa !important;
        border-radius: 4px !important;
      }
      .add-link {
        text-decoration-line: underline;
        color: #339570;
      }
      .form-label-note {
        color: #535353;
      }
    }

    .reg-questionnarie-wrapper {
      h2 {
        font-size: 16px;
      }
      p,
      label {
        margin: 0;
        font-style: normal;
        color: $roo-light-purple;
      }
      textarea {
        border: 1px solid #dbdffa !important;
        border-radius: 4px !important;
      }
      .recommended-text {
        position: relative;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 110px;
          height: 18px;
          background: #cce4db;
          border-radius: 4px;
          font-size: 12px;
          color: #303031;
          position: absolute;
          left: 171px;
          top: 20px;
        }
      }
      // p {
      // font-family: 'Poppins';
      // font-weight: 500;
      // font-size: 16px;
      // }
      // h2 {
      // font-family: 'Jost';
      // font-weight: 500;
      // font-size: 28px;
      // }
    }
  }
}

.promotion-banner-ul {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}
.promotion-banner {
  display: flex;
  align-items: center;
  border: 3px solid #6753a9;
  box-shadow: 0px 2px 16px #ebebeb;
  border-radius: 16px;
  background-color: #fff;
  padding: 20px 16px;
  margin-bottom: 8px;
}
.promotion-banner-grape {
  display: flex;
  align-items: center;
  background-color: var(--grapeDark600);
  box-shadow: 0px 2px 16px #ebebeb;
  border-radius: 16px;
  padding: 20px 16px;
  margin-bottom: 8px;
  .promotion-banner-text {
    color: white;
  }
  .promo-banner-text-container {
    flex: 1;
  }
}
.pb-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 100%;
  }
}

.promotion-banner-text {
  color: #412894;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 4px;
}

.promotion-start-now-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 32px;
  gap: 8px;
  height: 33px;
  width: 151px;
  background: #ffffff;
  border: 2px solid #412894;
  box-shadow: 0px 2px 4px #d6d6d6;
  border-radius: 24px;
  color: #6753a9;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 8px;
  }
}

.promo-csv-upload {
  height: 213px;
  width: 381px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 72px;
  background: #dbdffa;
  border: 2px dashed #acacad;
  border-radius: 8px;
  margin-top: 24px;
}

.promo-csv-uploaded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 72px;
  width: 380px;
  height: 216px;
  background: #edf0fd;
  border: 2px dashed #acacad;
  border-radius: 8px;
  margin-top: 24px;
}

.promo-csv-text-lg {
  color: #303031;
  font-size: 20px;
  margin: 8px 0;
}

.promo-csv-text-sm {
  color: #59595a;
  font-size: 16px;
}

.onboarding-tracker-container {
  display: flex;
  height: 429px;
  max-width: 338px;
  min-width: 270px;
  background: #fff;
  padding: 40px 20px 0px;
  border-radius: 12px;
  .mobile-tracker-title {
    display: none;
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
    padding: 16px 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-height: 118px;
    margin-bottom: 30px;
    position: relative;
    .tracker-number-container {
      justify-content: space-between;
      margin-top: -45px;
      offset-rotate: 275px;
      transform: rotate(270deg);
      height: 65vw;
      margin-right: 0;
      max-height: 380px;
      span {
        transform: rotate(90deg);
      }
    }
    .tracker-text-container {
      display: none;
    }
    .mobile-tracker-title {
      display: block;
      position: absolute;
      left: 40px;
      top: 50px;
      padding-right: 40px;
      @media screen and (min-width: 351px) {
        left: 40px;
      }
      @media screen and (min-width: 391px) {
        left: 50px;
      }
      @media screen and (min-width: 471px) {
        left: 60px;
      }
      @media screen and (min-width: 511px) {
        left: 70px;
      }
      @media screen and (min-width: 575px) {
        left: 60px;
      }
    }
  }
}

.tracker-number-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #412894;
  height: 80%;
  margin-right: 24px;
  margin-top: 5%;
}

.tracker-number {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: -12px;
}

.current-tracker-num {
  background-color: #dbdffa;
}

.completed-tracker-num {
  background-color: #311e64;
}
.other-tracker-num {
  background-color: #ebebeb;
}

.tracker-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  margin-top: 5%;
  .div {
    font-size: 20px;
  }
}

.justify-content-between {
  display: flex;
  justify-content: space-between;
}

.current-tracker-text {
  color: #303031;
  white-space: nowrap;
}
.completed-tracker-text {
  color: #242425;
  white-space: nowrap;
}
.other-tracker-text {
  color: #59595a;
  white-space: nowrap;
}

.onboarding-modal-background {
  background-color: #edf0fd;
}
.onboarding-modal-title {
  color: #412894;
  font-size: 32px;
  font-weight: 500px;
  line-height: 46.24px;
  margin-bottom: 24px;
  font-family: "Jost", sans-serif;
}
.onboarding-modal-text {
  color: #412894;
  font-size: 20px;
  line-height: 28.9px;
  margin-bottom: 42px;
  font-family: "Jost", sans-serif;
}

.onboarding-modal-text-lg {
  color: #412894;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-family: "Jost", sans-serif;
}

.onboarding-modal-text-sm {
  color: #412894;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  p {
    font-size: 12px;
    line-height: 20px;
    color: #412894 !important;
  }
}

.onboarding-animal-container {
  height: 137px;
  width: 251px;
  background-color: #fff;
  box-shadow: 0px 2px 4px #ebebeb;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}
.onboarding-animal-img {
  width: 93.83px;
}

.onboarding-animal-text {
  color: #253072;
  font-size: 20px;
  font-weight: 500;
  margin: 12px 0 12px 12px;
  font-family: "Jost", sans-serif;
}
.onboarding-animal-description {
  color: #838383;
  font-weight: 500;
  line-height: 29px;
  font-size: 20px;
  margin-left: 12px;
  font-family: "Jost", sans-serif;
}

.animal-selected-border {
  border: #3747ab 2px solid;
}

.onboarding-textarea {
  textarea {
    height: 192px;
    width: 460px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dbdffa;
    resize: none;
    outline: none;
  }
}

.onboarding-textarea-mobile {
  textarea {
    height: 192px;
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dbdffa;
    resize: none;
    outline: none;
  }
}

.onboarding-common-text {
  color: #412894;
  font-size: 20px;
  font-family: "Jost", sans-serif;
}

.onboarding-ic-container {
  height: 820px;
  width: 534px;
  background: #ffffff;
  border-radius: 12px;
  overflow-y: scroll;
  padding: 48px;
}

.onboarding-ic-container-mobile {
  height: 820px;
  width: auto;
  background: #ffffff;
  border-radius: 12px;
  overflow-y: scroll;
  padding: 48px;
}

// .onboarding-ic-container::-webkit-scrollbar-track {
//   background: transparent;
// }

.animate-requested-shift {
  transition: all 300ms ease-in;
  transition-timing-function: ease-in;
  transform: translateY(-110%);
}

.animate-hidden-shift {
  transition: all 300ms ease-out;
  transition-timing-function: ease-out;
  transform: translateY(-110%);
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 15px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d6d6d6;
    border-radius: 5px;
  }
}

#vet-analytics {
  .white-bordered-box {
    margin-bottom: 20px;
    padding: 28px 12px 26px;
  }
}

.right-panel-container {
  width: 737px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: #fff;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: -12px 3px 14px -11px rgb(0 0 0 / 16%);
  animation-name: fadeInRight;
  animation-duration: 500ms;
  @media screen and (max-width: 768px) {
    width: 100%;
    top: 4rem;
  }
  .header-text {
    color: $green-dark;
  }
  .gray-text {
    color: $gray-light-300;
  }
  .profile-panel-shift-details {
    border-bottom: 1px solid #d9d4ea;
    .user-profile {
      display: flex;
      align-items: center;
      height: 54px;
      .user-pic {
        img {
          width: 40px;
          height: 40px;
          border-radius: 100px;
          object-fit: contain;
          border: 1px solid #ccc;
        }
      }
      .user-data {
        display: flex;
        flex-direction: column;
        .user-name {
          @extend .header-font-family;
          color: #242425;
          font-size: 20px;
        }
      }
    }
  }
  .badge-icon-wrapper img {
    width: 87px;
    height: 87px;
  }

  .mobile-vet-info {
    flex-direction: column;
  }
}

.mobile-close-panel {
  width: 20px;
  height: 20px;
  background: url("../images/close_dark_grey.svg") no-repeat center center;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.flexible-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3747ab;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  height: 18px;
  width: 62px;
  border: #3747ab 1px solid;
  border-radius: 4px;
  &.small {
    width: 24px;
  }
}

.surgery-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #303031;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  height: 18px;
  width: 62px;
  background: #ffdddd;
  border-radius: 4px;
  &.small {
    width: 24px;
  }
}

.empty-indicator {
  height: 18px;
  width: 62px;
}

.hospital-panel-boost-text {
  color: #838383;
  font-size: 12px;
  line-height: 18px;
  text-decoration: line-through;
  display: flex;
  align-self: end;
  margin-bottom: 5px;
  margin-left: 5px;
}

.hospital-panel-avatar {
  height: 40px;
  border-radius: 100px;
  width: auto;
  overflow: hidden;
  img {
    width: 40px;
    height: 40px;
    border-radius: 100px;
  }
}

.profile-avatar {
  height: 80px;
  border-radius: 100px;
  width: auto;
  overflow: hidden;
  img {
    width: 80px;
    height: 80px;
    border-radius: 100px;
  }
}

.hospital-panel-info-text {
  font-size: 16px;
  color: #303031;
  font-weight: 500;
}

.hospital-panel-info-small-text {
  color: #838383;
  font-size: 12px;
}
.hospital-panel-link-text {
  text-decoration: underline;
  cursor: pointer;
  &:hover,
  &::after {
    opacity: 0.8;
  }
}

.badges {
  padding: 0 8px;
  border-radius: 4px;
  color: $dark-gray;
  text-align: center;
  &.tech-user-type {
    background: $blue-300;
    margin-bottom: 12px;
  }
  &.vet-user-type {
    background: $purple-200;
    margin-bottom: 12px;
  }
  &.surgery {
    background: $lightest-yellow;
  }
  &.flex-surgery {
    color: $dark-blue;
    border: 1px solid $dark-blue;
  }
  &.flex-time {
    color: white;
    border: 1px solid white;
  }
  &.flex-teal {
    border: 1px solid #178386;
    background: #e9f8f8;
  }
  &.boosted {
    color: $green-dark;
    border: 1px solid $green-dark;
  }
  &.primary {
    background: $dark-blue;
    color: white;
  }
  &.none {
    background: $lightestGray;
  }
  &.hospital-admin {
    background: $purple-100;
  }
  &.hospital-user {
    background: $yellow-200;
  }
  &.original {
    color: white;
    background: #178386;
  }
  &.duplicate {
    background: $yellow-500;
  }
}

.shift-card {
  border-radius: 16px;
  background: white;
  box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
  cursor: pointer;
  &.selected {
    box-shadow: 0 0 0 2px $green-dark;
    &::after {
      content: " ";
      position: absolute;
      background-image: url("../images/checkmark_circle.svg");
      background-repeat: no-repeat;
      width: 25px;
      height: 20px;
      left: -7px;
      top: -6px;
    }
  }
  &.vet-shift {
    .shift-card-header {
      background: $title-primary;
      color: white;
    }
  }
  &.tech-shift {
    .shift-card-header {
      background: $light-blue;
    }
  }
  &.list-view {
    margin-bottom: 15px;
    width: 996px;
    .shift-card-header {
      border-radius: 16px 0px 0px 16px;
      padding: 14px;
    }
    .shift-card-body {
      padding: 36px 42px 26px 42px;
    }
    .dashboard-more-menu {
      margin-left: -115px;
    }
  }
  .shift-card-header {
    border-radius: 16px 16px 0px 0px;
    padding: 15px 16px 12px 24px;
  }
  .shift-card-body {
    padding: 15px 16px 12px 24px;
  }
  .button-link {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    color: $green-dark;
    width: 185px !important;
    &.danger {
      color: $red-base;
    }
  }
}

.menu-container {
  box-shadow: 0px 2px 15px rgba(65, 40, 148, 0.2) !important;
  border-radius: 16px !important;
  .menu-item {
    &:hover {
      background: none;
    }
  }
}

#manage-shifts {
  .tab-list {
    border-bottom: 1px solid $lightestGray;
    padding-left: 0;
    margin-bottom: 52px;
    overflow: auto;
  }
  .react-tabs__tab {
    width: unset !important;
    background: #dbdffa !important;
    border-radius: 8px 8px 0px 0px !important;
    &.react-tabs__tab--selected {
      color: $purple-darkest !important;
      background: $blue-200 !important;
      border: 1px solid $blue-dark !important;
      border-radius: 8px 8px 0px 0px !important;
    }
  }
  .action-buttons {
    position: absolute;
    display: flex;
    right: 0;
  }
  .action-icons {
    div {
      z-index: 1;
    }
  }
}

.expiration-date {
  text-decoration: underline;
  color: $red-base;
}

.badges-overview-wrapper {
  section {
    margin-bottom: 10px;
    .badges-container {
      display: grid !important;
      grid-template-columns: repeat(auto-fill, 80px);
      gap: 15px;
    }
  }

  label {
    font-family: "Jost", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #303031;
    margin: 15px 0;
  }
}

.badge-icon-wrapper {
  position: relative;
  p {
    position: absolute;
    bottom: -20px;
    font-size: 14px;
    width: 90%;
    left: 4px;
    text-align: center;
    color: white !important;
    border-radius: 4px;
  }
}

.new-badge-congrats-wrapper {
  h5 {
    color: #412894;
    font-family: "Jost", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
  }
  span {
    font-family: "Jost", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #303031;
  }
  button {
    background: #ffffff;
    border: 2px solid #412894;
    box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
    border-radius: 24px;
    color: $roo-dark-purple;
    width: 114px;
    height: 33px;
    cursor: pointer;
  }
}

.small-circle {
  height: 12px;
  width: 12px;
  border-radius: 12px;
  margin: 0 10px;
  &.less-margin {
    margin-left: 0;
  }
  &.green {
    background: #007a4c;
  }
  &.gray {
    background: #59595a;
  }

  &.yellow {
    background: #e7c21c;
  }
}

.hospital-panel-accordion {
  width: 100%;
  height: 37px;
  background-color: #edf0fd;
  color: #3747ab;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hospital-panel-character-type {
  border: 1px solid #8d7ebf;
  height: 137px;
  width: 251px;
  overflow: hidden;
  border-radius: 8px;
}

.hospital-panel-inner-character-type {
  img {
    width: 93.83px;
    margin-right: 10px;
  }
  div {
    font-weight: 500;
    h6 {
      font-size: 20px;
      color: #253072;
      margin-top: 5px;
    }
    p {
      font-size: 20px;
      color: #838383;
    }
  }
}

.large-info-popup {
  width: 500px;
  height: auto;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #dbdffa;
  box-shadow: 0px 2px 15px rgba(65, 40, 148, 0.2);
  padding: 16px 16px 5px 16px;
  z-index: 200;
  &.tracker {
    position: absolute;
    left: 350px;
  }
  &.mobile {
    position: absolute;
    width: 100%;
    top: -125px;
    left: 0;
    overflow: hidden;
  }
}
//----------- Chat -----------//

.chat-panel-container {
  padding: 0px !important;
  @media screen and (min-width: 768px) {
    width: 491px;
  }
  .chat-panel-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .cp-shift-header {
    display: flex;
    gap: 12px;
    width: 100%;
    box-shadow: 0px 4px 2px -2px rgba(65, 40, 148, 0.2);
    height: 70px;
    z-index: 1;
  }
  .cp-close-btn {
    padding-top: 15px;
    padding-right: 15px;
  }
  .cp-avatar {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-left: 10px;
  }
  .cp-shift-info {
    width: 80%;
    height: 50px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;

    .cp-user-name {
      grid-area: 1 / 1 / 2 / 1;
    }
    .shift-data {
      grid-area: 2 / 1 / 2 / 1;
      display: flex;
      gap: 15px;
    }
  }

  .chat-panel-loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .shift-info-section {
    font-size: 12px;
    display: flex;
    gap: 5px;
    align-items: center;
  }
}

$FooterHt: 110px;
.chat-window {
  background-color: white;
  .chat-loading-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    .btn-loader {
      margin-left: 37px;
      &::after {
        border-color: #007a4c #d77d7d00 #007a4c transparent !important;
      }
    }
  }
  .chat-body {
    $adjustedHeaderHt: 137px;
    $mobileHeaderHt: 250px;
    $typingIndicatorHeight: 22px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 10px;
    padding-bottom: 35px;
    height: calc(100vh - ($adjustedHeaderHt + $FooterHt + $typingIndicatorHeight));
    @media screen and (max-width: 550px) {
      height: calc(100vh - ($mobileHeaderHt + $FooterHt + $typingIndicatorHeight));
    }
    .message-bubble {
      display: flex;
      .message-content {
        max-width: 350px;
        height: auto;
        display: flex;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        padding: 15px 24px;
        margin-bottom: 10px;
        overflow-wrap: break-word;
        word-break: break-word;
        @media screen and (max-width: 767px) {
          width: 240px;
        }
      }
      .message-meta {
        display: flex;
        align-items: center;
        color: $gray-light-300;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 21px;
      }
    }
  }

  .chat-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: $FooterHt;
    position: relative;
    border-top: 1px solid #edeaf5;
    padding: 10px;
    p {
      position: absolute;
      font-style: italic;
      font-size: 14px;
      top: -27px;
      left: 38px;
    }
    textarea {
      @extend .custom-scroll;
      height: 45px;
      width: 100%;
      max-height: 55px;
      border: 3px solid $purple-300;
      border-radius: 25px;
      padding-left: 18px;
      padding-top: 6px;
      font-weight: 500;
      outline: none;
      resize: none;
      transition: height 0.2s ease-out;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10px;
      }
      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }
    }

    textarea:focus {
      border: 3px solid $purple-200;
    }
  }

  #you {
    justify-content: flex-end;
  }
  #you .message-content {
    justify-content: flex-start;
    background: #edeaf5;
  }

  #you .message-meta {
    justify-content: flex-end;
  }

  #other {
    justify-content: flex-start;
  }

  #other .message-content {
    background: #ebebeb;
    justify-content: flex-start;
  }

  #other .message-meta {
    justify-content: flex-start;
  }

  .pending-status-background {
    background: #ebebeb !important;
  }
  .failed-message {
    opacity: 0.7;
  }
}
.review-text {
  color: $gray-light-400;
}

.img-close {
  position: absolute;
  height: 34px;
  width: 34px;
  background: #fff url("../images/close-modal.png") center center no-repeat;
  background-size: 19px;
  right: 50px;
  top: 50px;
  border-radius: 100px;
  box-shadow: #313131 0 0 10px;
  cursor: pointer;
}

.vet-alert {
  background: #fff;
  box-shadow: 0px 4px 20px rgba(65, 40, 148, 0.2);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  @media screen and (max-width: 767px) {
    padding: 27px 10px;
  }
  &.yellow {
    background: #fdf9e9;
  }
  &.red {
    background: #fde8e8;
  }
  div {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #59595a;
    display: flex;
    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 18px;
      margin-right: 10px;
    }
    img {
      margin-right: 16px;
    }
  }
  span {
    color: #007a4c;
    text-decoration: underline;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
    &:hover,
    &::after {
      opacity: 0.8;
    }
    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.alert-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(65, 40, 148, 0.2);
  border-radius: 16px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #59595a;
  .chat-link {
    color: #007a4c;
    font-size: 16px;
    text-decoration: underline !important;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
    &:hover,
    &::after {
      opacity: 0.8;
    }
  }
  &.red {
    background-color: #fde8e8;
  }
}

.settings-menus-container {
  .settings-menus {
    padding: 16px 32px;
    padding-right: 0;
    border-top: 1px solid $lightestGray;
    cursor: pointer;
  }
  &:last-child {
    border-bottom: 1px solid $lightestGray;
  }
}

.small-switch {
  transform: scale(0.8);
}

.word-break {
  word-break: break-word;
}

.uni-call-out-modal {
  top: 167px !important;
  @media screen and (max-width: 1200px) {
    top: 152px !important;
  }
}

.uni-callout-site-header {
  position: static !important;
  margin-top: -105px;
}

.highlight-uni-callout {
  position: relative;
  z-index: 1051;
  background: #fff;
  justify-content: center;
}

.mImp {
  margin-top: 0 !important;
}

.highlight-uni-callout::after {
  content: "";
  border: 1px solid #fff;
  position: absolute;
  z-index: 1051;
  height: 16px;
  width: 16px;
  rotate: 45deg;
  border-bottom: 0;
  border-right: 0;
  background: #fff;
  bottom: -19px;
}

.low-usage-banner-wrapper {
  border: 3px solid $roo-light-purple-400;
  box-shadow: 0px 2px 16px rgba(65, 40, 148, 0.2);
  border-radius: 16px;
  padding: 20px;
  display: grid;
  grid-template-columns: 30px auto 20px;
  gap: 10px;
  color: $roo-dark-purple;
  .lu-close-icon {
    cursor: pointer;
  }
  a {
    color: $roo-dark-purple !important;
    text-decoration: underline !important;
  }
}

.block-user-wrapper {
  .form-label {
    padding-bottom: 12px;
  }
  .block-text {
    margin-bottom: 32px;
  }
  textarea {
    resize: none;
  }
  .discl-text {
    @extend .small-body-text;
    line-height: 20px !important;
    color: $gray-light-400;
  }
  .block-reason-title {
    @extend .body-text;
    @extend .text-blue;
    font-family: "Jost", sans-serif !important;
    margin-bottom: 12px;
  }
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.dashboard-confirmed-schedule-list-text {
  width: 70%;
  word-wrap: break-word;
}

.blurred-text {
  filter: blur(4px);
}

.disabled-input {
  background: #ebebeb;
  border: 1px solid #dbdffa;
  border-radius: 8px;
  padding: 12px 8px;
  color: #59595a;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 165px;
}

.disabled-accordion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  height: 36px;
  background: #ebebeb;
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #303031;
}

.tech-training-container {
  background: $roo-base-background;
  height: 100vh;
  padding: 84px 252px;
  h1,
  p {
    color: $roo-dark-purple;
  }
  h1 {
    font-size: 36px;
    margin-bottom: 42px;
  }
  p {
    margin-bottom: 32px;
    font-size: 20px;
  }
  .tech-training-video {
    width: 100%;
    height: 600px;
    margin-bottom: 33px;
  }
  button {
    background: $roo-dark-purple !important;
    width: 145px !important;
  }
  @media screen and (max-width: 1440px) {
    padding: 84px 144px;
  }
  @media screen and (max-width: 1040px) {
    padding: 84px 25px;
    button {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 800px) {
    padding: 84px 15px;
  }
}

.demo-banner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 16px;
  gap: 8px;
  width: 100%;
  height: auto;
  background: #6753a9;
  border: 3px solid #6753a9;
  box-shadow: 0px 2px 16px rgba(65, 40, 148, 0.2);
  border-radius: 16px;
  margin-bottom: 21px;
  p,
  span {
    font-size: 20px;
    line-height: 28px;
    color: #ffffff !important;
  }
  p {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    margin: 0 0 0 8px;
  }
  span {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-decoration: underline !important;
    cursor: pointer;
  }
}
.blocked-banner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 16px;
  gap: 8px;
  width: 100%;
  height: auto;
  background: #fff;
  border: 3px solid #6753a9;
  box-shadow: 0px 2px 16px rgba(65, 40, 148, 0.2);
  border-radius: 16px;
  margin-bottom: 21px;
  p,
  span {
    font-size: 16px;
    line-height: 24px;
    color: #412894 !important;
    font-family: "Jost", sans-serif;
    font-weight: 500;
  }
  p {
    margin: 0 0 0 8px;
  }
  span {
    text-decoration: underline !important;
    cursor: pointer;
  }
}

.chat-with-team-member {
  position: absolute;
  padding-top: 200px;
  margin-top: -264px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &.tech {
    margin-top: -232px;
  }

  &.empty {
    margin-top: 0px;
    padding-top: 60px;
  }

  &.single-row {
    margin-top: -24px;
    padding-top: 60px;
  }

  @media screen and (max-width: 1200px) {
    width: 1400px;
    left: -200px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    left: 0;
    padding-left: 8px;
    padding-right: 8px;
  }

  div {
    font-family: "Jost", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    position: relative;
    margin-left: 4%;
    @media screen and (max-width: 768px) {
      margin-left: 0%;
    }
  }
  p {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    margin-left: 4%;
    @media screen and (max-width: 768px) {
      margin-left: 0%;
    }
  }
  span {
    position: relative;
    margin-left: 4%;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      margin-left: 0%;
    }
  }
}

.chat-header-hospital {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
  gap: 15px;
}

.dissolve-animation {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.hospital-shift-container {
  &.uni {
    padding: 12px;
  }
  background-color: #fff;
  // margin-bottom: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.hospital-shift-container-text {
  background-color: #fff;
  padding: 24px;
  position: relative;
  left: -15px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  font-family: "Jost", sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: #412894;
  display: flex;
  justify-content: space-between;
  &.no-bg {
    background-color: transparent;
  }
}

.faq {
  ul {
    padding-left: 15px;
  }
  .small-label {
    color: $green-600;
  }
  .h2-header {
    padding-top: 40px;
  }
  .sub-section {
    @extend .label-text;
    margin-top: 28px;
    margin-bottom: 12px;
  }
}
.switch-view-v2 {
  border: 1px solid;
  border-radius: 8px;
  background: #ffffff;
  height: 50%;
  .view-button {
    display: flex;
    align-items: center;
    padding: 4px 24px;
    border-right: 1px solid #3747ab;
    text-align: center;
    @media (max-width: 576px) {
      &:first-child,
      &:last-child {
        padding: 4px;
      }
    }
    .view-label {
      @extend .body-text;
      color: #59595a;
      text-align: center;
      white-space: nowrap;
      &.d-desktop {
        display: block;
        padding-top: 1px; // fix calendar label position
      }
      &.d-mobile {
        display: none;
        padding-top: 1px; // fix calendar label position
      }
    }
    &:hover:not(.active) {
      cursor: pointer;
    }
    &.active {
      cursor: default;
    }
    &.active {
      cursor: default;
      background-color: #dbdffa;
      .view-label {
        color: #303031;
      }
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    &:last-child {
      border-right: none;
    }
  }
}

.mobile-tooltip {
  box-shadow: 0 2px 15px 0 rgba(65, 40, 148, 0.2);
  background: #fff;
  border-radius: 5px;
  color: #333;
  padding: 6px;
  position: absolute;
  overflow: hidden;
  z-index: 9999;
  width: 200px;
  span {
    display: block;
    padding: 0;
    font-size: 14px;
    font-weight: 550;
    @extend .secondaryFont;
  }
  &.tooltip-width {
    min-width: 253px;
  }
}

.info-card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  border-radius: 20px;
  background: white;
  margin-bottom: 52px;
}

.add-shift-btn {
  height: 40px !important;
  padding: 8px 32px 8px 32px !important;
  border-radius: 24px !important;
  border: 2px !important;
  font-family: "Jost", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  box-shadow: 0px 2px 4px 0px #41289433;
  cursor: pointer;
  &.vet {
    background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #b3a9d4, #b3a9d4) !important;
    border: 2px solid #b3a9d4 !important;
  }
  &.tech {
    background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #4cbfc1, #4cbfc1);
    border: 2px solid #4cbfc1 !important;
  }
  &:focus {
    outline: none;
  }
}

.info-box {
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
  label {
    color: $dark-gray !important;
    font-weight: 500 !important;
    font-family: "Poppins", sans-serif !important;
  }
  .info-popup {
    top: 50%;
  }
  ul {
    padding-left: 20px;
    > div {
      position: relative;
      left: -22px;
    }
  }
  &.p-24 {
    padding: 24px;
  }
}

.ratings-modal {
  .react-switch-bg {
    border: 1px solid $blue-dark;
  }
  ol {
    list-style-type: none;
    counter-reset: elementcounter;
    padding-left: 0;
    li {
      color: $dark-gray !important;
      &::before {
        content: counter(elementcounter) "- ";
        counter-increment: elementcounter;
      }
    }
  }
}
.updated-select {
  > div {
    > div {
      border-radius: 8px;
      border: 1px solid #dbdffa;
    }
  }
}

.duplicate-shift-modal-wrapper {
  padding: 0px !important;
  min-height: auto !important;
  overflow: auto !important;
  .custom-header {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 12px;
    margin-right: 12px;
    border-radius: 20px;
    background: #e6f2ee;
    color: $green-dark;
    img {
      width: 24px;
    }
  }
  .label-text > .text-green-dark {
    color: #178386 !important;
  }
  .DayPicker {
    position: unset !important;
    .DayPicker-Day--selected {
      background-color: $blue-lightest !important;
    }
  }
  .dates-wrapper {
    padding: 20px;
    background: $blue-lightest;
    border-radius: 20px;
    gap: 12px;
  }
}

.shift-readout {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 20px;
  background-color: $blue-lightest;
}

.fw-datepicker {
  .DayPicker {
    width: 100%;
    box-shadow: none !important;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    margin-top: 0 !important;
    .DayPicker-Month {
      width: 100%;
    }
    .DayPicker-Weekdays {
      display: none;
    }
  }
}
.shift-info-card {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(146, 159, 239, 0.2);
  .blue-text {
    color: #3747ab;
  }
  .DayPickerInput {
    height: 48px !important;
    &:after {
      background: url("../images/repeat_calendar.svg") center center no-repeat !important;
    }
  }
  .disabled {
    color: $gray-light-300;
    .DayPickerInput {
      &:after {
        background: url("../images/disabled_calendar.svg") center center no-repeat !important;
      }
    }
  }
}

.quick-add-shift-modal {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .shift-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .shift-info {
    @extend .shift-readout;
  }
  .no-shift {
    margin-top: 20px;
  }
  .roo-tab {
    padding: 8px 44px;
    width: auto;
    height: auto;
  }
}

.left-section-flex-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 767px) {
    gap: 24px;
  }
}

.right-section-flex-container {
  display: flex;
  flex-direction: column;
  gap: 44px;
  @media (max-width: 767px) {
    margin-top: 24px;
    gap: 24px;
  }
  .right-sidebar {
    border-radius: 20px !important;
  }
}

.add-shift-flex-container {
  display: flex;
  gap: 16px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
}

.disabled-text {
  opacity: 50%;
}

.boost-text-input {
  img {
    position: relative;
    z-index: 1;
    left: 10px;
  }
  input {
    min-height: 48px;
    position: relative;
    left: -25px;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: #fff;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    border: 2px solid #e8eaf7;
    overflow: auto;
    padding-left: 40px;
  }
  input:focus,
  input:hover {
    border-color: #e8eaf7;
    background-color: #fff;
  }
  div,
  span {
    font-size: 12px;
    line-height: 20px;
  }
  div {
    color: #303031;
    margin-top: 2px;
  }
  span {
    color: #ed4545;
  }
}

.shift-readout-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 554px;
  height: 554px;
  gap: 16px;
  padding: 24px;
  align-self: stretch;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(146, 159, 239, 0.2);
  .total-price {
    color: $blue-dark;
  }
}

.federal-holiday-alert {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 20px;
  background: $yellow-alert;
}

.review-enterprise-team {
  .h1-header {
    margin-bottom: 8px;
  }
  .content {
    margin-top: 40px;
    padding: 0px 0px 40px 0px;
    gap: 36px;
    display: flex;
    flex-direction: column;
  }
  .duplicate-upload-hospitals-container {
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: white;
    .duplicate-group {
      padding: 0px 0px 24px 0px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      &:not(:last-child) {
        border: 0px 0px 1px 0px;
        border-bottom: 1px solid #ebebeb;
      }
      .hospital-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
  .review-label {
    color: $blue-dark;
  }
  .uploading-hospital-row {
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #d6d6d6;
    background: #fff;
    &.unique {
      border: none;
      box-shadow: 0px 4px 20px 0px #929fef33;
    }
    .img-wrap {
      width: 44px;
      height: 44px;
      border-radius: 100px;
      overflow: hidden;
      box-shadow: 0 2px 8px 0 rgba(65, 40, 148, 0.09);
      img {
        width: 44px;
        height: 44px;
        border-radius: 100px;
      }
    }
    .map-pin-outline-dot {
      width: 14px;
      height: 21px;
      background: url("../images/map_pin_outline_dot.svg") center center no-repeat;
    }
    @media screen and (max-width: 768px) {
      &.unique {
        box-shadow: 0px 2px 4px 0px #3747ab33;
      }
    }
  }
}

.promo-banner-container {
  div {
    font-weight: 600 !important;
  }
}

.promo-offer-modal {
  display: flex;
  flex-direction: column;
  .promo-name {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    font-family: "Jost", sans-serif;
    color: #303031;
  }
  .banner-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 32px;
    color: #303031;
  }
  .description-text {
    font-size: 12px;
    line-height: 20px;
    color: #59595a;
    margin-bottom: 32px;
    font-weight: 400;
  }
}

.promo-tracking {
  max-width: 696px;
  border-radius: 20px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
    max-width: 100%;
    margin-top: 16px;
  }
  &.completed {
    background-color: #e9f8f8;
  }
  &.expired-promo {
    background-color: #fdf9e9;
  }
  .additional-text {
    &.active {
      display: none;
    }
    font-family: "Jost", sans-serif;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px;
    img {
      height: 32px;
      margin-right: 12px;
    }
  }
  .promo-tracking-top-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    div {
      display: flex;
      flex-direction: column;
    }
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #412894;
      &.completed,
      &.expired-promo {
        color: #303031;
      }
    }
    .banner-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #303031;
    }
    .read-offer {
      color: #007a4c;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-decoration: underline;
      cursor: pointer;
      min-width: 102px;
    }
  }
  .promo-tracking-bottom-container {
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
    .completed-shifts {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #303031;
    }
    .applied-shifts {
      color: #007a4c;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-decoration: underline;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        margin-top: 16px;
      }
    }
  }
}

.vet-bid-wrapper {
  background: white;
  border-radius: 28px;
  padding: 1px 24px 24px 24px;
  margin-top: 34px;
  max-width: 411px;

  h4 {
    color: #178386;
    font-family: Jost, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    margin-bottom: 12px;
  }

  .vb-input-label {
    color: #3747ab;
    /* Product/Desktop/Small Label */
    font-family: Jost, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    margin: 0;
  }

  .vb-input-wrapper {
    margin-bottom: 16px;
  }

  .vb-toggle-wrapper {
    p {
      margin: 0;
      color: #303031;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
  }

  .vb-hospital-price {
    p {
      color: #303031;
      font-family: Jost, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    margin-bottom: 40px;
  }
}

.shift-type-list {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 18px;
  align-self: stretch;
  flex-wrap: wrap;
  position: relative;

  .shift-type {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 12px;
    border-radius: 24px;
    border: 2px solid $purple-500;
    cursor: pointer;

    &.selected {
      background-color: $purple-500;
      color: white;
      .shift-type-icon {
        background-color: white;
      }
    }
    &.unselected {
      background-color: white;
      color: $purple-500;
      .shift-type-icon {
        background-color: $purple-500;
      }
    }

    .shift-type-icon {
      width: 24px;
      height: 24px;
      &.urgent {
        -webkit-mask: url("../images/local_hospital.svg") no-repeat center;
        mask: url("../images/local_hospital.svg") no-repeat center;
      }
      &.emergency {
        -webkit-mask: url("../images/flare.svg") no-repeat center;
        mask: url("../images/flare.svg") no-repeat center;
      }
      &.vaccine {
        -webkit-mask: url("../images/syringe.svg") no-repeat center;
        mask: url("../images/syringe.svg") no-repeat center;
      }
      &.wellness {
        -webkit-mask: url("../images/wellness_shift.svg") no-repeat center;
        mask: url("../images/wellness_shift.svg") no-repeat center;
      }
      &.non-well {
        -webkit-mask: url("../images/thermometer.svg") no-repeat center;
        mask: url("../images/thermometer.svg") no-repeat center;
      }
    }
  }
}

.promo-shift-panel {
  display: flex;
  flex-direction: column;
  .purple-line {
    width: 100%;
    height: 4px;
    background: #412894;
    margin: 4px 0 24px 0;
  }
  .shift-container {
    background-color: #edf0fd;
    border-radius: 16px;
    margin: 0px 20px;
    margin-bottom: 32px;
    padding-top: 20px;
    padding-bottom: 4px;
    .title {
      color: #303031;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin: 0 20px 4px 20px;
    }
    .supporting-text {
      color: #303031;
      font-size: 12px;
      line-height: 20px;
      margin: 0 20px 8px 20px;
      padding-bottom: 0;
    }
  }
  .empty-shift-text {
    color: #303031;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0px 40px;
  }
}

.promo-shift-panel-shift-card {
  margin: 16px 20px;
  border-radius: 16px;
  height: 280px;
  box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
  .top-container {
    display: flex;
    flex-direction: column;
    background-color: #b3a9d4;
    border-radius: 16px 16px 0px 0px;
    padding: 16px;
    gap: 4px;
  }
  .bottom-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0px 0px 16px 16px;
    padding: 16px;
    gap: 16px;
    height: 196px;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.admin-request-modal-expire-text {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #ae1111;
}

.admin-request-modal-vet-name {
  font-family: "Jost", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: #303031;
}

.admin-request-modal-label {
  color: #412894;
  /* Product/Desktop/Small Label */
  font-family: Jost, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.admin-request-modal-value {
  color: #495fe4;
  /* Product/Desktop/Small Label */
  font-family: Jost, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 0 8px;
  border-radius: 4px;
  background: #edf0fd;
}

.group-admin-list-container {
  display: flex;
  width: 1172px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  .header {
    display: flex;
    align-items: flex-end;
    gap: 12px;
    align-self: stretch;
  }
  .admin-info-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 44px;
    color: $gray-light-400;
    align-self: stretch;
    flex-wrap: nowrap;
    @media (max-width: 1040px) {
      gap: 16px;
      flex-direction: column;
    }
    .admin-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      .email-info {
        display: flex;
        align-items: flex-end;
        gap: 4px;
      }
    }
  }
}

.sign-up-modal-container {
  .user-type-option {
    border-radius: 12px;
    background: #edeaf5;
    margin-bottom: 20px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Jost", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    color: #303031;
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px rgba(55, 71, 171, 0.2);
  }
  .selected {
    background: #412894;
    color: white;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 20px;
    }
    .user-type-option {
      font-size: 24px;
    }
  }
}

.sign-up-modal-action-button {
  margin-bottom: 44px !important;
  font-size: 24px !important;
  height: 52px !important;
  border-radius: 24px !important;

  @media (max-width: 768px) {
    font-size: 20px !important;
    margin-bottom: 0px !important;
  }
}

#hospitalAvailableShift {
  .head a {
    font-size: 12px !important;
    line-height: 20px !important;
    font-weight: 600 !important;
  }
}

.dea-info-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .header {
    display: flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: $purple-500;
    border-bottom: 2px solid $purple-500;
  }
  .dea-slider {
    display: flex;
    padding: 12px 0px;
    align-items: center;
    align-content: center;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
    color: $blue-dark;
  }
}

.onboarding-banner {
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background: #79cfd1;
  box-shadow: 0px 4px 20px 0px rgba(146, 159, 239, 0.2);
  margin-bottom: 20px;

  h4 {
    color: #303031;
    font-family: "Jost", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    margin-bottom: 12px;
  }

  .onboarding-banner-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    flex-wrap: wrap;
    box-shadow: 0px 2px 4px 0px rgba(55, 71, 171, 0.2);
    border-radius: 12px;
    padding: 16px 12px;
    cursor: pointer;

    .step-title {
      max-height: 35px;
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }

      p {
        font-family: "Jost", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        color: #303031;
        margin: 0;
      }
    }

    @media (max-width: 475px) {
      a {
        margin-top: 10px;
      }
    }
  }
}

.quesionaire-title {
  color: #303031 !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  margin-bottom: 28px !important;
}

.calendly-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1100px;
}

.license-content {
  padding-top: 124px;
  padding-left: 132px;
  .license-table {
    td {
      min-width: 311px;
    }
  }
}

@media (max-width: 600px) {
  .request-shift-buttons {
    button + button {
      margin-top: 1rem;
    }
  }
}

.call-preperation-card-checklist {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  gap: 12px;

  .call-preperation-card-checklist-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
