// --- Hospital profile location map

.map-container {
  width: 100%;
  height: 300px;
  .mapPin {
    position: relative;
    display: block;
    border-radius: 50%;
    background: #ed4a3b;
    width: 22px;
    height: 24px;
    top: -22px;
  }
  .mapPin:before {
    position: absolute;
    content: "";
    border-radius: 10px;
    width: 8px;
    height: 8px;
    top: 8px;
    left: 7px;
    background: #a11005;
    z-index: 2;
  }
  .mapPin:after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    bottom: -20px;
    left: 3px;
    border: 8px solid transparent;
    border-top: 24px solid #ed4a3b;
  }
  .mapPin span {
    position: absolute;
    left: 100%;
    margin-left: 4px;
    top: 35%;
    @extend .secondaryFont;
    font-weight: 600;
    color: #a11005;
    font-size: 11px;
    line-height: 12px;
  }
}

// --- Shifts location map

.aspect-ratio-outter {
  width: 100%;
  height: 431px;
  margin-bottom: 24px;
  position: relative;
}
.new-aspect-ratio-outter {
  width: 100%;
  height: 431px;
  position: relative;
}
.aspect-ratio-inner-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 431px;
}
.map-shifts-container {
  width: 100%;
  height: 431px;
  > div {
    > div {
      border-radius: 24px;
    }
  }
  .map-bubble-pin {
    position: absolute;
    transform: translate(-50%, -75%);
    width: 64px;
    height: 47px;
    text-align: center;
    padding-top: 4px;
    background: url("../images/map_bubble_num_pin.svg") center center no-repeat;
    &.requested {
      background: url("../images/map_bubble_num_pin_req.svg") center center no-repeat;
    }
    &.internship-pin {
      width: 75px;
      height: 60px;
      font-size: 12px;
    }
    z-index: 0;
    @extend .secondaryFont;
    font-weight: 600;
    color: #412894;
    font-size: 16px;
    line-height: 21px;
    .sm {
      font-size: 11px;
      color: #735fb4;
    }
    cursor: pointer;
    &.active,
    &:hover,
    &.requested:hover {
      background: url("../images/map_bubble_num_pin_active.svg") center center no-repeat;
      color: #ffffff;
      .sm {
        color: #ffffff;
      }
    }
    &.active {
      cursor: default;
      z-index: 1;
    }
    &:hover:not(.active) {
      opacity: 0.8;
      z-index: 2;
    }
  }
  .map-bubble-pin-externship {
    position: absolute;
    transform: translate(-50%, -75%);
    width: 64px;
    height: 47px;
    text-align: center;
    padding-top: 4px;
    background: url("../images/map_bubble_num_pin.svg") center center no-repeat;
    &.requested {
      background: url("../images/map_bubble_num_pin_req.svg") center center no-repeat;
    }
    &.internship-pin {
      width: 75px;
      height: 60px;
      font-size: 12px;
    }
    z-index: 0;
    @extend .secondaryFont;
    font-weight: 600;
    color: $teal-dark600;
    font-size: 16px;
    line-height: 21px;
    .sm {
      font-size: 11px;
      color: $teal-dark600;
    }
    cursor: pointer;
    &.active,
    &:hover,
    &.requested:hover {
      background: url("../images/map_bubble_num_pin_active.svg") center center no-repeat;
      color: #ffffff;
      .sm {
        color: #ffffff;
      }
    }
    &.active {
      cursor: default;
      z-index: 1;
    }
    &:hover:not(.active) {
      opacity: 0.8;
      z-index: 2;
    }
  }
  .map-bubble-pin-event {
    position: absolute;
    transform: translate(-50%, -75%);
    width: 122px;
    height: 47px;
    text-align: center;
    background: url("../images/map_bubble_num_pin_event.svg") center center no-repeat;
    &.requested {
      background: url("../images/map_bubble_num_pin_req.svg") center center no-repeat;
    }
    &.internship-pin {
      width: 75px;
      height: 60px;
      font-size: 12px;
    }
    z-index: 0;
    @extend .secondaryFont;
    font-weight: 600;
    color: #339570;
    font-size: 16px;
    line-height: 21px;
    .date {
      position: relative;
      top: 4px;
    }
    .sm {
      font-size: 11px;
      color: #339570;
      position: relative;
      top: 3px;
      left: -1px;
    }
    cursor: pointer;
    &.active,
    &:hover,
    &.requested:hover {
      background: url("../images/map_bubble_num_pin_active_event.svg") center center no-repeat;
      color: #412894;
      .sm {
        color: #412894;
      }
    }
    &.active {
      cursor: default;
      z-index: 1;
    }
    &:hover:not(.active) {
      opacity: 0.8;
      z-index: 2;
    }
  }
}
.new-map-shifts-container {
  > div {
    > div {
      border-radius: 24px 24px 0 0;
    }
  }
}

.map-popup-wrapper {
  position: absolute;
  z-index: 98;
  top: 10px;
  left: 30%;
  width: 378px;
  &.search {
    width: 207px;
    height: 28px;
    top: 13px;
    left: 38%;
  }

  // We set this to 112 because the map-popup-overlay has a z-index of 111
  &.event-wrapper {
    z-index: 112;
  }

  .close-map-popup {
    z-index: 113;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 16px;
    top: 16px;
    background: url("../images/close.svg") center center no-repeat;
    cursor: pointer;
  }

  .close-map-popup-event {
    z-index: 113;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 4px;
    top: 16px;
    background: url("../images/close.svg") center center no-repeat;
    cursor: pointer;
  }
}

.map-limit-popup {
  width: 100%;
  background: #ffffff;
  padding: 40px 32px;
}

.search-area-popup {
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 32px;
  border: 2px solid #412894;
  box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
  border-radius: 24px;
  white-space: nowrap;
  font-family: 'Jost', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #412894;
  cursor: pointer;
  &.loading {
    color: transparent;
    border: none;
    box-shadow: none;
  }
}

.wave-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 32px;
	.dot {
		display:inline-block;
		width:10px;
		height:10px;
		border-radius:50%;
		margin-right:3px;
		background: #B3A9D4;
		animation: wave 1.3s linear infinite;

		&:nth-child(2) {
			animation-delay: -1.1s;
      background: #8D7EBF;
		}

		&:nth-child(3) {
			animation-delay: -0.9s;
      background: #6753A9;
		}
	}
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-10px);
	}
}

.search-loading-1 {
  background: url("../images/search-loading-1.svg") center center no-repeat;
  padding-top: 10px;
}
.search-loading-2 {
  background: url("../images/search-loading-2.svg") center center no-repeat;
  padding-top: 10px;
}
.search-loading-3 {
  background: url("../images/search-loading-3.svg") center center no-repeat;
  padding-top: 10px;
}
.search-loading-4 {
  background: url("../images/search-loading-4.svg") center center no-repeat;
  padding-top: 10px;
}
.search-loading-5 {
  background: url("../images/search-loading-5.svg") center center no-repeat;
  padding-top: 10px;
}
.search-loading-6 {
  background: url("../images/search-loading-6.svg") center center no-repeat;
  padding-top: 10px;
}

.date-list-popup {
  width: 100%;
  background: #ffffff;
  .popup-head {
    font-size: 18px;
    line-height: 29px;
    color: $title-primary;
    padding: 12px 12px 12px 88px;
    min-height: 102px;
    background: #fff;
    width: 100%;
    border-bottom: #495187 solid 14px;
    .img-wrap {
      width: 68px;
      height: 68px;
      border: 1px solid #ccc;
      border-radius: 100px;
      overflow: hidden;
      margin-right: 12px;
      position: absolute;
      left: 12px;
    }
  }
  .popup-body {
    .instructions {
      line-height: 24px;
      padding: 12px 0;
    }
    .requested {
      background-color: $requestedShift;
    }
    padding: 16px 24px;
    line-height: 40px;
    color: $text-secondary;
  }
}

.map-popup-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 111;
}

.map-legend {
  overflow: hidden;
  span {
    float: left;
    margin-left: 3px;
    padding: 10px 30px 10px 30px;
    position: relative;
    color: #949494;
    @extend .secondaryFont;
    font-size: 14px;
    line-height: 17px;
    background: transparent;
    &:before {
      content: "";
      height: 22px;
      width: 22px;
      border-radius: 22px;
      position: absolute;
      top: -1px;
      left: 0;
      bottom: 0;
      margin: auto;
      background: #e6e9f7;
      box-shadow: 0 2px 6px 0 rgba(65, 40, 148, 0.36);
    }
    &.requested {
      &:before {
        background: $requestedShift;
      }
    }
    &.legend-map-pin {
      &:before {
        background: #ffffff;
      }
    }
    &.hospital-map-pin {
      &:before {
        background: $blue;
      }
    }
    &.hospital-map-pin-warning {
      &:before {
        background: #ed4a3b;
      }
    }
    &.hospital-map-pin-lowFavorability {
      &:before {
        background: #800000;
      }
    }
    &.vet-map-pin {
      &:before {
        background: #b15ee5;
      }
    }
    &.tech-map-pin {
      &:before {
        background: #00b8c5; // darker
      }
    }
    &.no-icon {
      padding: 10px 30px 10px 2px;
      &:before {
        content: none;
      }
    }
  }
}

// no extension needed on desktop
.extend-bottom {
  visibility: hidden;
  width: 1px;
  height: 0;
  &.p-200 {
    padding-bottom: 200px;
  }
  &.p-400 {
    padding-bottom: 400px;
  }
  &.p-600 {
    padding-bottom: 600px;
  }
  &.p-800 {
    padding-bottom: 800px;
  }
  &.p-1000 {
    padding-bottom: 1000px;
  }
  &.p-2000 {
    padding-bottom: 2000px;
  }
  &.p-8000 {
    padding-bottom: 8000px;
  }
}

// --- Admin user's map (builds on shift's map above)

.aspect-ratio-outter {
  &.users-map {
    height: 750px;
    padding-bottom: 20px;
  }
}
.map-users-container {
  width: 100%;
  height: 100%;
  .map-circle-pin {
    &.hospital {
      background: $blue; // #ed4a3b;
    }
    &.hospital-warning {
      background: #ed4a3b;
    }
    &.hospital-lowFavorability {
      background: #800000;
    }
    &.vet {
      background: #b15ee5;
    }
    &.tech {
      background: #029faa;
    }
    position: absolute;
    top: -11px;
    left: -11px;
    text-align: center;
    height: 22px;
    width: 22px;
    border-radius: 22px;
    box-shadow: 0 2px 6px 0 rgba(65, 40, 148, 0.38);
    // z-index: 0;
    // @extend .secondaryFont;
    color: #fff;
    font-size: 11px;
    line-height: 22px;
    cursor: pointer;
    &.active,
    &:hover {
      color: #351d85;
      background: white;
    }
    &.active {
      cursor: default;
      z-index: 1;
    }
    &:hover:not(.active) {
      opacity: 0.8;
      z-index: 2;
    }
  }
}

.hospital-color {
  color: $blue; // #ed4a3b;
}
.vet-color {
  color: #b15ee5;
}
.tech-color {
  color: #029faa; // darker
}

.map-popup-wrapper {
  &.users-map {
    top: 200px;
  }
}
// ---- mobile view

// 767, 576
@media all and (max-width: 767px) {
  .map-popup-wrapper {
    top: 10px;
    left: 27%;
    width: 250px;
  }

  .extend-bottom {
    height: 300px;
  }
}
