.policies-wrapper {
   padding: 0px 137px;
   @media screen and (max-width: 768px) {
    padding: 0px 20px;
   }
    
   h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    color: #412894;
    margin-bottom: 24px;
    margin-top: 40px;
   } 
   .policies-tab-title {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    div {
        font-family: 'Jost', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        width: 216px;
        height: 60px;
        padding: 8px 12px;
        border-radius: 12px;
        box-shadow: 0px 2px 4px 0px rgba(55, 71, 171, 0.20);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
   }

   .mobile-tab-selector {
        background-color: #412894;
        color: white;
        width: 100%;
        font-family: 'Jost', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        height: 60px;
        padding: 8px 12px;
        border-radius: 12px;
        box-shadow: 0px 2px 4px 0px rgba(55, 71, 171, 0.20);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 24px;
        position: relative;
   } 

   .mobile-tab-dropdown {
    background: #FFF;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(55, 71, 171, 0.20);
    position: absolute;
    width: 100%;
    top: 70px;

    .first-item {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }
    .last-item {
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
    }
    div {
        height: 60px;
        color: #303031;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid  #EDEAF5;
    }
   }

   .default-tab {
    background:  #FFF;
   } 

   .selected-tab {
    background-color: #412894;
    color: white !important;
   }

   .policies-section-wrapper {
    border-radius: 28px;
    background: #FFF;
    padding: 24px 20px;
    margin-bottom: 24px;

    h5 {
        font-family: 'Jost', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #178386;
    }
    p,li {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        color: #303031;
    }
    p {
        margin-bottom: 5px;
    }

    ul {
        margin: 0
    }
   }
   .spacer {
    margin-bottom: 35px;
   }
}

.ant-drawer-custom-policies {
    .ant-drawer-close {
        position: absolute;
        top: 20px;
        right: 0;
     }
    
     .ant-drawer-title {
        color:  #412894 !important;
        font-family: 'Jost', sans-serif !important;
        font-size: 24px !important;
        font-style: normal !important;
        font-weight: 400 !important;
     }
     .ant-drawer-wrapper-body {
        position: relative;
     }
}

.cancel-drawer-wrapper {
    .policies-section-wrapper {
        width: 100%;
        border-radius: 28px;
        background:  #FFF;
        padding: 24px 20px;
        margin-bottom: 24px;
        h5 {
            color:  #178386;
            font-family: 'Jost', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
        }

        .paragraph-wrapper{
            p, li, ul {
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                color: #303031;
                margin: 0;
            }
        }
    }

       .spacer {
        margin-bottom: 30px;
    }

    .cancel-survey-wrapper {
        background: white;
        width: 100%;
        min-height: 113px;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .survey-unopen {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .survey-open {
        padding: 24px;
        h5 {
            color:  #178386;
            font-family: 'Jost', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 15px;
        }
    }

    .first-paragraph {
        h3 {
            color: #303031;
            font-family: 'Poppins', sans-serif ;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
        }
        span {
            color: #303031;
            font-family: 'Poppins', sans-serif ;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
        }
        a {
            font-size: 12px;
        }
        div {
            height: 12px;
        }
    }

}