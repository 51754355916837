#root,
body {
  div {
    .uni-panel-header {
      cursor: pointer;
      flex: 1;
      height: 40px;
      text-align: center;
      font-size: 20px;
      font-family: Jost;
      font-weight: 500;
      border-bottom: 4px solid $purple-100;
      display: flex;
      color: $dark-gray;
      &.selected {
        border-bottom: 4px solid $purple-500;
        color: $purple-500;
      }
      &.unselected {
        border-bottom: 4px solid $purple-100;
        color: $dark-gray;
      }
    }
    .uni-request-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      z-index: 10000;
      position: fixed;
      pointer-events: none;
    }
    .uni-request-container .request-btn-container {
      margin-top: auto;
      bottom: 0;
      right: 0;
      height: 92px;
      background-color: white;
      box-shadow: 0px 4px 20px 0px #929fef33;
      pointer-events: auto;
      margin-bottom: 0;
      &.desktop {
        margin-bottom: 35px;
        width: 460px;
      }
    }
    .uni-panel-info-header {
      color: $teal-dark600;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 12px;
    }
    .uni-hospital-name {
      color: #242425;
      font-family: Jost;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      max-width: 260px;
    }
    .uni-hospital-address {
      color: $green-dark;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  .uni-hospital-img {
    border-radius: 100px;
    height: 83px;
    width: 83px;
  }
}
