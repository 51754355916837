#root,
body {
  .banner-note {
    padding: 12px 10px;
    background-color: #ffdfb3; // #ebe6fd;
    margin-right: 12px;
  }
  .accordion-parent-note-primary {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #ebe6fd;
    color: $title-primary;
    border-radius: 12px;
    line-height: 24px;
    text-align: center;
    margin-left: 6px;
  }
  .accordion-parent-note {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #d6d6d6;
    border-radius: 12px;
    line-height: 24px;
    text-align: center;
    margin-left: 6px;
  }
  .vet-dashboard {
    & > h2 {
      @extend .secondary-title;
      color: $title-primary;
      font-weight: normal;
    }
    .text-info-row {
      max-width: 800px;
      &.vet {
        max-width: 830px;
      }
    }
    .text-info-button {
      padding-right: 4px;
      cursor: pointer;
      color: $primary-color;
      font-size: 16px;
      display: inline-block;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
    .info-button-primary {
      background: url("../images/info_primary.svg") center top no-repeat;
      background-size: 100%;
      display: inline-block;
      height: 26px;
      width: 26px;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        background-size: 65% !important;
        height: 18px;
      }
    }
    .filter-wrapper {
      // padding-bottom: 20px;
      z-index: 110;
      form {
        .form-group {
          margin: 0;
          .form-control {
            height: 48px;
          }
          .locaition {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              top: 0;
              right: 10px;
              bottom: 0;
              margin: auto;
              height: 26px;
              width: 26px;
              background: url("../images/locate.svg") center center no-repeat;
            }
          }
          .clear-miles {
            font: 15px Poppins, sans-serif;
            color: $green-dark;
            cursor: pointer;
          }
          .search-hospital {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              top: 0;
              right: 10px;
              bottom: 0;
              margin: auto;
              height: 20px;
              width: 20px;
              background: url("../images/search_ic.svg") center center no-repeat;
            }
          }
        }
      }
      .date-picker-wrapper {
        @mixin date-picker-p {
          height: 48px;
          margin: 0;
          line-height: 32px !important;
          &.focused {
            border-color: #a9b0db;
          }
        }
        .DayPickerInput {
          &.date-picker-p {
            @include date-picker-p;
          }
          &.date-picker-p-v2 {
            @include date-picker-p;
          }
        }
        .DayPicker .DayPicker-Months .DayPicker-Weekdays {
          display: none;
        }
        .DayPicker {
          .DayPicker-Months .DayPicker-Body {
            .DayPicker-Day {
              padding: 6px 10px;
            }
          }
          .DayPicker-Day {
            position: relative;
            &.DayPicker-Day--selected {
              &:before {
                display: none;
              }
              &:after {
                content: "";
                width: 100%;
                position: absolute;
                top: 3px;
                bottom: 3px;
                right: 0;
                background: #eff0fa;
                z-index: -2;
              }
              &.DayPicker-Day--start,
              &.DayPicker-Day--end {
                &:before {
                  content: "";
                  background: rgba(185, 193, 236, 0.6);
                  border-radius: 100px;
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  width: 25px;
                  height: 25px;
                  margin: auto;
                  pointer-events: none;
                  z-index: -1;
                  display: block;
                }
                &:after {
                  width: 100%;
                }
              }
              &.DayPicker-Day--start {
                &:after {
                  border-radius: 100px 0 0 100px;
                  left: 5px;
                }
              }
              &.DayPicker-Day--end {
                &:after {
                  border-radius: 0 100px 100px 0;
                  right: 5px;
                }
              }
            }
          }
        }
      }
      .Select-control {
        height: 48px;
        .Select-multi-value-wrapper {
          height: 44px;
        }
        .Select-arrow-zone:after {
          top: 14px;
        }
        // .Select-value .Select-value-label {
        //   // line-height: 46px; // was breaking multi-select in advanced search
        // }
      }
      .Select.is-open .Select-menu-outer {
        width: auto;
      }
      .actions {
        display: flex;
        height: 100%;
        .btn {
          min-width: 180px;
          margin-bottom: 4px;
        }
        & > * {
          align-self: flex-end;
        }
        & > a {
          width: calc(100% - 110px);
          float: left;
          height: 48px;
          line-height: 30px;
          font-weight: bold;
        }
        .switch-view {
          width: 162px;
          justify-content: between;
          margin-left: 16px;
          .header {
            font-size: 16px;
            color: #5862a3;
            padding-bottom: 4px;
            text-align: center;
            user-select: none;
          }
          .view-button {
            margin: 0 auto;
            text-align: center;
            width: 54px;
            .view-label {
              font-size: 12px;
              color: #949494;
              text-align: center;
              white-space: nowrap;
              // border: 1px solid green;
              &.d-desktop {
                display: block;
                padding-top: 1px; // fix calendar label position
              }
              &.d-mobile {
                display: none;
                padding-top: 1px; // fix calendar label position
              }
            }
            :hover:not(.active) {
              cursor: pointer;
            }
            &.active {
              cursor: default;
            }
            &.active,
            :hover {
              cursor: default;
              .view-label {
                cursor: default;
                color: #785fcd;
              }
            }
            .view-icon-wrap {
              margin-left: 12px;
              width: 30px;
              height: 30px;
              &.pad-grid-icon {
                padding: 3px 0 3px 1px;
                &.active {
                  cursor: default;
                }
              }
              .view-icon {
                width: 100%;
                height: 100%;
                background-size: contain;
                &.grid-icon {
                  background: url("../images/grid_view_ic.svg") center center no-repeat;
                  &.active {
                    background: url("../images/grid_view_ic_active.svg") center center no-repeat;
                  }
                }
                &.cal-icon {
                  background: url("../images/cal_view_ic.svg") center center no-repeat;
                  &.active {
                    background: url("../images/cal_view_ic_active.svg") center center no-repeat;
                  }
                }
                &.map-icon {
                  background: url("../images/map_view_ic.svg") center center no-repeat;
                  &.active {
                    background: url("../images/map_view_ic_active.svg") center center no-repeat;
                  }
                }
              }
            }
          }
        }
        .switch-view-v2 {
          border: 1px solid #3747ab;
          border-radius: 8px;
          background: #ffffff;
          .view-button {
            display: flex;
            align-items: center;
            padding: 4px 24px;
            border-right: 1px solid #3747ab;
            text-align: center;
            .view-label {
              @extend .body-text;
              color: #59595a;
              text-align: center;
              white-space: nowrap;
              &.d-desktop {
                display: block;
                padding-top: 1px; // fix calendar label position
              }
              &.d-mobile {
                display: none;
                padding-top: 1px; // fix calendar label position
              }
            }
            &:hover:not(.active) {
              cursor: pointer;
            }
            &.active {
              cursor: default;
            }
            &.active {
              cursor: default;
              background-color: #dbdffa;
              .view-label {
                color: #303031;
              }
              &:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
              }
              &:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
              }
            }
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
    .search-result-wrapper {
      padding-top: 20px;
      min-height: 600px;
      padding-bottom: 120px;
      @media screen and (max-width: 768px) {
        padding-bottom: 180px;
      }

      .result-count,
      .shift-box-grid .result-count {
        display: block;
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        @extend .secondaryFont;
        color: $text-secondary;
      }
      .result-count-v2,
      .shift-box-grid .result-count-v2 {
        display: block;
        margin-bottom: 0px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        @extend .tertiaryFont;
        @extend .gray-base;
        & > i {
          font-style: normal !important;
        }
        padding-bottom: 12px;
      }

      .no-result-message {
        p {
          color: $title-primary;
          font-size: 18px;
          line-height: 26px;
          text-align: center;
        }
        p.lg {
          font-size: 20px;
          line-height: 28px;
        }
      }
      .left-col {
        width: calc(100% - 310px);
        float: left;
        &:after {
          content: ".";
          visibility: hidden;
        }
        .export-to-calendar {
          background: #ffffff;
          border: 1px solid #e8eaf7;
          box-shadow: 0 2px 15px 0 rgb(65 40 148 / 9%);
          border-radius: 2px;
          padding: 12px;
        }
      }
      .right-col {
        width: 300px;
        padding-left: 30px;
        float: left;
        .date-picker-wrapper {
          &.relative-picker {
            .DayPicker-NavBar {
              .DayPicker-NavButton--prev,
              .DayPicker-NavButton--next {
                top: 50px;
                z-index: 10;
              }
            }
            .DayPicker {
              border-radius: 5px;
              background-color: #ffffff;
              box-shadow: 0 2px 8px 0 rgba(65, 40, 148, 0.09);
              margin-top: 0;
              .DayPicker-Months {
                .DayPicker-Caption {
                  padding-top: 40px;
                  position: relative;
                  &:before {
                    content: "Your Schedule";
                    @extend .secondaryFont;
                    color: $para-primary;
                    font-size: 14px;
                    line-height: 19px;
                    position: absolute;
                    top: 6px;
                    left: 10px;
                    font-weight: normal;
                  }
                }
                .DayPicker-Weekdays {
                  &:before {
                    content: "";
                  }
                  .DayPicker-WeekdaysRow {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      .shift-box-grid {
        // -moz-column-count: 3;
        // -moz-column-gap: 26px;
        // -webkit-column-count: 3;
        // -webkit-column-gap: 26px;
        // column-count: 3;
        // column-gap: 26px;
        margin-left: -15px;
        margin-right: -15px;
        div[class^="col-"],
        div[class*=" col-"] {
          margin-bottom: 30px;
          // break-inside: avoid;
          display: flex;
        }
        &.full-width-grid {
          div[class^="col-"],
          div[class*=" col-"] {
            width: 100% !important;
            flex: 0 0 100% !important;
            max-width: 100% !important;
          }
        }
      }
    }
  }
  .edit-vet-profile {
    .custom-check [type="checkbox"]:not(:checked) + label {
      margin: 0 !important;
    }
  }
  .inner-container {
    .vet-dashboard {
      .first-time-modal {
        h6 {
          color: $title-primary;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 30px 30px 40px;
          li {
            label {
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 1.13px;
              line-height: 29px;
              color: $primary-color;
              position: relative;
              padding-left: 22px;
              margin: 0;
              &.first,
              &.second,
              &.third {
                &:before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                }
              }
              &.first {
                &:before {
                  content: "1.";
                }
              }
              &.second {
                &:before {
                  content: "2.";
                }
              }
              &.third {
                &:before {
                  content: "3.";
                }
              }
            }
          }
          p {
            padding-left: 30px;
          }
        }
        .btn-group {
          padding-top: 40px;
        }
      }
    }
  }
  .table-container {
    .vet-status-table {
      tbody tr td {
        font-size: 14px;
      }
    }
  }
  .calender-view-msg {
    display: block;
    width: 100%;
    margin-bottom: 30px;
    color: #412894;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }
  .favorites-updated-msg {
    position: absolute;
    color: #535353; // "#412894", // "#5862a3",
    border: 1px solid #cccccc;
    left: 50px;
    background-color: #fff;
    width: 230px;
    padding: 16px 16px 18px 16px;
    box-shadow: 1px 0px 12px 2px #cccccc;
    z-index: 100;
    pointer-events: none;
  }

  .vet-bidding {
    .propose {
      span {
        font-family: "Jost", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #3747ab;
      }
    }
  }
}

@media all and (max-width: 1300px) {
  #root,
  body {
    // .vet-dashboard .search-result-wrapper .shift-box-grid {
    //     -moz-column-count: 2;
    //     -moz-column-gap: 26px;
    //     -webkit-column-count: 2;
    //     -webkit-column-gap: 26px;
    //     column-count: 2;
    //     column-gap: 26px;
    // }
  }
}

@media all and (max-width: 1200px) {
  #root,
  body {
    .vet-dashboard {
      .filter-wrapper {
        position: relative;
        .actions {
          .switch-view {
            position: absolute;
            top: -52px;
            right: 15px;
          }
          .btn {
            width: 100%;
            min-width: inherit;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1023px) {
  #root,
  body {
    .vet-dashboard {
      h2 {
        font-size: 16px;
        line-height: 26px;
      }
      .search-result-wrapper {
        .left-col {
          width: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) and (min-width: 991px) {
  #root,
  body {
    .calender-view-msg {
      display: none;
    }
  }
}
@media all and (max-width: 767px) {
  #root,
  body {
    .vet-dashboard {
      .filter-wrapper {
        .Select-control {
          height: 40px;
          .Select-multi-value-wrapper {
            height: 36px;
          }
          .Select-value .Select-value-label {
            line-height: 36px;
            font-size: 13px;
          }
          .Select-arrow-zone:after {
            top: 9px;
            width: 16px;
          }
        }
        .date-picker-wrapper {
          .DayPicker-NavBar {
            .DayPicker-NavButton--prev,
            .DayPicker-NavButton--next {
              top: 8px;
              height: 28px;
              width: 28px;
            }
            .DayPicker-NavButton--prev {
              left: 15px;
            }
          }
          .DayPicker.Range {
            width: calc(100vw - 30px);
            .DayPicker-Months .DayPicker-Month {
              width: 100%;
              .DayPicker-Caption {
                text-align: center;
                text-transform: uppercase;
              }
            }
          }
          .DayPickerInput.date-picker-p {
            height: 40px;
            margin: 0;
            line-height: 25px !important;
            font-size: 13px;
            span {
              white-space: nowrap;
              overflow: hidden;
              width: 75px;
              text-overflow: ellipsis;
              display: block;
            }

            &:after {
              width: 20px;
              background-size: 100%;
            }
          }
        }
        form {
          .form-group {
            .form-control {
              height: 40px;
              font-size: 13px;
              width: 100%;
              display: block;
            }
            input::placeholder {
              white-space: normal;
              word-break: break-all;
              width: 80%;
            }
            input::-moz-placeholder {
              white-space: normal;
              word-break: break-all;
              width: 80%;
            }
            input::-webkit-placeholder {
              white-space: normal;
              word-break: break-all;
              width: 80%;
            }
            input::-ms-input-placeholder {
              white-space: normal;
              word-break: break-all;
              width: 80%;
            }
            input::-o-input-placeholder {
              white-space: normal;
              word-break: break-all;
              width: 80%;
            }

            .locaition:after {
              width: 20px;
              height: 20px;
              background-size: 100%;
            }
          }
        }
        .sm-action {
          .actions {
            .switch-view {
              .header {
                display: none;
              }
              .view-button {
                .view-label {
                  &.d-mobile {
                    display: block;
                  }
                  &.d-desktop {
                    display: none;
                  }
                }
                .view-icon-wrap {
                  width: 26px;
                  height: 26px;
                  :hover:not(.active) {
                    cursor: pointer;
                  }
                  &.pad-grid-icon {
                    padding: 2px 0 2px 2px;
                  }
                  .view-icon {
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    // &.grid-icon {
                    //   background: url("../images/ic_search.svg") center center no-repeat;
                    //   &.active {
                    //     background: url("../images/ic_search_active.svg") center center no-repeat;
                    //   }
                    // }
                    &.cal-icon {
                      background: url("../images/ic_shifts.svg") center center no-repeat;
                      &.active {
                        background: url("../images/ic_shifts_active.svg") center center no-repeat;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .Select {
        &.is-open {
          .Select-option {
            font-size: 14px;
          }
        }
      }
      .search-result-wrapper .shift-box-grid div[class^="col-"],
      .search-result-wrapper .shift-box-grid div[class*=" col-"] {
        height: auto !important;
      }
    }
    .inner-container {
      .vet-dashboard {
        .first-time-modal {
          p {
            margin-bottom: 20px !important;
          }
          ul {
            padding: 0;
          }
          .btn-group {
            margin: 0 !important;
            padding: 0 0 50px !important;
          }
        }
      }
    }
    .table-container {
      .table {
        thead th,
        tbody tr td {
          a {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            white-space: normal;
            max-width: 120px;
            &.legend {
              white-space: normal;
              &:before {
                display: none;
              }
            }
          }
        }
        .mb-ellipsis {
          display: block;
          // width: 70px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
@media all and (max-width: 400px) {
  #root {
    .table-container {
      .table {
        thead th,
        tbody tr td {
          padding-left: 8px;
          padding-right: 8px;
          font-size: 14px;
          &.max-width-250,
          &.max-width-200 {
            max-width: 70px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    a.btn-sm-text {
      padding: 9px;
    }
  }
}

.ic-contract-container {
  height: calc(100vh - 330px);
  overflow-y: hidden;
  background: #ffffff;
  border-radius: 12px;
  margin-left: -30px;
  margin-right: -30px;
  .ic-contract-content {
    padding: 15px 30px;
    overflow-y: auto;
    max-height: 100%;
  }
}

@media all and (max-width: 340px) {
  #root {
    .vet-dashboard {
      .filter-wrapper {
        form {
          .form-group {
            .form-control {
              &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #fff;
              }
              &::-moz-placeholder {
                /* Firefox 19+ */
                color: #fff;
              }
              &:-ms-input-placeholder {
                /* IE 10+ */
                color: #fff;
              }
              &:-moz-placeholder {
                /* Firefox 18- */
                color: #fff;
              }
            }
          }
        }
      }
    }
    .calender-view-msg {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
