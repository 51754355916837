.rc-slider {
  height: 3px;
  padding: 5px 0;
  border-radius: 0;
  margin-top: 15px;
  .rc-slider-rail {
    background-color: #d9d7d7;
    height: 3px;
    border-radius: 0;
  }
  .rc-slider-track {
    height: 3px;
    border-radius: 0;
    background-color: #007a4c;
    z-index: 2;
  }
  .rc-slider-step {
    height: 3px;
  }
  .rc-slider-handle {
    width: 26px;
    height: 26px;
    border: 0;
    background-color: #007a4c;
    box-shadow: 0px 2px 5px rgba(134, 134, 134, 0.375);
    margin-top: -11px;
  }
  .rc-slider-dot {
    bottom: -6px;
    margin-left: 0;
    width: 2px;
    height: 15px;
    background-color: #d9d7d7;
    border-radius: 0;
    &.rc-slider-dot-active {
      border-color: transparent;
    }
  }
  .rc-slider-mark {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .rc-slider-mark-text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: #949494;
  }
}
