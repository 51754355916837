body {
  #root {
    .taxinfo {
      &.container {
        max-width: 800px;
        margin: 0 auto;
      }
      h5 {
        color: #412894;
        // font-size: 24px;
        // line-height: 36px;
      }
      ul {
        margin-bottom: 74px; // adjust for internal anchor link landings
      }
      dt {
        color: #5862a3;
        padding-bottom: 5px;
        font-weight: 400;
      }
      dl {
        padding-bottom: 20px;
        // adjust for internal anchor link landing
        padding-top: 74px;
        margin-top: -74px;
      }
      dd {
        line-height: 26px;
        color: $text-secondary;
        font-size: 14px;
        @media screen and (min-width: 767px) {
          font-size: 16px;
        }
      }
      p {
        color: $text-secondary;
        line-height: 26px;
      }
      a {
        font-weight: 600;
      }
      li {
        padding-bottom: 5px;
      }
      .disclaimer {
        color: $title-secondary; // #dc3545;
        font-size: 14px;
        font-weight: 400;
      }
      .definition-links {
        border-bottom: 2px solid rgba(185, 193, 236, 0.3);
        font-weight: 600;
        padding-bottom: 20px;
        a {
          line-height: 1.6;
        }
      }
    }
  }
}
