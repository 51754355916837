// #root,
body {
  .flex-fixed-width-130 {
    flex: 0 0 130px;
  }
  .flex-fixed-width-150 {
    flex: 0 0 150px;
  }
  .flex-fixed-width-170 {
    flex: 0 0 170px;
  }
  .flex-fixed-width-320 {
    flex: 0 0 324px;
  }
  .flex-fixed-width-340 {
    flex: 0 0 335px;
  }
  .flex-fixed-width-360 {
    flex: 0 0 360px;
  }
  .flex-fixed-width-370 {
    flex: 0 0 369px;
  }
  .flex-fixed-width-250 {
    flex: 0 0 250px;
  }
  .flex-fixed-width-280 {
    flex: 0 0 280px;
  }
  .flex-fixed-width-270 {
    flex: 0 0 270px;
  }
  .flex-fixed-width-200 {
    flex: 0 0 200px;
  }
  .custom-check {
    &.below-label {
      padding-bottom: 8px;
      label {
        // reposition hidden label used for positioning checkbox
        left: 40px;
        top: -10px;
      }
    }
  }

  .advanced-search {
    .title-button {
      cursor: pointer;
      font: 16px Poppins, sans-serif;
      font-weight: 600;
      color: $primary-color; // $title-secondary;
      padding-right: 50px;
      position: relative;
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        background: url("../images/expand_green.svg") center center no-repeat;
        position: absolute;
        right: 33px;
        top: 0;
        bottom: 0;
        margin: auto;
        @extend .transition;
        transform-style: preserve-3d;
      }
      &.open {
        &:after {
          transform: rotateX(-180deg);
        }
      }
    }
    .title-note-section {
      display: inline-block;
    }
    .title-note {
      font: 15px Poppins, sans-serif;
      color: $title-secondary;
    }
    .clear-filter {
      font: 15px Poppins, sans-serif;
      color: $green-dark;
      padding-left: 60px;
      padding-right: 20px;
      cursor: pointer;
    }
    .Select--multi .Select-control .Select-multi-value-wrapper {
      min-height: 36px;
      line-height: 42px;
      .Select-value {
        margin: 4px 0 0 4px;
        min-height: 36px;
        .Select-value-label {
          min-height: 32px;
          line-height: 32px;
        }
        .Select-value-icon {
          top: 1px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  // #root,
  body {
    .custom-check {
      &.below-label {
        label {
          // reposition hidden label used for positioning checkbox
          left: 0;
          top: -14px;
        }
      }
    }
    .advanced-search {
      .title-note-section {
        display: flex;
        justify-content: space-between;
      }
      .Select--multi .Select-control .Select-multi-value-wrapper {
        line-height: 32px;
        .Select-value {
          min-height: 28px;
          .Select-value-label {
            min-height: 24px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

@media (max-width: 492px) {
  // #root,
  body {
    .flex-fixed-width-130 {
      flex: 1 0 130px;
    }
    .flex-fixed-width-150 {
      flex: 1 0 150px;
    }
    .flex-fixed-width-170 {
      flex: 1 0 170px;
    }
    .flex-fixed-width-360 {
      flex: 1 1 360px;
    }
  }
}
