.page {
  background: $blue-lightest;
}
.roo-button {
  box-shadow: 0px 2px 4px #d6d6d6;
  border-radius: 24px;
  gap: 8px;
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  text-transform: capitalize;
  z-index: 1;
  cursor: pointer;
  border: unset;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &.regular {
    padding: 8px 32px;
    height: 39px;
  }
  &.small {
    padding: 2px 32px;
    height: 29px;
  }
  &.xs {
    padding: 2px 20px;
    height: 29px;
  }
  &.primary {
    background: $title-primary;
    color: #ffffff !important;
    border: 0;
    &:hover {
      background: $purple-600;
    }
    &:active {
      background: $purple-700;
      outline: none;
    }
    &:focus {
      background: $purple-500;
      border: 2px solid $purple-500;
      box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
      outline: none;
    }
    &:disabled {
      background: $purple-200;
      border: 1px solid $purple-200;
      cursor: unset;
      mix-blend-mode: normal;
    }
  }
  &.secondary {
    background: #ffffff;
    border: 2px solid $title-primary;
    color: $title-primary;
    &.tech-shift {
      border: 2px solid $blue-200;
      color: $dark-gray;
    }
    &:hover {
      border: 2px solid $purple-600;
      color: $purple-600;
    }
    &:active {
      border: 2px solid $purple-700;
      color: $purple-700;
    }
    &:focus {
      border: 2px solid $purple-500;
      color: $purple-500;
      box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
      outline: none;
    }
    &:disabled {
      border: 2px solid $purple-200;
      color: $purple-200;
      mix-blend-mode: normal;
    }
  }
  &.tech-primary {
    background: $blue-200;
    color: $dark-gray;
    &:hover:not(:disabled) {
      background: $blue-300;
    }
    &:active:not(:disabled) {
      background: $dark-blue;
      outline: none;
    }
    &:focus {
      background: $light-blue;
      border: 2px solid $purple-500;
      box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
      outline: none;
    }
  }
}

.header-font-family {
  font-family: "Jost", sans-serif;
  font-style: normal;
}

.h1-header {
  @extend .header-font-family;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 52px;
  text-transform: capitalize;
}

.h2-header {
  @extend .header-font-family;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 0;
}

.h3-header {
  font-family: "Jost";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.h4-header {
  font-family: "Jost";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 0;
}

.h5-header {
  font-family: "Jost";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-transform: capitalize;
}

.page-header {
  @extend .h1-header;
  color: $title-primary;
  margin-top: 20px;
  margin-bottom: 4px;
}

.sub-head {
  @extend .header-font-family;
  font-weight: 400;
  font-size: 32px;
  line-height: 46px;
}

.label-text {
  font-family: "Jost";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.small-label {
  font-family: "Jost";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.body-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.body-regular-text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.small-body-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.medium-body-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.small-body-text-green-dark {
  @extend .small-body-text;
  color: $green-dark;
}

.white-bordered-box {
  background: #ffffff;
  border: 2px solid #dbdffa;
  border-radius: 12px;
}

.caret-arrow {
  width: 14px;
  height: 21px;
  &.down {
    background: url("../images/green_arrow_down.svg") center center no-repeat;
  }
  &.up {
    background: url("../images/green_arrow_up.svg") center center no-repeat;
  }
  &.right {
    background: url("../images/green_arrow_right.svg") center center no-repeat;
  }
}
.green-arrow-down {
  width: 14px;
  height: 21px;
}

.green-arrow-up {
  width: 14px;
  height: 21px;
}

.action-link {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline !important;
  color: $green-dark !important;
  &.green {
    color: $green-dark !important;
  }
  &.purple {
    color: $title-primary !important;
  }
  &.small-link {
    font-size: 12px;
    line-height: 20px;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  &.lft-arrow::before {
    background: url("../images/dark-green-left-arrow.svg") center center no-repeat !important;
  }
}

.Select__value-container {
  height: 35px;
}

.chevron-right {
  width: 30px;
  height: 48px;
  background: url("../images/chevron_right.png") center center no-repeat;
  transform-style: preserve-3d;
  transform: scale(0.2);
}

.nav-tabs-container {
  margin-top: 2rem;
  max-width: 1100px;
  display: flex;
  border-top: 1px solid $lightestGray;
  .nav-tabs {
    min-width: 398px;
    width: max-content;
    border-bottom: 0;
    .tab > div {
      padding: 0px 22px 0px 5px;
    }
    .active.show > div {
      background-color: $light-blue;
    }
  }
  .tab-content {
    width: 100%;
    .field-wrapper {
      padding: 24px 28px 40px 28px;
      border-bottom: 1px solid $lightestGray;
    }
    &::before {
      content: "";
      background-color: $lightestGray;
      position: absolute;
      width: 1px;
      height: 100%;
      display: block;
    }
  }
}

.modal-container {
  padding: 8px 16px;
}

@media screen and (max-width: 768px) {
  .h3-header {
    font-size: 24px;
    line-height: 32px;
  }
}
