$primary-color: #007a4c;
$primary-hover: #82ceb1;
$primary-dark: #55ad8c;

$white: #ffffff;
$blue: #283593;
$blue-dark: #3747ab;
$blue-lightest: #edf0fd;

$text-primary: #323232;
$text-secondary: #535353;
$text-ternary: #818181;

$para-primary: #757575;
$para-secondary: #949494;

$title-primary: #412894;
$title-secondary: #7b87cc;
$title-secondary-lighter: #b9c1ec;
$title-secondary-lightest: #f6f7fc;
$title-primary-bright: #9039ed;

$label-primary: #5862a3;
$checkbox-radio: #3747ab;
$checkbox-radio-light: #3747ab;
$checkbox-radio-bright: #6372d1;

$well-bg: rgba(123, 135, 204, 0.07);
$banner-bg: #ebecf5;
$banner-light-bg: #f2f4ff;

$link: $primary-color;
$link-hover: #7fcfb1;
$link-pressed: #55ad8c;

$confirmedShift: #495187;
$expiredShift: #c35f7b;
$requestedShift: #fbefa0;
$postedShift: #ebedf9;

$vet-color: #b9c1ec;
$tech-color: #bfe3ff;
$accent-color: #e8eaf7;

$lightestRed: #d6a0af;
$lightestYellow: #f9eec0;
$dark-gray: #303031;
$darkestGray: #262626;
$lightestGray: #d6d6d6;
$gray-light-300: #838383;
$gray-light-400: #59595a;
$gray-200: #acacad;

$coral-base: #ff7676;
$coral-light: #ff5454;
$coral-lightest: #ffbbbb;
$coral-100: #ffdddd;

$teal-background: #e9f8f8;
$teal-lightest: #d2eff0;
$teal-lighter: #a5dfe0;
$teal-light300: #79cfd1;
$teal-light400: #4cbfc1;
$teal-base: #1fafb2;
$teal-dark600: #178386;
$teal-dark700: #105859;
$teal-darker: #082c2d;
$teal-darkest: #031212;

$blue-200: #b6bff4;
$blue-300: #929fef;
$dark-blue: #3747ab;
$light-blue: #dbdffa;
$lightest-blue: #edf0fd;

$purple: #edeaf5;
$purple-100: #d9d4ea;
$purple-200: #b3a9d4;
$purple-300: #8d7ebf;
$purple-500: #412894;
$purple-600: #311e6f;
$purple-700: #21144a;
$purple-darkest: #21144a;

$yellow-alert: #fdf9e9;
$lightest-yellow: #faf3d2;
$yellow-200: #f5e7a4;
$yellow-500: #e7c21c;

$green-dark: #007a4c;
$green-base: #339570;
$green-300: #66af94;
$green-600: #005c39;

$dark-yellow: #74610e;

$red-alert: #fde8e8;
$red-base: #e81616;
$red-400: #ed4545;
$red-dark-600: #ae1111;

.background-none {
  background: none !important;
}
.link-disabled {
  opacity: 0.4;
}
// @imagePath: ~"../../assets/images";

// Font includeed
// @import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700");
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");

.primaryFont {
  font-family: "Poppins", sans-serif;
}
.secondaryFont {
  font-family: "Open Sans", sans-serif;
}
.tertiaryFont {
  font-family: "Jost", sans-serif;
}
.hr {
  height: 1px;
  border: 0;
  border-bottom: 2px solid $title-secondary-lighter;
}
.hr-grey {
  border-bottom: 1px solid $gray-200;
}

@mixin transition {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

@mixin d-block {
  display: block;
}

@mixin d-inline-block {
  display: inline-block;
}

@mixin d-none {
  display: none;
}

@mixin highlighted-text {
  color: $primary-color;
  font-weight: 600;
  font-size: 18px;
}

.primary-title {
  font-size: 42px;
  line-height: 45px;
  margin-bottom: 15px;
  color: $title-primary;
}

.clear-filter {
  font: 15px Poppins, sans-serif;
  color: $green-dark;
  cursor: pointer;
  padding-right: 10px;
}

.primary-title-v2 {
  @extend .primary-title;
  font-size: 36px;
  line-height: 52px;
  font-weight: 600;
}

.secondary-title {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 15px;
}

.ternary-title {
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 29px;
  font-weight: 200;
}

.secondary-text-color {
  color: $text-secondary;
}

.dark-gray-color {
  color: $text-primary;
}

.lightest-grey {
  color: $lightestGray;
}

.text-red {
  color: $red-400 !important;
}

.text-gray-light-300 {
  color: $gray-light-300;
}

.secondary-text {
  @extend .secondaryFont;
  color: $text-secondary;
  font-weight: 600;
  line-height: 27px;
  font-size: 20px;
  &.sm {
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
  }
  &.sign-now-message {
    font-size: 20px;
    line-height: 22px;
    font-weight: normal;
    strong {
      color: #55ad8c;
    }
  }
}
.para-primary {
  color: $para-primary;
}
.ternary-text {
  color: $para-secondary;
}
.text-blue {
  color: $title-primary !important;
}
.text-green {
  color: $primary-color !important;
}
.text-green-dark {
  color: $green-dark !important;
}
.text-purple {
  color: $title-primary !important;
}
.text-grey {
  color: $text-ternary;
}
.text-grey-dark-600 {
  color: #242425;
}
.text-label {
  color: $label-primary;
}
.text-grey-light {
  color: #949494;
}
.text-bold {
  font-weight: bold;
}
.text-red-dim {
  color: #cf738d !important;
}
.text-blue-highlight {
  color: #785fcd !important;
  // $title-primary-bright; // $checkbox-radio-bright;
}
.title-secondary-color {
  color: $title-secondary !important;
}
.gray-base {
  color: #59595a;
}
.dark-yellow {
  color: $dark-yellow;
}
.dark-gray {
  color: $dark-gray;
}
.font-w-300 {
  font-weight: 300 !important;
}
.font-w-400 {
  font-weight: 400 !important;
}
.font-w-500 {
  font-weight: 500 !important;
}
.font-w-600 {
  font-weight: 600 !important;
}
.font-w-700 {
  font-weight: 700 !important;
}

.font-s-12 {
  font-size: 12px !important;
}

.font-s-14 {
  font-size: 14px !important;
}

.font-s-15 {
  font-size: 15px !important;
}

.font-s-16 {
  font-size: 16px !important;
}

.font-s-18 {
  font-size: 18px !important;
}

.font-s-20 {
  font-size: 20px !important;
}

.font-s-24 {
  font-size: 24px !important;
}

.font-s-28 {
  font-size: 28px !important;
}

strong.md-strong {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #404040;
}
.transition {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.well {
  box-shadow: 0 2px 15px 0 rgba(65, 40, 148, 0.22);
  background: $white;
  padding: 40px;
}
.container-pt-md {
  padding: 120px 0 !important;
}
.confirmed {
  background: $confirmedShift;
}
.completed {
  background: $gray-200;
}
.expired {
  background: $expiredShift;
}
.expired-text-color {
  color: $red-dark-600;
}
.requested {
  background: $requestedShift;
}
.posted {
  background: $postedShift;
}
.border-b {
  border-bottom: 2px solid #dddddd;
}
.scrolling-container {
  overflow-y: auto !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
iframe {
  border: 0;
  box-shadow: none;
  outline: none;
}
.green-text {
  color: $primary-dark !important;
}
@media (max-width: 767px) {
  .pos-sm-static {
    position: static !important;
  }
}
.title-secondary {
  color: $title-secondary !important;
}

.additional-pay {
  @media (max-width: 767px) {
    display: none;
  }
}

.font-w-normal {
  font-weight: normal !important;
}

.expired-request {
  color: #808080;
}

.vet-all-done {
  flex: none !important;
}

.banner-text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #757575;
}

.internship-background {
  background-color: $lightest-blue;
  height: 41px;
  width: 100%;
}

.internship-card-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  color: $dark-blue;
}

.subhead-title-marketing {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 32px;
  color: #303031 !important;

  @media (max-width: 426px) {
    font-size: 20px;
  }
}

.ternary-title-marketing {
  font-size: 36px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #303031;

  @media (max-width: 450px) {
    font-size: 28px;
  }
}

.tertiary-title-marketing {
  font-size: 28px;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  color: #050505;

  @media (max-width: 450px) {
    font-size: 24px;
  }
}

.secondary-title-marketing {
  font-size: 48px;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  color: #412894;

  @media (max-width: 450px) {
    font-size: 36px;
  }
}

.primary-title-marketing {
  font-size: 56px;
  color: #412894;
  font-weight: 700;

  @media (max-width: 450px) {
    font-size: 44px;
  }
}

// .links-marketing {
//   font-size: 20px !important;
//   font-weight: 600 bold !important;
//   color: #005C39 !important;
//   font-family: "Poppins", sans-serif !important;
// }

.body-marketing {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #242425;

  @media (max-width: 450px) {
    font-size: 14px;
  }
}

.marketing-primary-font {
  font-family: "Poppins", sans-serif !important;
}

.marketing-secondary-font {
  font-family: "Jost", sans-serif;
}

.blue-lightest-bg {
  background-color: $blue-lightest;
}

.purple-bg {
  background-color: $purple;
}

.green-bg {
  background-color: #e6f2ee;
}

.product-label-font {
  font-family: "Jost", sans-serif !important;
  color: #303031 !important;
  font-size: 20px;
  line-height: 28.9px;

  @media (max-width: 426px) {
    font-size: 16px;
  }
}

.marketing-label-font {
  font-size: 20px;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  color: #007a4c;
  margin-bottom: 7px;

  @media (max-width: 426px) {
    font-size: 16px;
  }
}
