#hospital-notification-settings {
  .accordion__sub-category-switch-container {
    display: flex;
    align-items: center;

    // span {
    //   font-family: Poppins;
    //   font-size: 12px;
    //   font-weight: 400;
    //   line-height: 18px;
    //   letter-spacing: 0px;
    //   text-align: left;
    //   margin-right: 0.5rem;
    // }
  }

  .accordion__item-toggle-container + p {
    margin-top: 0.5rem;
  }

  .is-primary-contact-container {
    text-align: left;
    // padding: 1rem 0.5rem;
    // margin-bottom: 2rem;
    h2 {
      color: #323232;
    }
  }

  .save-button {
    height: 45px;
    // background: #412894;
    box-shadow: 0px 2px 4px #d6d6d6;
    border-radius: 24px;
    padding: 8px 32px;
    gap: 8px;
    // font-family: "Jost";
    // font-style: normal;
    // font-weight: 500;
    // font-size: 20px;
    line-height: 29px;
    text-transform: capitalize;
    // color: #ffffff;
    border: 0;
  }

  .cancel-button {
    height: 45px;
    padding: 2px 32px;
    gap: 8px;
    // font-family: "Jost";
    // font-style: normal;
    // font-weight: 500;
    // font-size: 20px;
    line-height: 29px;
    // background: #ffffff;
    // border: 2px solid #412894;
    box-shadow: 0px 2px 4px #d6d6d6;
    border-radius: 24px;
    // color: #412894;
  }

  .react-switch-bg {
    border: 1px solid #dbdffa;
  }

  .save-and-cancel-buttons-container {
    max-width: 1100px;
    margin-top: 1.66rem;

    &.desktop {
      display: none;
    }

    display: flex;
    flex-direction: column-reverse;

    .save-button,
    .cancel-button {
      width: 100%;

      &:disabled {
        opacity: 0.5;
        // cursor: not-allowed;
      }
    }

    .save-button {
      margin-bottom: 0.75rem;
    }
  }

  [data-nav-tabs-desktop*="true"] {
    display: none;
  }

  [data-accordion-mobile*="true"] {
    margin-top: 2rem;
  }

  // color: $title-primary;

  .accordion__notification-settings-container {
    max-width: 700px;
  }

  .accordion__notification-settings {
    border-bottom: 1px;
  }

  .accordion__item-toggle-title {
    // font-family: "Poppins"; // TODO: install Poppins
    // font-style: normal;
    // font-weight: 500;
    // font-size: 12px;
    // line-height: 18px;
  }

  h1 {
    // font-size: 2rem;
    margin-bottom: 2rem;
    color: $title-primary;
  }

  h2 {
    // font-family: "Jost"; // TODO: install Jost
    // font-style: normal;
    // font-weight: 500;
    // font-size: 24px;
    // line-height: 35px;
  }

  h4 {
    // font-family: "Jost";
    // font-style: normal;
    // font-weight: 400;
    // font-size: 18px;
    // line-height: 26px;
  }

  .background-blue-lightest {
    // background-color: $blue-lightest;
  }

  // accordion
  .accordion {
    .accordion__item {
      .accordion__heading {
        .accordion__button {
          padding: 10px 0;
          background: none;
          // color: $blue-dark;
          &:after {
            content: "";
            right: 0;
            top: 6px;
            width: 48px;
            height: 30px;
            background: url("../images/chevron_down.png") center center no-repeat;
            transform: scale(0.3);
          }
          &[aria-expanded="true"] {
            &:after {
              transform: scale(0.3) rotateX(-180deg);
            }
          }
        }
      }

      // .accordion__button {
      //   user-select: none;
      //   position: relative;
      //   cursor: pointer;
      //   background-color: rgba(246, 247, 252, 0.8);
      //   padding: 10px;
      //   border: 0;
      //   color: #7b87cc;
      //   font-size: 14px;
      //   line-height: 19px;
      //   text-transform: uppercase;
      //   &:focus {
      //     outline: none;
      //   }
      //   &:after {
      //     content: "";
      //     width: 10px;
      //     height: 10px;
      //     background: url("../images/expand_purple.png") center center no-repeat;
      //     position: absolute;
      //     right: 15px;
      //     top: 0;
      //     bottom: 0;
      //     margin: auto;
      //     @extend .transition;
      //     transform-style: preserve-3d;
      //   }
      //   &[aria-expanded="true"] {
      //     font-weight: 600;
      //     &:after {
      //       transform: rotateX(-180deg);
      //     }
      //   }
    }
  }

  .accordion__item-toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 10px 0;

    .accordion__item-switch {
      transform: scale(0.8);
    }
  }

  .accordion__item-toggle-container + .accordion__item-toggle-container {
    margin-top: 1rem;
  }

  // react-toggle, no longer used, using react-switch
  .react-toggle-track {
    background-color: #fff;
  }

  .react-toggle {
    .react-toggle-thumb {
      background-color: $blue-dark;
    }

    &.react-toggle--checked {
      .react-toggle-thumb {
        border-color: unset;
        background-color: #fff;
      }
      .react-toggle-track {
        background-color: $blue-dark;
      }
    }

    &.react-toggle--focus {
      .react-toggle-thumb {
        box-shadow: unset;
      }
    }
  }

  .save-and-cancel-buttons-container {
    .button-container {
      button + button {
        margin-top: 1rem;
      }
    }
  }

  // desktop
  @media (min-width: 900px) {
    .container {
      max-width: 1100px;
    }

    .save-and-cancel-buttons-container {
      // max-width: 290px;
      justify-content: flex-end;
      flex-direction: row;

      &.mobile {
        display: none;
      }
      &.desktop {
        display: flex;
      }

      .button-container {
        button {
          min-width: initial;
        }

        display: flex;
        button + button {
          margin-left: 1rem;
          margin-top: 0;
        }

        .cancel-button {
          width: 125px;
        }

        .save-button {
          width: 106px;
        }
      }
    }

    .nav-tabs-desktop {
      margin-top: 44px;
      border-top: 1px solid #d6d6d6;
      max-width: 1100px;
      display: flex;

      .nav-tabs {
        border-bottom: 0;
      }
    }

    .nav-tabs {
      min-width: 33%;

      a.active.show > div {
        background-color: #e9ecef;
        // background-color: #dbdffa;
        margin-right: 0.25rem;
      }
    }

    .tab-pane > div {
      margin-left: 2rem;
    }

    .tab-content {
      border-left: 1px solid #d6d6d6;
      padding-top: 24px;
      padding-left: 0;
      width: 100%;
      .tab-content-title {
        padding-left: 1.5rem;
        margin-bottom: -5px;
      }
    }

    .accordion__item-toggle-container + .accordion__item-toggle-container {
      margin-top: 0.75rem;
    }
  }
}
