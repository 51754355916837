.internship-grid-view {
  .header {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 63px;
    color: $title-primary;
  }
  .secondary-header {
    color: $para-secondary;
    font-size: 30px;
    line-height: 45px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
  }
  .internship-title {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 45px;
    color: #323232;
    &.expired-internship {
      font-size: 18px;
      line-height: 27px;
      color: $para-secondary;
    }
  }

  .internship-grid-legends {
    overflow: hidden;
    .key-text {
      float: left;
      padding: 0px 22px 10px 9px;
      position: relative;
      color: #949494;
      @extend .secondaryFont;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 17px;
      background: transparent;
    }
    span {
      float: left;
      padding: 10px 20px 10px 24px;
      position: relative;
      color: #949494;
      @extend .secondaryFont;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 17px;
      background: transparent;
      &:before {
        content: "";
        height: 12px;
        width: 12px;
        border-radius: 12px; // ***
        position: absolute;
        top: -1px;
        left: 0;
        bottom: 0;
        margin: auto;
        background: #e6e9f7;
      }
      &.confirmed {
        &:before {
          background: $confirmedShift;
        }
      }
      &.requested {
        &:before {
          background: $requestedShift;
        }
      }
      &.posted {
        &:before {
          background: $postedShift;
        }
      }
    }
    .circle {
      width: 0;
      height: 0;
      background: transparent;
      border-left: 16px solid transparent;
      border-top: 16px solid transparent;
      border-radius: 50%;
      &.posted {
        border-color: $postedShift;
      }
      &.requested {
        border-color: $requestedShift;
      }
      &.confirmed {
        border-color: $primary-color;
      }
    }
  }
}

.internship-card {
  box-shadow: 0 2px 8px 0 rgba(65, 40, 148, 0.09);
  background: #fff;
  padding-bottom: 120px;
  width: 100%;
  // padding-top: 65px;
  overflow: hidden;
  height: 100%;
  &.no-bottom-padding {
    padding-bottom: 70px;
  }
  .internship-card-header {
    padding-left: 8px;
    min-height: 67px;
    background: #fff;
    border-bottom: #e8eaf7 solid 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .internship-card-header-title {
      font-size: 20px;
      line-height: 29px;
      color: $confirmedShift;
      width: 175px;
    }
    .internship-card-header-tab {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      width: 52px;
      height: 18px;
      background: $light-blue;
      color: #303031;
      padding: 0px 8px;
      margin-right: 8px;
      text-align: center;
    }
    &.green-bar {
      border-bottom: $primary-color solid 8px;
    }
    &.yellow-bar {
      border-bottom: $requestedShift solid 8px;
    }
    &.blue-thick-bar {
      border-bottom: $title-primary solid 8px;
    }
    &.coral-bar {
      border-bottom: $coral-base solid 8px;
    }
    span {
      display: block;
    }
  }
  .internship-card-body {
    padding: 12px 12px 0;
    .top-row {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      .img-wrap {
        width: 72px;
        height: 72px;
        border-radius: 100px;
        overflow: hidden;
        box-shadow: 0 2px 8px 0 rgba(65, 40, 148, 0.09);
        float: left;
        img {
          width: 72px;
          height: 72px;
          border-radius: 100px;
        }
      }
      .name {
        width: calc(100% - 72px);
        float: left;
        padding-left: 16px;
        h6 {
          @extend .secondaryFont;
          color: $text-secondary;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          margin-bottom: 4px;
        }
        a {
          font-weight: 500;
          letter-spacing: 1px;
          display: inline-block;
        }
      }
      .empty-card-img {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f6f7fc;
        img {
          width: 40px !important;
          height: 40px !important;
        }
      }
    }
    p {
      @extend .secondaryFont;
      color: $text-secondary;
      line-height: 22px;
      margin-bottom: 14px;
      span {
        font-weight: 600;
      }
    }
  }
  .internship-card-footer {
    padding: 15px 12px 0;
    min-height: 70px;
    max-height: 120px;
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
    display: block;
    text-align: center;
    span {
      display: block;
      &.danger {
        color: #ff4747;
        text-align: center;
        font-size: 14px;
      }
    }
    .btn {
      min-width: inherit;
      margin-top: 8px;
    }
    .message {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: #404040;
      margin: 0;
      span.countFixWidth {
        color: #418b6f;
      }
    }
    .footer-text {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      &.decline-link {
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        color: #007a4c;
      }
    }
    .booked-text {
      color: #412894;
    }
  }
}

.empty-card {
  padding-bottom: 10px !important;
}
// ---- mobile view
// 767, 576
@media all and (max-width: 767px) {
  .internship-card {
    box-shadow: 0 2px 8px 0 rgba(65, 40, 148, 0.09);
    padding-bottom: 120px;
    width: 100%;
    padding-top: 65px;
    overflow: hidden;
    .internship-card-header {
      font-size: 18px;
      line-height: 29px;
      color: $title-primary;
      padding: 12px;
      min-height: 67px;
      position: absolute;
      left: 15px;
      right: 15px;
      top: 0;
      background: #fff;
    }
    .internship-card-body {
      padding: 12px 12px 0;
    }
    .internship-card-footer {
      padding: 15px 12px 0;
    }
  }
}
