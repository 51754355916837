/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.u-position-absolute {
  position: absolute;
}

.u-position-relative {
  position: relative;
}

.accordion {
}

.accordion__heading {
  cursor: pointer;
}

.accordion__item + .accordion__item {
}

.accordion__item--has-icon {
  position: relative;
}

.accordion__button:hover {
}

.accordion__panel {
  overflow-y: auto;
  /* max-height: 620px; approximate max height */
  transition: all 800ms ease;
}

.accordion__panel.accordion__panel--hidden {
  max-height: 0;
  transition: all 800ms ease;
  /* animation: fadein 0.35s ease-in;*/
}

.accordion__button > *:last-child,
.accordion__panel > *:last-child {
  margin-bottom: 0;
}

.accordion-box {
  cursor: pointer;
  background: #ffffff;
  border-radius: 15px;
  margin-bottom: 32px;
  padding: 30px 25px;
  font-size: 14px;
}

.shadow-box {
  box-shadow: 0px 2px 4px rgba(86, 86, 86, 0.3);
}

.nested-accordion {
  cursor: pointer;
  border-top: 1px solid #e8eaf7;
  padding: 30px 0px;
  font-size: 14px;
}
/* 
.accordion__arrow {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
}

.accordion__arrow::after,
.accordion__arrow::before {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: currentColor;
    content: '';
}

.accordion__arrow::before {
    left: 4px;
    transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before, [aria-selected='true'] .accordion__arrow::before {
    transform: rotate(-45deg);
}

.accordion__arrow::after {
    right: 4px;
    transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after, [aria-selected='true'] .accordion__arrow::after {
    transform: rotate(45deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
} */

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes move-down {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(5px);
  }
  30% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes move-up {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-5px);
  }
  30% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.accordion__button--animated:hover .accordion__arrow {
  animation-name: move-down;
  animation-duration: 1.5s;
}

.accordion__button--animated[aria-expanded="true"]:hover .accordion__arrow {
  animation-name: move-up;
  animation-duration: 1.5s;
}
