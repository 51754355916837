#root,
body {
  // intentionally added it for react-big-calender:0.34.0(latest version)
  .calendar-legends-wrapper {
    position: relative;
    a {
      position: absolute;
      bottom: -20px;
      right: 5px;
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .rbc-overlay {
    .cal-event {
      &.vet-shift {
        background: $vet-color;
        &.expired {
          opacity: 0.5;
        }
      }
      &.vet-shift-expired {
        // background: #ffdbdb; // #ece4f7;
      }
      &.tech-shift {
        background: $tech-color;
      }
      .cal-shift {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        padding-left: 5px;
        color: $text-secondary;
        .requested-title-text,
        .confirmed-title-text {
          font-weight: 700;
        }
        .confirmed-title-text {
          color: $confirmedShift;
        }
        // ----
        @at-root .triangle-corner {
          width: 0;
          height: 0;
          background: transparent;
          border-left: 16px solid transparent;
          &.posted {
            border-top: 16px solid $postedShift;
          }
          &.requested {
            border-top: 16px solid $requestedShift;
          }
          &.confirmed {
            border-top: 16px solid $confirmedShift;
          }
          // expired request
          &.expired {
            border-top: 16px solid $expiredShift;
          }
          // post expires soon
          &.expires-soon {
            border-top: 16px solid $postedShift; // #1db569; // slight alteration from primary-color
          }
        }
        // ----
      }
      .event-name {
        display: flex;
        justify-content: space-between;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        font-weight: normal;
        font-size: 13px;
        padding: 0 5px;
        @extend .secondaryFont;
        &.requested {
          color: #535353;
          font-weight: 600;
          position: relative;
          padding-right: 18px;
          &.title {
            &:after {
              content: "";
              width: 16px;
              height: 16px;
              position: absolute;
              top: 2px;
              right: 2px;
              background: url("../images/pending_clock.svg") center center no-repeat;
            }
          }
        }
        &.posted {
          color: #535353;
          font-weight: 600;
        }
      }
    }
  }
  .rbc-event {
    border-radius: 0;
    background: transparent;
    font-size: 13px;
    color: #fff;
    padding: 0;
    margin: 0 0 1px;
    float: left;
    width: unset;
    @extend .secondaryFont;
    &:focus {
      outline: none;
    }
  }

  .weekly-view-non-current-week {
    z-index: 5;
    background-color: $white;
  }

  // .timer-icon {
  //   content: "";
  //   width: 16px;
  //   height: 16px;
  //   position: relative;
  //   top: -8px;
  //   margin-right: 10px;
  //   background: url("../images/pending_clock.svg") center center no-repeat;
  // }

  .timer-icon-request {
    width: 100%;
    height: 100%;
    color: red;
    background: url("../images/pending_clock_red.svg") center center no-repeat;
  }

  .event-tooltip {
    box-shadow: 0 2px 15px 0 rgba(65, 40, 148, 0.2);
    background: #fff;
    border-radius: 5px;
    color: #333;
    padding: 6px;
    position: fixed;
    overflow: hidden;
    z-index: 9999;
    span {
      display: block;
      padding: 0;
      font-size: 14px;
      font-weight: 550;
      @extend .secondaryFont;
    }
    &.tooltip-width {
      min-width: 253px;
    }
    @media all and (max-width: 767px) {
      display: none !important;
    }
  }
  .vet-shift-expired {
    // background: #ffdbdb; // #ece4f7;
  }
  .posting-details-head-renew-mulitple {
    min-height: 65px;
    padding: 14px;
    border-bottom: 14px solid $postedShift;
    color: $title-primary;
    @extend .primaryFont;
    font-size: 18px;
    line-height: 26px;
    position: relative;
  }
  .calendar-container {
    // padding: 90px 0;
    .calendar-wrapper.vet-calendar-wrap,
    .calendar-wrapper.tech-calendar-wrap {
      height: 750px !important;
    }
    .dashboard-columns {
      // display: flex;
      .col-left,
      .col-right .sidebar {
        box-shadow: 0 2px 15px 0 rgba(65, 40, 148, 0.09);
        border-radius: 5px;
        background: #fff;
      }
      .col-left {
        width: 100%;
        background: transparent;
        box-shadow: none;
      }
      .col-right {
        justify-content: flex-end;
        padding: 0 0 0 30px;
        // display: flex;
        .right-sidebar {
          width: 100%;
          padding: 0;
          // display: flex;
          // flex-direction: column;
          border: 0;
          // .accordion-container{
          // overflow-y: auto;
          // }
          .section-title {
            padding: 12px 12px 0;
          }
          ul {
            li .head {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .calendar-contractor-toggle-buttons {
    .btn-secondary:not(:disabled):not(.disabled).active,
    .btn-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-secondary.dropdown-toggle {
      background-color: $light-blue;
      color: $dark-gray;
      font-weight: 500;
    }

    .btn-secondary {
      font-weight: normal;
      min-width: 100px;
      background-color: $white;
      color: $gray-light-400;
      border: 1.33px solid $dark-blue;
      box-shadow: initial;
    }
  }

  .calendar-wrapper {
    position: relative;
    .add-shift-calendar-position {
      position: absolute;
      right: 14px;
      top: 14px;
    }
    .calendar-month-title {
      display: none;
    }
    .shifts-toggle-calendar {
      position: absolute;
      top: 18px;
      right: 0;
      span {
        cursor: pointer;
        font-size: 15px;
        line-height: 19px;
        text-align: right;
        padding: 0 15px;
        color: $text-secondary;
        @extend .secondaryFont;
        &:first-child {
          border-right: 2px solid #979797;
        }
        &.active {
          font-weight: 600;
          color: #785fcd;
        }
      }
    }
  }
  .calendar-wrap {
    position: relative;
    box-shadow: 0px 4px 20px rgba(65, 40, 148, 0.2);
    border-radius: 5px;
    background: #fff;
    height: 100%;
    min-height: 640px;
    //overflow: hidden;
    .custom-loader-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 999;
      background: rgba(255, 255, 255, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      .custom-loader {
        &:after {
          border: 5px solid #7b67bf;
          border-color: #7b67bf transparent;
        }
      }
    }
    .rbc-calendar {
      min-height: 640px;
      .rbc-toolbar {
        margin: 0;
        .rbc-btn-group:first-child {
          width: 100%;
          text-align: center;
          overflow: hidden;
          padding: 14px;
          min-height: 58px;
          & button:first-child {
            display: none;
          }
          & button:last-child,
          & button:nth-child(2) {
            background-color: transparent;
            border: 0;
            outline: none;
            width: 24px;
            height: 24px;
            overflow: hidden;
            text-indent: 999px;
            @extend .transition;
            cursor: pointer;
            padding: 0;
            // margin: 0 90px;
            // position: relative;
            position: absolute;
            top: 18px;
            &:hover {
              opacity: 0.8;
              @extend .transition;
            }
            &:active {
              box-shadow: none;
            }
          }
          & button:nth-child(2) {
            background: url("../images/arrow_right_dark_green.svg") center center no-repeat;
            background-size: 45%;
            left: 12px;
            transform: rotate(-180deg);
          }
          & button:last-child {
            background: url("../images/arrow_right_dark_green.svg") center center no-repeat;
            background-size: 45%;
            left: 204px;
          }
          & label {
            display: inline-block;
            margin: 0;
            color: #7b87cc;
            font-size: 18px;
            letter-spacing: 1.5px;
            line-height: 29px;
            text-align: center;
            @extend .primaryFont;
            padding: 0 10px;
            width: 95px;
          }
        }
        .rbc-toolbar-label {
          display: none;
          // position: absolute;
          // left: 150px;
          // color: #7B87CC;
          // font-size: 18px;
          // letter-spacing: 1.5px;
          // line-height: 29px;
          // text-align: center;
          // @extend .primaryFont;
          // padding: 0 10px;
          // font-weight: normal;
        }
        .rbc-btn-group:last-child {
          display: none;
        }
      }

      .rbc-month-view {
        border-top: 0;
      }

      .rbc-row.rbc-month-header {
        .rbc-header {
          padding: 2px;
          border-color: #ddd;
          span {
            font-family: "Jost", sans-serif;
            font-weight: 500;
            color: #242425;
            opacity: 0.5;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            // @extend .secondaryFont;
            // color: $para-primary;
            // font-weight: normal;
          }
        }

        .rbc-header + .rbc-header {
          border-left: 0;
          border-top: 0;
        }
      }

      .rbc-month-view {
        border-color: #ddd;
        border-left: 0;
        border-right: 0;
        .rbc-today {
          background: transparent;
          &.weekly-view-non-current-week {
            background: $white;
          }
        }
        .rbc-now {
          a {
            // background: #95A3ED;
            background: $text-secondary;
            // background: $blue;
            height: 24px;
            width: 24px;
            border-radius: 40px;
            color: #fff !important;
            font-weight: normal;
            display: inline-block;
            text-align: center;
            position: relative;
            top: 2px;
            right: -2px;
            line-height: 24px !important;
          }
        }
        .rbc-month-row {
          overflow: visible;
          &:hover {
            overflow: visible;
          }
        }
        .rbc-header {
          border-bottom: 1px solid #ebebeb;
        }
        .rbc-day-bg + .rbc-day-bg {
          border-left: 1.5px solid #ebebeb;
        }
        .rbc-month-row + .rbc-month-row {
          border-top: 1.5px solid #ebebeb;
        }
        .rbc-month-row,
        .rbc-overlay {
          .cal-event {
            box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
            border-radius: 2px;

            &.vet-shift {
              // background: $vet-color;
              background: $white;
              border-left: 4px solid $purple-500;
              &.expired {
                opacity: 0.5;
              }
            }
            &.vet-shift-expired {
              // background: #ffdbdb; // #ece4f7;
            }
            &.tech-shift {
              // background: $tech-color;
              background: $white;
              border-left: 4px solid $blue-200;
            }
            .cal-shift {
              font-weight: 500;
              font-family: "Jost", sans-serif;
              font-size: 15px;
              line-height: 24px;
              padding-left: 5px;
              color: $text-secondary;
              .confirmed-title-text {
                // color: $confirmedShift;
              }
              // ----
              @at-root .triangle-corner {
                width: 0;
                height: 0;
                background: transparent;
                border-left: 16px solid transparent;
                &.posted {
                  border-top: 16px solid $postedShift;
                }
                &.requested {
                  border-top: 16px solid $requestedShift;
                }
                &.confirmed {
                  border-top: 16px solid $confirmedShift;
                }
                // expired request
                &.expired {
                  border-top: 16px solid $expiredShift;
                }
                // post expires soon
                &.expires-soon {
                  border-top: 16px solid $postedShift; // #1db569; // slight alteration from primary-color
                }
              }
              // ----
            }
            .event-name {
              display: flex;
              justify-content: space-between;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // white-space: nowrap;
              font-weight: normal;
              font-size: 13px;
              padding: 0 5px;
              @extend .secondaryFont;
              &.requested {
                color: #535353;
                font-weight: 600;
                position: relative;
                padding-right: 18px;
                &.title {
                  &:after {
                    content: "";
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    top: 2px;
                    right: 2px;
                    background: url("../images/pending_clock.svg") center center no-repeat;
                  }
                }
              }
              &.posted {
                color: #535353;
                font-weight: 600;
              }
            }
          }
        }
      }
      .rbc-row-content {
        pointer-events: none;

        .rbc-date-cell {
          a {
            @extend .secondaryFont;
            color: $text-secondary;
            font-size: 16px;
            line-height: 22px;
            &:hover {
              &:after {
                display: none;
              }
            }
          }
          // &.rbc-current.rbc-now {
          &.rbc-now {
            font-weight: normal;
            span {
              background: $purple-500;
              border-radius: 50%;
              color: #fff;
              display: inline-block;
              padding: 0px 3px;
              font-size: 14px;
            }
          }
        }
      }
      .rbc-ellipsis,
      .rbc-event-label,
      .rbc-row-segment .rbc-event-content,
      .rbc-show-more {
        overflow: visible;
        white-space: normal;
        font-size: 12px;
        font-weight: bold;
        @extend .secondaryFont;
      }
      .rbc-row {
        align-items: stretch;
      }
      .rbc-row-segment {
        align-self: stretch;
        border-radius: 0.5rem;
        .rbc-event {
          height: 100%;
          width: 100%;
          .rbc-event-content {
            height: 100%;
            .cal-event {
              // height: 100%;
              padding-bottom: 0.1rem;
            }
          }
        }
      }
      .rbc-show-more {
        padding-top: 4px;
        &:hover {
          &:after {
            display: none;
          }
        }
      }
      .rbc-event {
        border-radius: 0;
        background: transparent;
        font-size: 13px;
        color: #fff;
        padding: 0;
        margin: 0 0 1px;
        float: left;
        &:focus {
          outline: none;
        }
      }
      .rbc-off-range-bg {
        background: transparent;
        &.weekly-view-non-current-week {
          background: $white;
        }
      }
      .rbc-date-cell.rbc-off-range a {
        color: #bebebe;
      }
      .shift-hide {
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
    }

    .weekly-calendar-view {
      .rbc-calendar {
        .rbc-month-row + .rbc-month-row {
          border-top: 0;
        }
      }
    }
    .cal-modal-wrap {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      background: rgba(255, 255, 255, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      .cal-modal-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
      }
      .cal-modal {
        width: 400px;
        background: $white;
        box-shadow: 0 2px 24px 0 rgba(35, 24, 71, 0.3);
        margin: 20px;
        z-index: 10;
        .cal-modal-head {
          min-height: 65px;
          padding: 14px;
          border-bottom: 14px solid #fff;
          color: $title-primary;
          @extend .primaryFont;
          font-size: 18px;
          line-height: 26px;
          position: relative;
          &.confirmed-border {
            border-color: $confirmedShift;
          }
          &.expired-border {
            border-color: $expiredShift;
          }
          &.posted-border {
            border-color: $postedShift;
          }
          &.requested-border {
            border-color: $requestedShift;
          }
          &.no-border {
            border: 0;
            height: auto;
            padding-bottom: 0;
          }
          .close-modal {
            width: 18px;
            height: 18px;
            position: absolute;
            right: 16px;
            top: 16px;
            background: url("../images/close.svg") center center no-repeat;
            cursor: pointer;
            &:hover {
              &::after {
                display: none;
              }
            }
          }
        }
        .cal-modal-body {
          h4 {
            color: $text-secondary;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 26px;
            padding: 8px 0 0 14px;
          }
          .link-group {
            padding: 0 10px;
            a {
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 1px;
              line-height: 26px;
              &.remove {
                color: #c35f7b;
                &:hover {
                  &:after {
                    background: #c35f7b;
                  }
                }
              }
            }
          }
          ul {
            margin: 0;
            padding: 0 10px;
            list-style: none;
            &.vet-list {
              li {
                //background: #495187;
                margin-bottom: 5px;
                overflow: hidden;
                padding: 8px;
                display: flex;
                cursor: pointer;
                label,
                span {
                  color: #ffffff;
                  @extend .secondaryFont;
                  font-size: 14px;
                  line-height: 19px;
                  margin: 0;
                  cursor: pointer;
                }
                label {
                  font-weight: 600;
                  flex: 2 0px;
                }
                span {
                  text-align: right;
                }
                &.requested {
                  label,
                  span {
                    color: #757575;
                  }
                }
              }
            }
          }
          .vet-details,
          .tech-details {
            padding: 10px;
            .vet-bio {
              display: flex;
              padding: 4px 0 10px;
              align-items: center;
              .photo-wrap {
                box-shadow: 0 0 0 1px #eaeaea;
                width: 60px;
                height: 60px;
                overflow: hidden;
                border-radius: 100px;
                background: #eaeaea;
                display: flex;
              }
              .bio-wrap {
                flex: 2 0px;
                padding: 0 20px;

                label {
                  @extend .secondaryFont;
                  color: $text-secondary;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 21px;
                  display: block;
                  margin: 0;
                }
                a {
                  @extend .primaryFont;
                  font-size: 16px;
                  font-weight: 500;
                  letter-spacing: 1px;
                  line-height: 26px;
                  cursor: pointer;
                }
              }
            }
            p {
              @extend .secondaryFont;
              font-size: 14px;
              line-height: 19px;
              color: $text-secondary;
              margin-bottom: 12px;
              strong {
                font-weight: 600;
              }
              &.shift-charges {
                color: #404040;
                @extend .primaryFont;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                text-align: center;
                padding: 28px 0 0;
                strong {
                  color: #418b6f;
                  font-weight: 500;
                }
              }
            }
            .btn {
              // padding: 2px 30px;
              min-width: 190px;
              text-transform: uppercase;
              font-weight: bold;
            }
          }
          .remove-decline {
            color: red !important;
            font-size: 12px !important;
          }
          .modal-body {
            p {
              font-size: 26px;
              line-height: 40px;
              text-align: center;
              width: 100%;
            }
            .modal-p-wrap {
              p {
                text-align: left;
                line-height: normal;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .calendar-legends {
      position: absolute;
      bottom: -42px;
    }
  }
  .cal-notification {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    &.scroll-container {
      max-height: 220px;
      overflow-y: auto;
    }

    li,
    .accordion__button {
      position: relative;
      padding: 8px 205px 8px 60px;
      margin-bottom: 10px;
      background-size: 26px;

      color: $text-primary;
      line-height: 30px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      overflow: hidden;
      @extend .secondaryFont;

      a,
      span {
        color: $text-primary;
        font-weight: 600;
      }
      .counter {
        color: $text-primary;
        padding-left: 15px;
        font-weight: 600;
      }
      .profile-img {
        width: 26px;
        height: 26px;
        border: 1px solid #ccc;
        border-radius: 100px;
        overflow: hidden;
        margin-right: 10px;
        margin-top: 2px;
        display: flex;
        background-size: 100%;
        position: absolute;
      }
      .link {
        position: absolute;
        right: 16px;
        top: 12px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1.13px;
        line-height: 22px;
        min-width: auto;
        @extend .secondaryFont;
        text-transform: uppercase;
        text-decoration-line: underline;
        &.link-arrow {
          padding-right: 30px;
          &:before {
            content: "";
            width: 20px;
            height: 16px;
            position: absolute;
            top: 4px;
            right: 0;
            background: url("../images/small_down_arrow_green.svg") right center no-repeat;
            background-size: 100%;
            transform: rotateX(0);
          }
        }
        &:hover {
          &:after {
            display: none;
          }
        }
        &.in {
          &:before {
            transform: rotateX(-180deg);
          }
        }
      }
      .actions {
        position: absolute;
        right: 15px;
        top: 7px;
        text-transform: uppercase;
      }
      .pop-up-notification-date {
        display: inline-block;
      }
    }
    // .grouped-alerts {
    //   .actions {
    //     right: 90px;
    //   }
    // }
    .accordion {
      margin-bottom: 10px;
      overflow: visible;
      .accordion__button {
        margin: 0;
        box-shadow: none;
        &[aria-expanded="true"] {
          .link {
            &:before {
              transform: rotateX(-180deg);
            }
          }
        }
        span {
          font-weight: 600;
        }
      }
      .accordion__panel {
        max-height: 300px;
        overflow: hidden;
        &.accordion__panel--hidden {
          max-height: 0;
        }
        li {
          background-image: none;
          padding-left: 20px !important;
          margin-bottom: 0 !important;
          box-shadow: unset !important;
        }
        .alert-text {
          padding-left: 40px;
        }
      }
    }

    .single-alert li,
    .grouped-alerts {
      background: #fff url("../images/alert_black.svg") 20px 10px no-repeat;
      border-radius: 15px;
      box-shadow: 0px 2px 15px rgba(65, 40, 148, 0.1);
      &.red-alert {
        background: $lightestRed url("../images/alert_red.svg") 20px 10px no-repeat;
        .link {
          &.link-arrow:before {
            background: url("../images/down_arrow_black.svg") right center no-repeat;
          }
        }
      }
      &.yellow-alert {
        background: $lightestYellow url("../images/alert_yellow.svg") 20px 10px no-repeat;
        .link {
          &.link-arrow:before {
            background: url("../images/down_arrow_black.svg") right center no-repeat;
          }
        }
      }
    }
    .yellow-alert {
      background: $lightestYellow url("../images/alert_yellow.svg") 20px 10px no-repeat;
      .link {
        &.link-arrow:before {
          background: url("../images/down_arrow_black.svg") right center no-repeat;
        }
      }
    }

    .flex-content-wrapper {
      line-height: 20px !important;
      padding-right: 0 !important;
      .pop-up-notification-span {
        height: auto !important;
        display: inline-block;
        line-height: normal;
        font-size: 11px;
      }
    }
  }
}

.scroll-container-msg {
  max-height: 150px;
  overflow-y: auto;
}

.scroll-container-msg::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.scroll-container-msg::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scroll-container-msg::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.shift-pricing-info {
  .additional-pricing {
    p {
      display: flex;
      div {
        &:first-child {
          min-width: 55px;
        }
      }
    }
  }
  p {
    margin-bottom: 5px !important;
    div,
    span {
      &:first-child {
        color: $primary-color;
      }
      &:last-child {
        color: $title-primary;
      }
    }
  }
}

.mobile-calendar-floating-add-shift-button {
  background-color: $purple-500;
  height: 64px;
  width: 64px;
  position: fixed;
  bottom: 16px;
  left: 16px;
  box-shadow: rgb(23 73 77 / 15%) 0px 20px 30px;
  z-index: 99;
  border-radius: 50%;
}

.mobile-calendar-floating-add-shift-button-svg {
  filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(0%) hue-rotate(354deg)
    brightness(105%) contrast(106%);
  position: absolute;
  top: 30%;
  left: 30%;
  scale: 1.5;
}

.mobile-calendar-floating-shift-button-body {
  min-height: 35vh;
}

.filter-shift-cards-modal {
  .pretty.p-default input:checked ~ .state label:after {
    background-color: $blue-dark !important;
    top: 13%;
  }

  .pretty .state label {
    color: $dark-gray;
  }

  // .pretty .state label:before,
  // .pretty .state label:after,
  .pretty .state label:before {
    border: 1px solid $dark-blue;
    background-color: $white;
    top: 13%;
  }

  .pretty.p-default .state label:after {
    transform: scale(0.75);
  }

  .btn-secondary {
    background-color: $white;
    // color: $dark-gray;
  }

  .modal-body {
    background-color: $blue-lightest;
  }
}

.filter-status-circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;

  &.small {
    width: 8px;
    height: 8px;
  }

  &.right {
    left: initial;
    right: 5px;
  }

  &.unfilled {
    background-color: $blue-200;
  }
  &.requested {
    background-color: $yellow-500;
  }
  &.expired,
  &.expired-requests {
    background-color: $red-base;
  }
  &.confirmed {
    background-color: $green-dark;
  }
  &.completed {
    background-color: $gray-200;
  }
}

.mobile-cal-modal-clock-icon,
.mobile-cal-modal-calendar-icon {
  width: 28px;
  margin-right: 0.66rem;
}

.contractor-request-avatar {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  border: 1px solid $gray-200;
}

.contractor-avatar {
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.contractor-request-list-avatar {
  height: 70px;
  width: 70px;
}

.mobile-shift-card-contractor-avatar {
  height: 50px;
  width: 50px;
}

.mobile-cal-modal-instant-book-text {
  margin-left: 0.33rem;
  font-weight: 500;
  &.on {
    color: $purple-500;
  }
  &.off {
    // color: $dark-gray;
  }
}

.filter-shift-card-button {
  position: absolute;
  right: 8px;
  top: 0.2rem;
}

.contractor-request-avatar:not(:first-child) {
  margin-left: -60px;
  -webkit-mask: radial-gradient(circle 55px at 5px 205%, transparent 99%, #fff 100%);
  mask: radial-gradient(circle 55px at 5px 50%, transparent 99%, #fff 100%);
}

.contractor-request-avatar img {
  width: 100%;
  display: block;
}

.shift-status-circle {
  width: 8px;
  height: 8px;
  border-radius: 100%;

  &.unfilled {
    background-color: $blue-200;
  }
  &.requested {
    background-color: $yellow-500;
  }
  &.expired,
  &.expired-requests {
    background-color: $red-base;
  }
  &.confirmed {
    background-color: $green-dark;
  }
  &.completed {
    background-color: $gray-200;
  }
}

.mobile-cal-contractor-request-li {
  display: initial;

  .contractor-request-list-name {
    font-weight: 500;
  }
}

.mobile-cal-shift-amount {
  color: $gray-light-400;
  font-weight: 500;
}

.contractor-years-of-practice {
  color: $gray-light-300;
}

// @media all and (max-width: 900px) {
@media all and (max-width: 767px) {
  .desktop-calendar-add-shift-buttons {
    display: none !important;
  }

  .tablet-calendar-view-msg {
    display: none;
  }

  .scroll-container-msg {
    max-height: 185px;
  }

  #root {
    .link-dark-green {
      color: $green-dark;
      text-decoration: underline;
      font-weight: 600;
    }

    .cal-notification {
      .single-alert li,
      .grouped-alerts {
        background: #fff url("../images/alert_black.svg") 14px 25px no-repeat;
        border-radius: 15px;
        box-shadow: 0px 2px 15px rgba(65, 40, 148, 0.1);
        background-size: 16px 16px;
        &.red-alert {
          background: $red-alert url("../images/alert_red.svg") 14px 25px no-repeat;
          background-size: 16px 16px;
          .link {
            &.link-arrow:before {
              background: url("../images/down_arrow_black.svg") right center no-repeat;
            }
          }
        }
        &.yellow-alert {
          background: $yellow-alert url("../images/alert_dark_yellow.svg") 14px 25px no-repeat;
          background-size: 16px 16px;
          .link {
            &.link-arrow:before {
              background: url("../images/down_arrow_black.svg") right center no-repeat;
            }
          }
        }
      }

      .yellow-alert {
        background: $yellow-alert url("../images/alert_dark_yellow.svg") 14px 25px no-repeat;
        background-size: 16px 16px;
        .link {
          &.link-arrow:before {
            background: url("../images/down_arrow_black.svg") right center no-repeat;
          }
        }
      }

      li,
      .accordion__button {
        font-family: "Poppins", sans-serif;
        color: $dark-gray;
        position: relative;
        padding: 8px 25px 48px 45px;

        .link.link-arrow {
          color: $green-dark;
          position: absolute;
          left: 15px;
          top: 75px;
          text-transform: initial;
        }

        .link.link-arrow:before {
          background: none !important;
        }

        .counter {
          padding-left: initial;
        }

        .countFixWidth {
          color: $red-dark-600;
          display: inline-block;
        }
      }

      .accordion {
        .accordion__panel {
          li {
            padding-left: 60px !important;

            .profile-img {
              position: relative;
            }
          }

          .alert-text {
            padding-left: 0;
          }
        }
      }

      li .actions {
        // position: relative;
        top: initial;
        right: initial;
        margin-top: 0.6rem;
        width: 80%;

        .roo-button {
          width: 100%;
        }
      }

      .mobile-view-profile-link {
        color: $green-dark;
        text-decoration: underline;
        display: inline-block;
      }
    }

    .mobile-cal-modal-surgery-box {
      background-color: $lightest-yellow;
      padding: 3px 10px;
      border-radius: 0.5rem;
      display: inline;
    }

    .mobile-cal-modal-flexible-surgery-box {
      color: $dark-blue;
      border: 1.5px solid $dark-blue;
      padding: 2px 10px;
      border-radius: 0.5rem;
    }

    .mobile-cal-modal-surgery-box + .mobile-cal-modal-flexible-surgery-box,
    .mobile-cal-modal-surgery-none + .mobile-cal-modal-flexible-surgery-box {
      margin-left: 0.75rem;
    }

    .mobile-calendar-title {
      padding-left: 8px;
    }

    a.lft-arrow-green-dark {
      padding-left: 45px;
    }

    #calendar-container {
      padding-top: 5rem;
    }

    #mobile-cal-modal-wrap {
      position: fixed;
      // top: 7rem;
      top: initial;
      z-index: 99;

      .cal-modal {
        width: 100%;
        min-height: 70vh;
        margin: initial;
        border-radius: 1rem 1rem 0 0;
        // box-shadow: initial;

        &.requests-panel {
          min-height: 90vh;
          max-height: 90vh;

          .cal-modal-head {
            background-color: $white;
            color: inherit;
            border-radius: 0;
          }

          .cal-modal-head-contractor-label {
            background: 0;
            padding: 0;
            color: $green-dark;
          }

          .mobile-cal-modal-flexible-shift {
            color: $dark-blue;
            border: 1.5px solid $dark-blue;
          }

          .mobile-cal-modal-surgery-box {
            color: $dark-gray;
          }
        }
      }

      &.request-list-modal {
        .cal-modal-body {
          overflow-y: auto;
          height: 70vh;

          .countFixWidth {
            color: $red-dark-600;
          }
        }
      }

      .cal-modal-overlay {
        background-color: rgba(51, 51, 51, 0.4784313725);
        position: fixed;
      }

      .cal-modal-head {
        background-color: $purple-500;
        color: $white;
        border-radius: 1rem 1rem 0 0;
        border-color: $white;
        border-bottom: 0;

        .close-modal {
          top: 22px;
          transform: scale(1.3);
          background: url("../images/close_white.svg") no-repeat center center;

          &.close-dark-grey {
            background: url("../images/close_dark_grey.svg") no-repeat center center;
          }
        }
      }
      .cal-modal-head-tech {
        background-color: $light-blue;
        color: $darkestGray;
        // border: 2px solid $purple-500;
        // border-left: 2px solid $purple-500;
        // border-right: 2px solid $purple-500;
      }

      .cal-modal-head-contractor-label {
        font-size: 15px;
        background-color: #edeaf5;
        border-radius: 4px;
        color: #303031;

        &.vet {
          padding: 3px 12px;
        }
        &.tech {
          background-color: $blue-300;
          padding: 3px 12px;
        }
      }

      .cal-modal-head-contractor-shift-group-id {
        margin-left: 1.25rem;
        font-size: 14px;
      }

      .mobile-cal-modal-shift-time {
        font-family: "Jost", sans-serif;
      }

      .mobile-cal-modal-flexible-shift {
        margin-left: 2rem;
        border: 1.5px solid #fff;
        border-radius: 0.5rem;
        padding: 0.1rem 0.7rem;
        font-size: 16px;
      }

      .cal-modal-body {
        .vet-details {
          p {
            font-size: 16px;
            color: inherit;
            font-family: "Poppins", sans-serif;
          }

          .vet-bio {
            padding: 10px;
            justify-content: space-between;

            .bio-wrap {
              display: flex;
              flex: initial;
              align-items: center;
              padding-right: 0px;

              & a {
                font-size: 14px;
                margin-left: 0.5rem;
              }
            }
          }
        }

        .remove-decline {
          text-decoration: underline;
        }
      }

      .mobile-cal-contractor-request-li {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        display: block;
        background-color: initial;
        border-top: 1px solid $lightestGray;
      }

      // .mobile-cal-contractor-request-li + .mobile-cal-contractor-request-li {
      //   border-top: 1px solid $lightestGray;
      // }
    }
    #calendar-desktop {
      @media (max-width: 830px) {
        display: none;
      }
    }

    #calendar-mobile {
      margin-bottom: 2rem;
      @media (min-width: 830px) {
        display: none;
      }

      .vet-list .expired a,
      .vet-list .expired div.link-button {
        color: initial !important;
      }

      .pretty .state label {
        color: $dark-gray;
      }

      .filter-shift-cards-modal {
        box-shadow: initial;

        .btn-secondary:not(:disabled):not(.disabled).active,
        .btn-secondary:not(:disabled):not(.disabled):active,
        .show > .btn-secondary.dropdown-toggle {
          background-color: $light-blue;
          color: $dark-gray;
        }

        .btn-secondary {
          color: $gray-light-400;
          border: 1.33px solid $dark-blue;
          box-shadow: initial;
        }

        .modal-dialog,
        .modal-body {
          padding: initial;
        }

        .modal-content {
          height: 100vh;
          max-height: 100vh;
          top: 6.5rem;
        }
      }

      .mobile-calendar-floating-add-shift-modal {
        .modal-dialog {
          position: relative;
          top: initial;
          transform: initial !important;
          max-height: 100%;

          .modal-content {
            position: absolute;
            border-radius: 1rem 1rem 0 0;
            bottom: 0;
          }
        }
      }

      @keyframes rbc-month-view-animation {
        0% {
          opacity: 0;
        }
        90% {
          opacity: 0.1;
        }
        100% {
          margin-top: 0.75rem;
          opacity: 1;
        }
      }

      @keyframes rbc-time-view-animation {
        0% {
          opacity: 0.5;
        }
        100% {
          margin-top: 0;
          opacity: 1;
        }
      }

      .rbc-month-row {
        opacity: 0;
        margin-top: -50%;
        animation-duration: 0.25s;
        animation-name: rbc-month-view-animation;
        animation-fill-mode: both;
        transition-timing-function: ease-in-out;
      }

      .rbc-time-header-cell {
        opacity: 0.5;
        margin-top: 25%;
        animation-duration: 0.25s;
        animation-name: rbc-time-view-animation;
        animation-fill-mode: forwards;
      }

      .shift-mobile-card-title {
        color: $title-primary;
      }

      .calendar-wrap {
        background: 0;
        min-height: 0;
        box-shadow: initial;

        .hr-toggle-calendar-weekly-monthly {
          width: 15%;
          border: 1.5px solid rgba(0, 0, 0, 0.1);
          border-radius: 5px;

          & + hr {
            border: 1.25px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            margin-top: -0.25rem;
          }
        }

        .calendar-legends {
          display: none !important;
        }

        .cal-modal-wrap {
          background: 0;
        }

        .calendar-wrapper {
          width: 100%;
          // padding-right: 15px;
          // padding-left: 15px;
          margin-right: auto;
          margin-left: auto;

          .calendar-month-title {
            color: $purple-500;
            top: 16px;
            left: 2.05rem;
          }

          .rbc-calendar {
            min-height: 0;

            .rbc-toolbar {
              .rbc-btn-group:first-child {
                min-height: initial;
                padding: initial;
                margin-top: 1rem;

                // "Today" button
                & button:nth-child(1) {
                  display: none;
                }
                & button:nth-child(2) {
                  display: none;
                }
                & button:last-child {
                  display: none;
                }
              }
            }

            .rbc-month-view {
              margin-top: 3rem;
              border: 0;
              flex: 0;

              .rbc-before-todays-date {
                // color: $gray-light-400;
                color: #999;
              }

              .rbc-month-header {
                margin-bottom: 0.35rem;
              }

              .rbc-date-cell {
                text-align: center;
              }

              .rbc-row-segment {
                display: none;
              }

              .rbc-month-row + .rbc-month-row {
                border: 0;
                margin-top: 0.75rem;
              }

              .rbc-month-row {
                .rbc-day-bg + .rbc-day-bg {
                  border: 0;
                }

                .rbc-row-content {
                  .rbc-date-cell {
                    &.rbc-now {
                      overflow: visible;
                      background-color: initial;

                      div > span {
                        border-radius: 50%;
                        background-color: $purple-500;
                        color: $white;
                        font-weight: normal;
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                      }
                    }
                  }
                }
              }
              .rbc-header {
                border: 0;
              }
            }
          }
        }
      }

      .settings-menus-container {
        .settings-menus {
          padding: 16px 32px;
          padding-right: 0;
          border-top: 1px solid $lightestGray;
          cursor: pointer;
        }
        &:last-child {
          border-bottom: 1px solid $lightestGray;
        }
      }

      .card-box {
        width: 311px;
        padding: 16px;
        margin-right: 12px;
        margin-bottom: 8px;
        background: white;
        color: $dark-gray;
        box-shadow: 0px -2px 16px rgba(86, 86, 86, 0.25);
        border-radius: 8px;
      }

      // .credit-card {
      //   @extend .card-box;
      //   height: 176px;
      // }

      // .bank-card {
      //   @extend .card-box;
      //   height: 122px;
      // }

      .add-credit-card {
        padding-top: 16px;
      }

      .shift-mobile-card {
        box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
        border-radius: 12px;
        max-width: 365px;
        width: 100%;
        padding: 8px 16px 10px 10px;
        color: $dark-gray;
        margin-right: 0;
        margin-top: 16px;
        height: fit-content;
        &.active {
          background: #ffffff;
        }
        &.recommended {
          background: $purple;
        }
        &.disabled {
          background: #ebebeb;
          opacity: 0.7;
        }
        &.vet,
        &.hospital-admin {
          border-left: 11px solid $purple-500;
        }
        &.tech {
          border-left: 11px solid $light-blue;
        }
        &.hospital-user {
          border-left: 11px solid $yellow-500;
        }

        .shift-status-circle {
          width: 8px;
          height: 8px;
          border-radius: 100%;

          &.unfilled {
            background-color: $blue-200;
          }
          &.requested {
            background-color: $yellow-500;
          }
          &.expired {
            background-color: $red-base;
          }
          &.confirmed {
            background-color: $green-dark;
          }
          &.completed {
            background-color: $gray-200;
          }
        }

        .user-status {
          // @extend .small-body-text;
          color: $dark-gray;
          &:before {
            content: "";
            height: 8px;
            width: 8px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 4px;
          }
          &.active {
            &:before {
              background: $green-300;
            }
          }
          &.invited {
            &:before {
              background: $gray-200;
            }
          }
        }

        .contractor-type {
          &.vet {
            background-color: $purple;
            padding: 3px 10px;
          }
          &.tech {
            background-color: $blue-300;
            padding: 3px 10px;
          }
        }

        .mobile-shift-card-link {
          text-decoration: underline;
          color: $green-dark;
          font-weight: 600;
        }
      }

      .form-group {
        .form-control {
          padding: 12px 8px !important;
          border: 1px solid $light-blue !important;
          border-radius: 4px !important;
          background: #ffffff !important;
          height: 48px !important;
          color: $dark-gray !important;
          box-shadow: none !important;
          &:disabled {
            background: #ebebeb !important;
          }
        }
      }

      #manageUsers .modal-dialog {
        max-width: 500px !important;
      }

      .rbc-calendar {
        .rbc-time-view {
          border: 0;

          > .rbc-time-header {
            flex-direction: column;
            margin-top: 3rem;
          }

          .rbc-time-header {
            .rbc-label.rbc-time-header-gutter {
              padding: initial;
            }

            .mobile-calendar-weekly-header {
              margin-bottom: 1rem;

              .rbc-header,
              .rbc-header + .rbc-header {
                border: 0;
              }
            }

            .rbc-time-header-content {
              border: 0;

              .rbc-time-header-cell {
                a {
                  color: $dark-gray;
                }

                .rbc-today {
                  background: 0;
                }

                // .rbc-today,
                .rbc-selected-day {
                  overflow: visible;
                  background-color: initial;

                  a > span,
                  span > span {
                    // padding: 1px 7px;
                    border-radius: 50%;
                    background-color: $purple-500;
                    color: $white;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                  }
                }

                .rbc-header {
                  text-overflow: initial;
                  border-bottom: 0;
                  font-weight: 500;
                }

                .rbc-header + .rbc-header {
                  border: 0;
                }
              }

              .rbc-allday-cell {
                display: none;
              }
            }
          }

          .rbc-time-content {
            display: none;
          }
        }
      }
    }

    .search-result-wrapper {
      .calendar-wrap {
        padding-top: 30px;
        background: transparent;
        box-shadow: none;
        .cal-modal-wrap {
          top: 30px;
        }
        .calendar-wrapper {
          background: #fff;
          height: 300px !important;
          box-shadow: 0 2px 15px 0 rgba(65, 40, 148, 0.09);
          .shifts-toggle-calendar {
            position: relative;
            top: -36px;
            overflow: hidden;
            & > span {
              display: block;
              float: left;
              width: 50%;
              text-align: center;
            }
          }
        }
        .rbc-calendar {
          position: relative;
          top: -18px;
          .rbc-toolbar .rbc-btn-group:first-child {
            padding: 6px;
            min-height: 40px;
            overflow: hidden;
            button:first-child {
              display: none;
            }
            button:nth-child(2),
            button:last-child {
              width: 28px;
              height: 28px;
            }
            button:nth-child(2) {
              float: left;
              margin: 0 0 0 10px;
            }
            button:last-child {
              float: right;
              margin: 0 10px 0 0;
            }
          }
          .rbc-month-view {
            border: 0;
            .rbc-month-header {
              height: 20px;
              visibility: hidden;
            }
            .rbc-month-row,
            .rbc-day-bg + .rbc-day-bg {
              border: 0;
            }
            .rbc-event {
              display: none;
            }
            .rbc-date-cell {
              text-align: center;
            }
          }
        }
        .calendar-legends {
          bottom: -65px;
          span {
            padding: 5px 20px 5px 24px;
          }
        }
      }
    }
  }
}

// @media all and (max-width:1318px) {
//   #root{
//     .calendar-wrap{
//       .add-shift-calendar-position {

//       }
//     }
//   }
// }

.paw-print {
  width: 11% !important;
  height: 29px !important;
  position: absolute !important;
  left: 17%;
  top: 17%;
  @media screen and (max-width: 1199px) {
    width: 7% !important;
    left: 12%;
  }
  @media screen and (max-width: 1198px) {
    width: 10% !important;
    left: 15%;
  }
  @media screen and (max-width: 1023px) {
    width: 7% !important;
    left: 10%;
  }
  @media screen and (max-width: 991px) {
    width: 8% !important;
    left: 14%;
  }
  @media screen and (max-width: 767px) {
    width: 6% !important;
    left: 10%;
  }
  @media screen and (max-width: 500px) {
    width: 6% !important;
    left: 11%;
  }
}

.paw-tooltip {
  display: inline-block;
}
.paw-tooltip .tooltiptext {
  visibility: hidden;
  width: 170px;
  background-color: #fff;
  color: #535353;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 14px;
  font-weight: normal;
  line-height: 15px;
  top: 30%;
  border: 0.5px solid #e2e1e1;
  padding: 10px;
  box-shadow: 0px 0px 2px 2px rgba(232, 234, 247, 0.66);
}

.paw-tooltip:hover .tooltiptext {
  visibility: visible;
}

.paw-print-vet {
  width: 11% !important;
  height: 29px !important;
  position: absolute !important;
  left: 44px;
  top: 70px;
  @media screen and (max-width: 1199px) {
    width: 7% !important;
    left: 12%;
  }
  @media screen and (max-width: 1198px) {
    width: 10% !important;
    left: 15%;
  }
  @media screen and (max-width: 1023px) {
    width: 7% !important;
    left: 10%;
  }
  @media screen and (max-width: 991px) {
    width: 9% !important;
    left: 14%;
  }
  @media screen and (max-width: 767px) {
    width: 6% !important;
    left: 9%;
  }
  @media screen and (max-width: 500px) {
    width: 6% !important;
    left: 10%;
  }
}

.top-21 {
  top: 21% !important;
}

.pop-up-notification-hover {
  color: #785fcd !important;
  display: inline-block !important;
}

#calendar-desktop {
  @media (max-width: 767px) {
    display: none;
  }
}

#calendar-mobile {
  margin-bottom: 2rem;
  @media (min-width: 767px) {
    display: none;
  }
}
@media (min-width: 500px) and (max-width: 900px) {
  .tablet-calendar-view-msg {
    display: block;
  }
}

@media (min-width: 900px) {
  .tablet-calendar-view-msg {
    display: none;
  }
}

@media (max-width: 350px) {
  .filter-shift-card-button {
    right: -12px;
  }

  #root {
    #calendar-mobile {
      .calendar-wrap {
        .calendar-wrapper {
          .rbc-calendar {
            .rbc-toolbar {
              .rbc-btn-group:first-child {
                & button:nth-child(2) {
                  left: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
