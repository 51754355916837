@media all and (min-width: 990px) and (max-width: 1280px) {
  #root,
  body {
    .carousel#trainingSlider {
      padding: 50px 0;
    }
  }
}

@media all and (min-width: 1199px) {
  #root,
  body {
    .container {
      // max-width: 1170px;
      max-width: 1300px;
    }
  }
}

@media all and (max-width: 1024px) {
  #root,
  body {
    .inner-container {
      padding: 60px 0 40px;
    }
    .btn,
    input[type="submit"] {
      padding: 10px 38px;
    }
    .carousel#trainingSlider {
      padding: 50px 0;
      .finish-training {
        right: 50px;
        top: 10px;
        padding: 8px 20px;
      }
    }
  }
}
@media (min-width: 567px) and (max-width:1200px){
  .container {
    max-width: 100%;
  }
}

@media all and (min-width: 992px) {
  #root,
  body {
    .message-wrapper {
      padding: 50px 60px 40px;
      text-align: center;
    }
  }
}

@media all and (max-width: 1200px) {
  #root,
  body {
    .site-header .navbar .navbar-toggler {
      display: inline-block;
    }
    .site-header .navbar .navbar-collapse {
      .close-menu {
        top: 20px;
      }
      .menu-top-menu-container {
        &.inner-page-menu {
          padding-top:40px;
          .navbar-nav {
            flex-direction: column;
            li {
              padding: 10px 30px;
              &.sm-user {
                .user-pic {
                  width: 50px;
                  height: 50px;
                  overflow: hidden;
                  border-radius: 100px;
                  display: flex;
                  border: 1px solid #ececef;
                }
              }

              .dropdown-sm-menu {
                padding: 40px 30px 10px;
              }
            }
          }
        }
      }
      .dropdown-sm-menu {
        a {
          color: #535353;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .site-header {
      .navbar {
        align-items: flex-start;
        justify-content: space-between;
        .nabar-brand {
          width: 120px;
          // margin: 0 auto 30px auto;
          @include d-inline-block;
        }
        .navbar-toggler {
          padding: 0;
          position: relative;
          &:focus {
            outline: 0;
            box-shadow: none;
          }
          .navbar-toggler-icon {
            background: url("../images/menu.png") no-repeat center center;
            background-size: 100%;
          }
        }
        .menu-overlay {
          background: $title-secondary;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 2;
          opacity: 0.3;
        }
        .navbar-collapse {
          position: fixed;
          top: 0;
          bottom: 0;
          right: -30%;
          width: 30%;
          background: $white;
          z-index: 5;
          display: block !important;
          @media screen and (max-width: 786px) {
            right: -60%;
            width: 60%;
          }
          @media screen and (max-width: 476px) {
            right: -80%;
            width: 80%;
          }
          @include transition;
          &.collapse.show {
            right: 0;
            @include transition;
          }
          &.collapse:not(.show) {
            display: block;
          }
          .menu-top-menu-container {
            width: 100%;
            padding: 170px 0 0;
            .navbar-nav {
              li {
                padding: 14px 30px;
                a {
                  margin: 0;
                  float: left;
                  &.btn {
                    top: 0;
                    margin-top: 50px;
                  }
                }
              }
            }
          }
          .close-menu {
            height: 24px;
            width: 24px;
            background: url("../images/close-menu.png") no-repeat center center;
            background-size: 100%;
            position: absolute;
            right: 16px;
            top: 16px;
          }
        }
      }
    }
    .d-block-md {
      display: none !important;
    }
    button.btn.lg-btn {
      padding: 10px 30px;
    }
    .modal {
      &.fade {
        .modal-dialog {
          -webkit-transform: translate(0, 25%);
          transform: translate(0, 25%);
        }
      }
      &.show {
        .modal-dialog {
          -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
          &.sm-modal-height {
            align-items: center !important;
          }
        }
      }
      &.fade.show {
        .modal-content {
          border-radius: 0.3rem 0.3rem 0 0;
          animation: 200ms ease normal forwards 1 fadeinSm;
          -webkit-animation: 200ms ease normal forwards 1 fadeinSm;
          opacity: 1;
          transform: translate(0, 0);
        }
      }
      .modal-dialog {
        max-width: 100%;
        margin: 20px 0 0;
        max-height: calc(100% - 40px);
        min-height: inherit;
        .modal-body {
          padding: 20px;
          h3 {
            font-size: 18px;
            line-height: 30px;
          }
          p {
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 60px;
            @media screen and (max-width: 420px) {
              margin-bottom: 30px;
            }
          }
          form .btn {
            width: 100%;
            @media screen and (max-width: 420px) {
              width: auto;
            }
          }
          .vet-dashboard {
            p {
              margin-bottom: 10px;
            }
          }
        }
        .message-wrapper {
          padding-top: 140px;
          text-align: center;
          h3 {
            padding: 0 50px;
          }
        }
      }
      .sm-modal-close {
        display: block;
        margin-top: 150px;
        margin-bottom: 30px;
        padding: 8px 30px;
      }
    }
    .upload-photo-wrap {
      padding: 50px 50px 30px;
      &.image-in {
        .upload-top-row {
          justify-content: center;
          & > .item.upload-btn {
            padding: 0;
            .btn {
              width: 180px;
              padding: 10px;
            }
          }
        }
        .thumb-wrap li {
          width: 50%;
        }
      }
      .upload-top-row {
        & > .item.upload-btn .btn {
          width: 180px;
          padding: 10px;
        }

        .or {
          display: none;
        }
      }
    }
    .carousel {
      &#trainingSlider {
        height: auto;
        .finish-training {
          right: 0;
          left: auto;
          bottom: 22px;
          top: auto;
          margin: auto;
          z-index: 10;
        }
      }
      .slick-slider-wrapper .slick-slider {
        .slick-list {
          .slick-track {
            .slick-slide {
              padding: 40px 10px;
              height: auto;
              min-height: 380px;
            }
          }
        }
      }
    }
    .history-head {
      display: block !important;
    }
    .table-container .table thead th,
    .table-container .table tbody tr td a {
      white-space: nowrap;
    }
    .table-container .table thead th br {
      display: none;
    }
  }
}

@keyframes fadeinSm {
  0% {
    opacity: 0.5;
    transform: translate(0, 20%);
  }
  33% {
    opacity: 0.7;
    transform: translate(0, 14%);
  }
  66% {
    opacity: 0.9;
    transform: translate(0, 7%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@-webkit-keyframes fadeinSm {
  0% {
    opacity: 0.5;
    transform: translate(0, 20%);
  }
  33% {
    opacity: 0.7;
    transform: translate(0, 14%);
  }
  66% {
    opacity: 0.9;
    transform: translate(0, 7%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media all and (min-width: 1200px) {
  #root,
  body {
    .navbar-nav {
      float: right;
      margin: 0;
    }
  }
}

@media all and (max-width: 767px) {
  #root,
  body {
    .sm-font-16 {
      font-size: 16px;
      line-height: 24px;
    }
    .sm-none {
      display: none;
    }
    .btn.md-btn.min-width,
    a.btn.md-btn.min-width,
    button.btn.md-btn.min-width,
    input[type="submit"].md-btn.min-width {
      min-width: 130px;
    }
    .sm-w-100 {
      width: 100%;
    }
    .sm-px-none {
      padding: 0 !important;
    }
    .sm-back-arrow {
      position: absolute !important;
      left: 10px !important;
      top: 0 !important;
      cursor: pointer;
    }
    .tab-container {
      .table-tab {
        padding: 8px 20px;
        width: auto;
      }
      .table-tab-list {
        padding-bottom: 7px;
      }
      .inner-tab-list {
        .table-tab {
          width: 100%;
        }
      }
    }
    .mobColHide {
      .table-container {
        tr {
          td,
          th {
            &:last-child {
              display: none;
            }
          }
        }
      }
    }
    .inner-container,
    .inner-container.py-md,
    .inner-container.sm-top {
      padding: 112px 10px 40px; // check ios small screen if change this
      .well {
        padding: 20px 15px;
      }
      .lft-arrow-green-dark {
        top: 4px;
        width: auto;
        height: 40px;
        overflow: hidden;
        display: inline-block;
        &:before {
          height: 22px;
          width: 38px;
          background-size: 34px;
        }
      }
      .left-arrow-wrapper {
        width: auto;
        position: absolute;
        left: 0;
        top: 4px;
        z-index: 9;
        a {
          text-indent: -999px;
          overflow: hidden;
          width: 50px;
          display: block;
          &:before {
            width: 31px;
            background-position: left center;
            background-size: 100%;
          }
        }
      }
      .training-container {
        padding-top: 0;
        .lft-arrow-green-dark {
          top: 4px;
          width: 40px;
          overflow: hidden;
          &:before {
            height: 22px;
            width: 38px;
            background-size: 34px;
          }
        }
        .carousel#trainingSlider {
          margin-left: -15px;
          margin-right: -15px;
          padding: 0;
          .slick-slider-wrapper .slick-slider {
            padding: 10px 0 120px;
            .slick-list {
              border: 0;
              box-shadow: none;
            }
            .slick-slide {
              padding: 0;
              min-height: 300px;
              & > div:first-child {
                display: block !important;
              }
              div[tabindex="-1"] {
                display: block !important;
                img {
                  display: inline-block;
                  max-height: 160px;
                  &.lg-img {
                    max-height: 200px;
                  }
                }
              }
            }
            div[class^="col-"],
            div[class*=" col-"] {
              flex: 0 0 100%;
              max-width: 100%;
              padding: 0;
              span,
              h3 {
                width: 100%;
                display: block;
                text-align: center;
              }
              span {
                font-size: 14px;
                line-height: 23px;
              }
              h3 {
                font-size: 18px;
                line-height: 22px;
              }
              p {
                font-size: 13px;
                line-height: 21px;
              }
              &:last-child {
                padding-top: 20px;
              }
            }
            .slick-arrow.slick-prev,
            .slick-arrow.slick-next {
              top: auto;
              bottom: 60px;
            }
            .slick-arrow.slick-prev,
            .slick-arrow.slick-next {
              background: url("../images/slider-prev-arrow.png") center center no-repeat;
              transform: none;
              height: 40px;
              width: 36px;
              background-size: auto;
              left: -5px;
            }
            .slick-arrow.slick-next {
              background: url("../images/slider-next-arrow.png") center center no-repeat;
              right: -5px;
              left: auto;
            }
          }
        }
      }
      .view-profile {
        .page-title {
          font-size: 16px;
          line-height: 26px;
        }
        .slick-slider-wrapper {
          .slick-slider {
            padding: 0;
          }
        }
      }
    }
    .btn,
    a.btn,
    button.btn,
    input[type="submit"] {
      min-width: inherit;
      &.min-width {
        min-width: 220px;
      }
    }
    .primary-title {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 10px;
    }
    .modal {
      &.show.sm-modal .modal-dialog {
        align-items: unset;
        &.modal-dialog-centered {
          align-items: center;
        }
      }
      .modal-content {
        .modal-body {
          padding: 20px;
          h3 {
            font-size: 18px;
            line-height: 30px;
          }
          h4 {
            font-size: 12px;
            line-height: 21px;
          }
          p {
            font-size: 14px;
            line-height: 19px;
          }
          label {
            font-size: 16px;
            line-height: 26px;
          }
          .btn {
            margin-top: 30px;
          }
          .message-wrapper {
            padding-top: 100px;
          }
          .sm-modal-close {
            margin-top: 100px;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 23px;
          }
          .terms-and-conditions {
            p {
              margin-bottom: 30px;
            }
          }
          .btn-reverse-group.sm {
            display: flex;
            flex-direction: column-reverse;
          }
        }
      }
    }
    .reg-forms {
      .message {
        right: 0;
        &.label-message {
          top: 22px;
        }
      }
    }
    .Select--multi {
      .Select-control {
        .Select-multi-value-wrapper {
          .Select-value {
            margin: 4px 0 0 4px; // *** HERE
            .Select-value-label {
              min-height: 26px; // *** HERE
              line-height: 22px;
            }
            .Select-value-icon {
              top: 1px;
            }
          }
        }
      }
    }
    .Select--multi .Select-control .Select-multi-value-wrapper {
      min-height: 35px;
    }
    .Select .Select-control {
      height: 40px;

      .Select-multi-value-wrapper {
        height: 35px;
        .Select-input > input {
          height: 26px;
          line-height: 20px;
        }
        .Select-value {
          .Select-value-label {
            line-height: 26px;
          }
          .Select-value-icon {
            top: 1px;
          }
        }
      }
      .Select-arrow-zone:after {
        top: 10px;
      }
      .Select-value .Select-value-label,
      .Select-placeholder {
        line-height: 38px;
        font-size: 13px;
      }
    }
    .hospital-edit-profile {
      overflow: hidden;
      .upload-photo-wrap {
        .upload-top-row {
          > .item {
            &.upload-btn {
              .btn {
                width: 130px;
                font-size: 14px;
                border: 2px solid #007a4c;
                box-shadow: 0 0 0 2px #007a4c inset;
                &:after {
                  content: "update";
                  position: absolute;
                  top: 0;
                  left: 0;
                  background-color: #fff;
                  right: 0;
                  height: 44px;
                  width: 130px;
                  line-height: 44px;
                }
              }
            }
          }
        }
      }
      &.vet-image {
        .upload-photo-wrap {
          width: 100%;
          float: none;
          .upload-top-row {
            > .item {
              &.upload-btn {
                .btn {
                  &:after {
                    content: "Browse";
                  }
                }
              }
            }
          }
        }
      }
    }
    .table-container {
      .table {
        thead th,
        tbody tr td a {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          white-space: normal;
          max-width: 120px;
          &.legend {
            white-space: normal;
            &:before {
              display: none;
            }
          }
        }
      }
    }
    .mobileButtonWrap {
      text-align: left !important;
      .btn {
        margin: 10px !important;
      }
    }
  }
}

@media all and (max-width: 576px) {
  #root,
  body {
    .font-13-sm-12 {
      font-size: 12px;
    }
    .sm-padding-none {
      padding: 0 !important;
    }
    .required {
      position: relative;
      .message {
        padding: 1px 0 0 0;
        position: static;
        margin-bottom: 3px;
      }
    }
    .ratings-wrapper.profile-ratings,
    .ratings-wrapper.profile-ratings.edit-profile {
      padding: 0;
      overflow: hidden;
      .ratings {
        margin-bottom: 8px;
        li {
          width: 44px;
          height: 44px;
          margin: 0 6px;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          [type="radio"]:not(:checked) + label i {
            line-height: 42px;
          }
        }
      }
      & > span {
        position: relative;
        font-size: 12px;
        line-height: 18px;
        display: block;
        &.lft-lable {
          float: left;
        }
        &.rht-lable {
          float: right;
          text-align: right;
        }
      }
    }
    .ratings-wrapper.profile-ratings.edit-profile {
      & > span {
        font-size: 14px;
      }
    }
    form {
      .message {
        &.zip {
          top: -24px;
        }
      }
      .input-group {
        .form-control {
          max-width: 100%;
        }
      }
    }
    .w9-wrap {
      padding: 16px 16px 50px;
    }
  }
}

@media all and (max-width: 321px) {
  #root,
  body {
    .ratings-wrapper.profile-ratings {
      padding: 0;
      overflow: hidden;
      .ratings {
        margin-bottom: 8px;
        li {
          width: 36px;
          height: 36px;
          margin: 0 8px;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          [type="radio"]:not(:checked) + label i {
            line-height: 34px;
          }
        }
      }
      & > span {
        position: relative;
        font-size: 12px;
        line-height: 18px;
        &.lft-lable {
          float: left;
        }
        &.rht-lable {
          float: right;
          text-align: right;
        }
      }
    }
    .w9-wrap {
      padding: 10px 10px 40px;
    }
  }
}
