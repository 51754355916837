.hospital-shifts-view {
  .hospital-shifts-header {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 63px;
    color: $title-primary;
    padding-bottom: 8px;
  }
}
