#root,
body {
  .site-header {
    background: none;
    padding-top: 30px;
    .navbar {
      padding: 0;
      .nabar-brand {
        width: 175px;
        display: block;
      }
      .menu-top-menu-container {
        float: right;
      }
      .navbar-nav {
        & li > a {
          padding: 0;
          margin: 0 20px;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 1.13px;
          color: $primary-color;
          cursor: pointer;
          @include transition;
          &:hover {
            opacity: 0.8;
            @include transition;
          }
          &.btn {
            top: -6px;
            line-height: 36px;
            &.btn-outline-primary {
              height: 40px;
              width: 154px;
              min-width: auto;
              border: 2px solid #007a4c;
              border-radius: 24.5px;
              box-shadow: 0 2px 3px 0 rgba(134, 134, 134, 0.22);
              &:not(:disabled):not(.disabled):active,
              &:not(:disabled):not(.disabled):active {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
    &.forgot_password_page {
      padding-bottom: 25px;
    }
  }

  .inner-container {
    .site-header {
      @media all and (max-width: 768px) {
        position: absolute;
      }
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      min-height: 60px;
      padding: 8px 0;
      background: white;
      z-index: 90;
      .nabar-brand {
        width: 120px;
        padding-top: 8px;
      }
      &.ifTrainingModal {
        z-index: 9999;
      }
      &.backSideHeader {
        z-index: 1;
      }
      &.pending-enterprise-header {
        padding-bottom: 0;
      }
      .inner-page-menu {
        display: block;
        .long-menus {
          @media (min-width: 900px) and (max-width: 1200px) {
            justify-content: center;
          }
          li {
            padding: 0 10px !important;
          }
          .dropdown {
            & > a {
              span {
                max-width: 120px !important;
              }
            }
          }
        }
        .navbar-nav li {
          padding: 0 20px;
          display: flex;
          align-items: center;
          &:last-child {
            padding-right: 26px;
          }
          & a {
            color: $title-primary;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 26px;
            align-items: center;
            margin: 0;
            &:hover {
              &:after {
                background: $title-primary;
              }
            }
            &.dropdown-toggle {
              @extend .secondaryFont;
              color: $text-secondary;
              &:after {
                border: 0;
                width: 100%;
                margin: 0;
              }
              &:hover {
                &:after {
                  background: $text-secondary;
                }
              }
            }
          }
          &.dropdown {
            position: relative;
            .dropdown-menu {
              left: auto;
              right: initial;
              box-shadow: 0 2px 15px 0 rgba(185, 185, 185, 0.35);
              border-radius: 15px;
              border: 0;
              margin-top: 8px;
              .dropdown-item {
                padding: 0;
                // float: left;
                width: auto;
                margin: 5px 10px;
                @extend .secondaryFont;
                color: $text-secondary;
                &.add-shift {
                  color: $green-dark;
                  text-decoration: underline;
                }
                &:hover {
                  background: transparent;
                  &:after {
                    background: $text-secondary;
                  }
                }
              }
            }
            .user-pic {
              width: 40px;
              height: 40px;
              border: 1px solid #ccc;
              border-radius: 100px;
              overflow: hidden;
              position: absolute;
              left: 16px;
              top: -5px;
              bottom: 0;
              margin: auto;
              margin-right: 12px;
              background: url("../images/user_default.png") center center no-repeat;
              background-size: 100%;
              img {
                min-width: 38px;
                min-height: 38px;
              }
            }
            .dropdown-toggle,
            .dropdown-toggle-white {
              padding-left: 50px;
              padding-right: 25px;
              position: relative;
              &:before {
                content: "";
                position: absolute;
                right: 0;
                top: 5px;
                bottom: 0;
                margin: auto;
                width: 12px;
                height: 8px;
                background: url("../images/dropdown_arrow.svg") center bottom no-repeat;
                @extend .transition;
                transform-style: preserve-3d;
              }
              &:hover {
                &:after {
                  display: none;
                }
              }
              span {
                &:last-child {
                  color: #495187 !important;
                }
              }
              span.userNameLink {
                font-size: 14px;
              }
            }
            .dropdown-toggle-white {
              &:before {
                content: "";
                position: absolute;
                left: 5rem;
                right: 0;
                top: 0px;
                bottom: 0;
                margin: auto;
                width: 12px;
                height: 8px;
                background: url("../images/dropdown_arrow_white.svg") center bottom no-repeat;
                @extend .transition;
                transform-style: preserve-3d;
              }
            }
            a.dropdown-toggle,
            a.dropdown-toggle-white {
              padding-left: 0;
              color: #412894 !important;
              font-weight: 600;
            }
            #profile-menu.dropdown-toggle,
            #profile-menu.dropdown-toggle-white {
              padding-left: 50px;
              font-weight: normal;
            }
            span {
              position: relative;
              max-width: 200px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              &:after {
                content: "";
                opacity: 0;
                position: absolute;
                bottom: -2px;
                left: 0;
                right: 0;
                height: 1px;
                background: #535353;
              }
              &:hover {
                &:after {
                  opacity: 1;
                  background: $text-secondary;
                }
              }
            }
          }
          &.show {
            &.dropdown {
              .dropdown-toggle,
              .dropdown-toggle-white {
                &:before {
                  transform: rotateX(-180deg);
                }
              }
            }
          }
        }
      }
    }
  }
}
