body {
  #root {
    .shift-history-label {
      font-family: Poppins, sans-serif !important;
      font-size: 14px !important;
    }
  }
}
.shift-history-info {
  color: $label-primary;
}

.shift-history-payInfo {
  // color: $requestedShift;
  font-size: 20px;
  small {
    font-size: 65%;
    display: block;
  }
}

.shift-history-additional {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 5px;
  margin-bottom: 5px;
  @media screen and (max-width: 767px) {
    width: 57px;
  }

  .ot-hover {
    display: block;
    font-size: 13px;
    text-align: right;
    @media screen and (min-width: 767px) {
      display: none;
    }
  }
}
form {
  .form-control {
    &.history-date-range {
      @media screen and (max-width: 767px) {
        font-size: 13px !important;
      }
    }
  }
}

.tax-text {
  div {
    font-family: "Jost", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #412894;
    margin: 0 0 0 16px;
  }
  span {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #303031;
    margin: 0 0 24px 16px;
  }
}

.tax-line-break {
  width: 100%;
  border: 1px solid #d6d6d6;
}

.export-text {
  padding-top: 32px;
  margin-left: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #303031;
  padding-right: 29px;
  @media screen and (max-width: 768px) {
    padding-right: 12px;
  }
  div {
    font-size: 16px;
    line-height: 24px;
  }
  span {
    font-size: 12px;
    line-height: 20px;
  }
}

.fine-print {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #007a4c;
  margin-top: 24px;
  cursor: pointer;
  width: 118px;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.fine-print-panel {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  padding-left: 20px;
  div {
    font-family: "Jost", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #412894;
  }
  span {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #303031;
    margin: 0 29px 24px 0;
  }
}

.shift-history-row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 768px) {
    align-items: flex-end;
    flex-direction: column;
    padding: 16px;
    justify-content: space-between;
    white-space: nowrap;
  }
  > div,
  > p,
  a,
  td {
    width: 25%;
    @media screen and (max-width: 768px) {
      width: auto;
    }
  }
}

.shift-history-titles {
  max-width: 1120px;
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #303031;
  margin: 24px 0 0 306px;
  padding: 0 24px 8px 0;
  &.shift {
    :nth-child(1) {
      width: 19%;
    }
    :nth-child(2) {
      width: 19%;
    }
    :nth-child(3) {
      width: 19%;
    }
    :nth-child(4) {
      width: 20%;
    }
    :nth-child(5) {
      width: 23%;
      padding-left: 6%;
    }
  }
  :nth-child(2) {
    width: 26%;
  }
  :nth-child(3) {
    width: 26%;
  }
  div {
    width: 25%;
    img {
      height: 16px;
      width: 16px;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.mobile-shift-history-titles {
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 16px;
    font-size: 12px;
    color: #303031;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }
}

.deductions-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 48px;
  background: #ffffff;
  border: 2px solid #339570;
  border-radius: 20px;
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #303031;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 0 24px 0;
    padding-left: 16px;
    justify-content: flex-start;
  }
}
