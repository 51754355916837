.info-popup {
  width: 345px;
  background-color: #ffffff;
  box-shadow: 0 2px 22px 0 rgba(45, 31, 90, 0.25);
  text-align: left;
  position: absolute;
  left: 100%;
  top: 50%;
  margin-left: 20px;
  z-index: 10;
  transform: translateY(-50%);
  display: none;
  &.dark {
    width: 280px;
    background-color: #050505;
    color: #fff;
    box-shadow: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    opacity: 1;
    background: url("../images/close.svg") no-repeat center center;
    &:hover {
      opacity: 0.8;
    }
    &:focus {
      outline: none;
    }
  }
  .top-row {
    padding: 12px;
    border-bottom: 2px solid #dddddd;
    h6 {
      color: #7b87cc;
      font-size: 16px;
      letter-spacing: 1.33px;
      line-height: 26px;
      margin: 0;
    }
    span {
      color: #949494;
      @extend .secondaryFont;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .cost-details {
    color: #757575;
    @extend .secondaryFont;
    font-size: 16px;
    line-height: 22px;
    padding: 12px 0;
    .additional {
      padding-top: 12px;
      margin: 0;
      border-top: 2px solid #dddddd;
    }
    p {
      overflow: hidden;
      padding: 0 12px;
      span {
        float: left;
      }
      strong {
        font-weight: 600;
        color: #535353;
        float: right;
      }
    }
    .col-left {
      display: inline-block;
      margin-left: 12px;
      width: 232px;
    }
    .col-right {
      display: inline-block;
      margin-right: 8px;
      width: 90px;
    }
  }
}

.top-price-info-wrap {
  position: relative;
  top: 250px;
  left: -260px;
}

.info-icon-blue {
  background: url("../images/ic_info.svg") center top no-repeat;
  background-size: 100%;
  height: 25px;
  width: 25px;
  cursor: pointer;
  &:hover + .tech-standing-info-wrap,
  &:hover + .tech-standing-info-wrap-feedback-form {
    display: block !important;
  }
}

.tech-standing-info-wrap-feedback-form {
  display: none;
  z-index: 800;
  cursor: default;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 16px;
  bottom: -30px;
  right: -120px;
  max-width: 310px;
  padding: 2px 6px;
  overflow-wrap: break-word;
  position: absolute;
  line-height: 24px;
  user-select: none;
  background-color: #ffffff;
  box-shadow: 0 2px 22px 0 rgba(45, 31, 90, 0.25);
  &:hover {
    display: block !important;
  }
}

.standing-change-message-box {
  .tech-standing-info-wrap {
    display: none;
    z-index: 800;
    cursor: default;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 16px;
    left: 290px;
    max-width: 310px;
    padding: 2px 6px;
    overflow-wrap: break-word;
    position: fixed;
    line-height: 24px;
    top: 15px;
    user-select: none;
    background-color: #ffffff;
    box-shadow: 0 2px 22px 0 rgba(45, 31, 90, 0.25);
    &:hover {
      display: block !important;
    }
    .tech-standing-popover {
      .table {
        margin-bottom: 0px !important;
        color: #535353;
        tbody {
          tr {
            td {
              &:nth-child(1) {
                width: 185px;
              }
              &:nth-child(2) {
                width: 111px;
                text-align: center;
              }
            }
          }
        }
        thead {
          color: #412894;
          tr {
            th {
              &:nth-child(1) {
                width: 185px;
              }
              &:nth-child(2) {
                width: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.tech-top-price-info-wrap {
  position: relative;
  top: 130px;
  left: -260px;
}

.pay-info-wrap {
  position: relative;
  top: 228px;
  left: -320px;
}

@media all and (max-width: 767px) {
  .top-price-info-wrap,
  .tech-top-price-info-wrap {
    left: 0;
    right: 0;
  }

  .pay-info-wrap {
    left: -235px;
  }

  .info-popup {
    left: -19px;

    top: 0;
    margin-left: 0;
  }
  .standing-change-message-box {
    .tech-standing-info-wrap {
      right: 0px;
      left: auto;
    }
  }
}

@media (min-width: 768px) {
  .info-popup {
    left: 90%;
    top: 130%;
  }
}

@media (min-width: 900px) {
  .info-popup {
    left: 80%;
    top: 160%;
  }
}

@media (min-width: 1200px) {
  .info-popup {
    left: 65%;
  }
}
