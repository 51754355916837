.date-range {
  .previous {
    background: url("../images/arrow_left.png") center center no-repeat;
    height: 44px;
    width: 44px;
    cursor: pointer;
  }
  .next {
    background: url("../images/arrow_right.png") center center no-repeat;
    height: 44px;
    width: 44px;
    cursor: pointer;
  }
  .inactive {
    opacity: 0.35;
    cursor: default;
    pointer-events: none;
  }
  input {
    width: 120px;
    height: 40px !important;
  }
  p.dash {
    position: relative;
    top: 10px;
    color: #9e9fa0;
  }
}

#root .feedback-form {
  .custom-check [type="radio"]:not(:checked) + label {
    color: $title-secondary !important;
  }
  li.custom-check [type="radio"]:not(:checked) + label {
    color: $text-primary !important;
  }

  .custom-check [type="radio"]:checked + label {
    color: $title-secondary !important;
  }
  li.custom-check [type="radio"]:checked + label {
    color: $text-primary !important;
  }

  .input-group {
    .form-control {
      width: 0;
    }
    .input-group-text {
      background: #e9ecef;
      border-color: #ced4da;
      cursor: unset;
      &:hover {
        background-color: #e9ecef;
      }
    }
  }
}

#root {
  .admin-tab-header-form {
    width: 700px;
    .input-group {
      label {
        color: #5862a3;
      }
      input[type="text"].sm {
        width: 100px;
        box-shadow: inset 1px 1px 7px 0 rgba(232, 234, 247, 0.66);
        background-color: $white;
        @extend .form-element-border;
      }
      input:focus {
        outline: none;
      }
      textarea {
        resize: none;
        height: 88px !important;
      }
    }
  }
}

.asterisk {
  width: 6px;
  height: 6px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.price-feedback-input {
  height: 29px;
  width: 69px;
  border: 2px solid #e8eaf7;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: inset 1px 1px 7px 0 rgba(232, 234, 247, 0.66);
  position: absolute;
  right: 0;
}

#root {
  .date-header-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 48px;
    color: $title-primary;
  }
  .date-picker-wrapper {
    &.admin {
      .day-picker-icon-button {
        width: 160px;
        position: relative;
        cursor: pointer;
        &:after {
          content: "";
          height: 30px;
          width: 30px;
          background: url("../images/calendar.svg") center center no-repeat;
          position: absolute;
          left: 110px;
          top: 0;
          bottom: 0;
          margin: auto;
          pointer-events: none;
        }
      }
      .DayPicker {
        .DayPicker-Weekdays {
          &:before {
            opacity: 0; // hide instructions
          }
          .DayPicker-WeekdaysRow {
            opacity: 0;
          }
        }
      }
    }
  }
}

.billing {
  background: #fce3d5;
}

.hospital-ot {
  background: #e2e2e2;
}

.hospital-bonus {
  background: #baeaf5;
}

.hospital-rttr {
  background: #ade4b9;
}

.hospital-other {
  background: #c9cdfb;
}

.contractor-ot {
  background: #ffe7e7;
}

.contractor-bonus {
  background: #e4d0f9;
}

.contractor-other {
  background: #eae5a0;
}

.profile-margin-top {
  margin-top: -44px;
}

table.table-nested td {
  padding: 3px 18px 3px 0px;
}

.left-side-accordion-title {
  cursor: pointer;
  font: 16px Poppins, sans-serif;
  font-weight: 600;
  color: $primary-color; // $title-secondary;
  padding-left: 8px;
  position: relative;
  &:before {
    content: "";
    width: 10px;
    height: 10px;
    background: url("../images/expand_green.svg") center center no-repeat;
    position: absolute;
    left: -14px;
    top: 0;
    bottom: 0;
    margin: auto;
    @extend .transition;
    transform-style: preserve-3d;
  }
  &.open {
    &:before {
      transform: rotateX(-180deg);
    }
  }
}

.right-side-accordion-title {
  cursor: pointer;
  // font: 16px Poppins, sans-serif;
  // font-weight: 600;
  // color: $primary-color; // $title-secondary;
  // padding-left: 8px;
  position: relative;
  &:after {
    content: "";
    width: 10px;
    height: 10px;
    background: url("../images/expand_green.svg") center center no-repeat;
    position: absolute;
    right: -16px;
    top: -5px;
    bottom: 0;
    margin: auto;
    @extend .transition;
    transform-style: preserve-3d;
  }
  &.open {
    &:after {
      transform: rotateX(-180deg);
    }
  }
}

.table-details-row {
  background-color: #fcfdfe; // #feffed;
  td {
    background-color: #fcfdfe; // #f7faff; // #fcfcf0;
  }
}
