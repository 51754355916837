.text-wrapper {
  padding: 10% 0% 0% 5%;
  p {
    font-size: 20px;
    text-align: center;
    color: #412894;
    font-family: "Poppins", sans-serif;
    font-weight: normal;

    @media all and (max-width: 767px) {
      font-size: 12px;
      line-height: 18px;
      color: #535353;
      text-align: justify;
    }
  }
  @media all and (max-width: 767px) {
    padding: 0px;
  }
}

.secondary-error-msg {
  &.message {
    padding-top: 8px !important;
    text-align: center;
    width: 100%;
    display: block;
  }
}
