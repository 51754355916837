.hospital-manage-settings,
.admin-billing-info,
.admin-manage-users,
.admin-manage-dvm,
.ask-auto-confirm {
  .settings-menus-container {
    .settings-menus {
      padding: 16px 32px;
      padding-right: 0;
      border-top: 1px solid $lightestGray;
      cursor: pointer;
    }
    &:last-child {
      border-bottom: 1px solid $lightestGray;
    }
  }

  .card-box {
    width: 311px;
    padding: 16px;
    margin-right: 12px;
    margin-bottom: 8px;
    background: white;
    color: $dark-gray;
    box-shadow: 0px -2px 16px rgba(86, 86, 86, 0.25);
    border-radius: 8px;
  }

  .credit-card {
    @extend .card-box;
    height: 176px;
  }

  .bank-card {
    @extend .card-box;
    min-height: 122px;
  }

  .add-credit-card {
    padding-top: 16px;
  }

  .user-card {
    box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
    border-radius: 12px;
    width: 280px;
    padding: 8px 16px 8px 8px;
    color: $dark-gray;
    margin-right: 16px;
    margin-top: 16px;
    height: fit-content;
    &.active {
      background: #ffffff;
    }
    &.recommended {
      background: $purple;
    }
    &.disabled {
      background: #ebebeb;
      opacity: 0.7;
    }
    &.vet,
    &.hospital-admin {
      border-left: 11px solid $purple-500;
    }
    &.tech {
      border-left: 11px solid $light-blue;
    }
    &.hospital-user {
      border-left: 11px solid $yellow-500;
    }

    .user-status {
      @extend .small-body-text;
      color: $dark-gray;
      &:before {
        content: "";
        height: 8px;
        width: 8px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 4px;
      }
      &.active {
        &:before {
          background: $green-300;
        }
      }
      &.invited {
        &:before {
          background: $gray-200;
        }
      }
    }
  }

  .form-group {
    .form-control {
      padding: 12px 8px !important;
      border: 1px solid $light-blue !important;
      border-radius: 4px !important;
      background: #ffffff !important;
      height: 48px !important;
      color: $dark-gray !important;
      box-shadow: none !important;
      &:disabled {
        background: #ebebeb !important;
      }
    }
  }

  #manageUsers .modal-dialog {
    max-width: 500px !important;
  }
}

.admin-manage-dvm {
  .field-wrapper {
    padding: 24px 28px 40px 28px;
    border-bottom: 1px solid #d6d6d6;
  }
}
