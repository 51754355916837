.flex-container {
  display: flex;
  justify-content: space-between;
}
.flex-section-one-desktop {
  width: 60%;
}
.flex-section-two-desktop {
  width: 30%;
}
.flex-section-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.mobile-breakdown-container {
  margin: 10px;
  width: 90%;
}
.progressbars-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 80px 0px;
}
.progressbar-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
  justify-content: space-between;
  h5 {
    font-size: 18px;
    font-weight: 400;
    color: $label-primary;
    padding-bottom: 10px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 10px;
  }
}
.progressbar-circle {
  width: 50%;
  bottom: 0;
}
.shift-breakdown-container-desktop {
  min-width: 350px;
  background-color: #ffffff;
  box-shadow: 0px 2px 15px rgba(65, 40, 148, 0.1);
  border-radius: 15px;
}
.shift-breakdown-container-mobile {
  min-width: 300px;
  background-color: #ffffff;
  box-shadow: 0px 2px 15px rgba(65, 40, 148, 0.1);
  border-radius: 15px;
  margin-bottom: 20px;
}
.shift-breakdown-wrapper {
  border-bottom: 1px solid black;
  margin: 24px 24px 10px 24px;
}
.shift-breakdown-card {
  display: flex;
}
.shift-breakdown-num-box {
  width: 60px;
}
.shift-breakdown-numbers-positive {
  font-size: 16px;
  margin-right: 60px;
  color: $label-primary;
}
.shift-breakdown-numbers-negative {
  font-size: 16px;
  margin-right: 60px;
  color: $expiredShift;
}
.shift-breakdown-numbers-total {
  width: 75px;
  padding-left: 24px;
  font-size: 16px;
  font-weight: 600px;
  color: $title-primary;
}
.shift-type {
  color: #949494;
  font-size: 16px;
}
.shift-type-total {
  margin-left: 25px;
  font-size: 16px;
  font-weight: 600px;
  color: $title-primary;
}
.page-title {
  font-size: 42px;
  font-weight: 500;
  color: $title-primary;
  padding-bottom: 24px;
}
.main-title-text {
  font-size: 30px;
  font-weight: 400;
}
.main-title-text-mobile {
  font-size: 30px;
  font-weight: 400;
}
.improve-rating-list {
  padding: 24px 0px; 
  display: flex;
  flex-direction: column;
  gap: 10px;
  h6 {
    font-size: 16px;
    margin: 0px;
    padding: 0px;
    font-weight: 400;
  }
}
.rating-comments-primary {
  width: 100%;
  padding: 12px;
  background-color: none;
  h5 {
    font-size: 14px;
    font-weight: 400;
  }
  p {
    font-size: 14px;
    font-weight: 400;
  }
}
.rating-comments-secondary {
  width: 100%;
  padding: 12px;
  background-color: $accent-color;
  h5 {
    font-size: 14px;
    font-weight: 400;
  }
  p {
    font-size: 14px;
    font-weight: 400;
  }
}
