/* to prevent zooming on mobile ios */
input[type="text"]:focus,
textarea:focus {
  font-size: 16px !important;
}

.form-element-border {
  border: 2px solid #e8eaf7;
  color: $text-secondary;

  &.is-invalid-input {
    border-color: var(--redBase);
  }
}

.form-title {
  font-size: 28px;
  line-height: 46px;
  margin-bottom: 15px;
  color: $title-primary;
  font-weight: normal;
  @media screen and (max-width: 991px) {
    font-size: 20px;
    line-height: 28px;
  }
}

.form-title-registration {
  font-weight: 700;
  font-size: 56px;
  line-height: 84px;
  margin-bottom: 16px;
  color: $title-primary;
  // white-space: nowrap;
  &.tech {
    font-weight: 600;
    font-size: 36px;
    line-height: 52px;
    margin-bottom: 8px;
    @media (max-width: 991px) {
      font-size: 28px;
      line-height: 40px;
    }
    @media (max-width: 480px) {
      margin-top: 20px;
    }
  }
}

.form-secondary-title-registration {
  color: #59595a;
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  text-align: center;
  &.tech {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: "Poppins", sans-serif;
    text-align: left;
    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.form-secondary-title {
  color: $para-primary;
  font-size: 22px;
  font-weight: 500;
  line-height: 37px;
  text-align: center;
  @media screen and (max-width: 991px) {
    font-size: 16px;
    line-height: 24px;
  }
  &.sm-title {
    color: $para-primary;
    font-size: 18px;
  }
  &.m-title {
    font-size: 20px;
  }
}

.form-super-label {
  font-size: 20px;
  margin-bottom: 12px;
}

.confirm_save_hide {
  opacity: 0;
  transition: all 1.1s ease;
}

.confirm_save_show {
  opacity: 1;
  transition: all 0.2s ease;
}

.form-label {
  color: $label-primary;
  @extend .primaryFont;
  font-size: 16px;
  letter-spacing: 0.75px;
  line-height: 26px;
  margin-bottom: 0;
  font-weight: 300;
}

.form-label-v2 {
  @extend .tertiaryFont;
  color: $dark-gray;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0;
}

.registration-form-label {
  font-family: "Jost", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #412894;
  @media (max-width: 991px) {
    font-size: 12px;
    line-height: 20px;
  }
}

.form-label-note {
  color: $text-secondary;
  @extend .secondaryFont;
  font-size: 14px;
  line-height: 22px;
}

.registration-form-label-note {
  color: #412894;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  @media (max-width: 991px) {
    font-size: 8px;
    line-height: 12px;
  }
}

.form-well-shadow {
  background-color: $white;
  box-shadow: 0 2px 15px 0 rgba(65, 40, 148, 0.22);
}

html {
  body {
    input.form-control::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $para-secondary;
    }
    input.form-control::-moz-placeholder {
      /* Firefox 19+ */
      color: $para-secondary;
    }
    input.form-control:-ms-input-placeholder {
      /* IE 10+ */
      color: $para-secondary;
    }
    input.form-control:-moz-placeholder {
      /* Firefox 18- */
      color: $para-secondary;
    }
  }
}

#root,
body {
  .form-group {
    .form-control.StripeElement {
      & > div {
        top: 12px;
      }
      @media (max-width: 767px) {
        & > div {
          top: 5px;
        }
      }
    }
  }
  .custom-check {
    .input-row-cell-wrapper {
      position: relative;
      top: -18px;
    }
    .input-row-label-wrapper {
      height: 48px;
      font-size: 14px;
      color: $text-secondary;
      line-height: 18px;
    }
    padding: 0;
    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked),
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="radio"][disabled] + label {
      cursor: not-allowed !important;
    }
    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label,
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 40px;
      cursor: pointer;
      // line-height: 20px;
      display: inline-block;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
      font-weight: normal;
      color: $text-secondary;
      font-family: "Poppins", sans-serif;
      @extend .secondaryFont;
    }
    [type="checkbox"]:checked + label a,
    [type="checkbox"]:not(:checked) + label a,
    [type="radio"]:checked + label a,
    [type="radio"]:not(:checked) + label a {
      color: $primary-color;
      font-weight: 600;
      @extend .primaryFont;
    }
    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:before,
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 28px;
      height: 28px;
      @extend .form-element-border;
      background: $white;
      border: 1px solid $checkbox-radio-light;
    }
    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:after,
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 18px;
      height: 18px;
      background: $checkbox-radio-light;
      position: absolute;
      top: 5px;
      left: 5px;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      border-radius: 1px;
    }
    [type="checkbox"]:not(:checked) + label:after,
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="checkbox"]:checked + label:after,
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before,
    [type="radio"]:checked + label:after {
      border-radius: 20px;
    }
    [type="radio"]:disabled + label:before,
    [type="radio"]:disabled + label:after,
    [type="checkbox"]:disabled + label:before,
    [type="checkbox"]:disabled + label:after {
      cursor: default;
      opacity: 0.5;
    }
    &.radio-image {
      & > div[class^="col-"],
      div[class*=" col-"] {
        label {
          display: flex;
          padding: 0;
          width: 100%;
          margin: 30px 0;
          border-radius: 10px;
          height: 160px;
          background-color: rgba(255, 255, 255, 0.63);
          box-shadow: 0 2px 6px 1px rgba(197, 192, 212, 0.19);
          & > div {
            overflow: hidden;
            display: flex;
            & > div {
              padding: 24px 30px;
              h6 {
                @extend .primaryFont;
                @extend .ternary-title;
                font-weight: normal;
                color: $checkbox-radio;
                margin: 0 0 8px;
                letter-spacing: normal;
              }
              p {
                color: $para-primary;
                font-size: 16px;
                line-height: 22px;
                @extend .secondaryFont;
                margin: 0;
                letter-spacing: normal;
              }
            }
            img {
              width: 98px;
            }
          }
        }
        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
          right: 0;
          margin: auto;
          top: -40px;
          width: 32px;
          height: 32px;
        }
        [type="radio"]:checked + label {
          box-shadow: 0 0 0px 2px #5862a3;
        }
        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after {
          right: 0;
          margin: auto;
          top: -31px;
          left: 0;
          height: 14px;
          width: 14px;
        }
      }
      &.radio-image-required {
        div[class^="col-"],
        div[class*=" col-"] {
          label {
            box-shadow: 0 2px 6px 1px rgba(255, 123, 123, 0.45);
          }
          [type="radio"]:not(:checked) + label:before {
            border-color: #ff7a7a;
          }
        }
      }
    }
    &.open-sans {
      [type="checkbox"]:checked + label,
      [type="checkbox"]:not(:checked) + label,
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label,
      [type="checkbox"]:checked + label a,
      [type="checkbox"]:not(:checked) + label a,
      [type="radio"]:checked + label a,
      [type="radio"]:not(:checked) + label a {
        @extend .secondaryFont;
        font-size: 16px;
        line-height: 28px;
      }
    }
  }

  .custom-check-s {
    .input-row-cell-wrapper {
      position: relative;
      top: -18px;
    }
    .input-row-label-wrapper {
      height: 48px;
      font-size: 14px;
      color: $text-secondary;
      line-height: 18px;
    }
    padding: 0;
    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked),
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label,
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 40px;
      cursor: pointer;
      // line-height: 20px;
      display: inline-block;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px !important;
      font-weight: normal;
      color: $text-secondary !important;
      @extend .secondaryFont;
    }
    [type="checkbox"]:checked + label a,
    [type="checkbox"]:not(:checked) + label a,
    [type="radio"]:checked + label a,
    [type="radio"]:not(:checked) + label a {
      color: $primary-color;
      font-weight: 600;
      @extend .primaryFont;
    }
    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:before,
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 19px;
      height: 19px;
      @extend .form-element-border;
      background: $white;
      border: 1px solid $checkbox-radio-light;
    }
    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:after,
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 13px;
      height: 13px;
      background: $checkbox-radio-light;
      position: absolute;
      top: 3px;
      left: 3px;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      border-radius: 1px;
    }
    [type="checkbox"]:not(:checked) + label:after,
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="checkbox"]:checked + label:after,
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before,
    [type="radio"]:checked + label:after {
      border-radius: 20px;
    }
    [type="radio"]:disabled + label:before,
    [type="radio"]:disabled + label:after {
      cursor: default;
      opacity: 0.5;
    }
    &.radio-image {
      & > div[class^="col-"],
      div[class*=" col-"] {
        label {
          display: flex;
          padding: 0;
          width: 100%;
          margin: 30px 0;
          border-radius: 10px;
          height: 160px;
          background-color: rgba(255, 255, 255, 0.63);
          box-shadow: 0 2px 6px 1px rgba(197, 192, 212, 0.19);
          & > div {
            overflow: hidden;
            display: flex;
            & > div {
              padding: 24px 30px;
              h6 {
                @extend .primaryFont;
                @extend .ternary-title;
                font-weight: normal;
                color: $checkbox-radio;
                margin: 0 0 8px;
                letter-spacing: normal;
              }
              p {
                color: $para-primary;
                font-size: 16px;
                line-height: 22px;
                @extend .secondaryFont;
                margin: 0;
                letter-spacing: normal;
              }
            }
            img {
              width: 98px;
            }
          }
        }
        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
          right: 0;
          margin: auto;
          top: -40px;
          width: 32px;
          height: 32px;
        }
        [type="radio"]:checked + label {
          box-shadow: 0 0 0px 2px #5862a3;
        }
        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after {
          right: 0;
          margin: auto;
          top: -31px;
          left: 0;
          height: 13px;
          width: 13px;
        }
      }
      &.radio-image-required {
        div[class^="col-"],
        div[class*=" col-"] {
          label {
            box-shadow: 0 2px 6px 1px rgba(255, 123, 123, 0.45);
          }
          [type="radio"]:not(:checked) + label:before {
            border-color: #ff7a7a;
          }
        }
      }
    }
    &.open-sans {
      [type="checkbox"]:checked + label,
      [type="checkbox"]:not(:checked) + label,
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label,
      [type="checkbox"]:checked + label a,
      [type="checkbox"]:not(:checked) + label a,
      [type="radio"]:checked + label a,
      [type="radio"]:not(:checked) + label a {
        @extend .secondaryFont;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  .small-check-label {
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #323232 !important;
    @media (max-width: 991px) {
      font-size: 12px !important;
    }
  }
  .small-check {
    .input-row-cell-wrapper {
      position: relative;
      top: -18px;
    }
    .input-row-label-wrapper {
      height: 48px;
      font-size: 14px;
      color: $text-secondary;
      line-height: 18px;
    }
    padding: 0;
    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked),
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label,
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 40px;
      cursor: pointer;
      // line-height: 20px;
      display: inline-block;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
      font-weight: normal;
      color: $text-secondary;
      @extend .secondaryFont;
    }
    [type="checkbox"]:checked + label a,
    [type="checkbox"]:not(:checked) + label a,
    [type="radio"]:checked + label a,
    [type="radio"]:not(:checked) + label a {
      color: $primary-color;
      font-weight: 600;
      @extend .primaryFont;
    }
    &.blue {
      [type="checkbox"]:checked + label:before,
      [type="checkbox"]:not(:checked) + label:before,
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        border: 1px solid #3747ab;
        left: 15px;
        top: 7px;
      }
    }
    &.experience-pg {
      [type="checkbox"]:checked + label:before,
      [type="checkbox"]:not(:checked) + label:before,
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        left: 0px;
        top: 6px;
      }
    }
    [type="checkbox"]:checked + label:before,
    [type="checkbox"]:not(:checked) + label:before,
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 17px;
      height: 17px;
      @extend .form-element-border;
      background: $white;
      border: 1px solid $checkbox-radio-light;
    }
    [type="checkbox"]:checked + label:after,
    [type="checkbox"]:not(:checked) + label:after,
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 13px;
      height: 13px;
      background: $checkbox-radio-light;
      position: absolute;
      top: 2px;
      left: 2px;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      border-radius: 1px;
    }
    &.blue {
      [type="checkbox"]:checked + label:after,
      [type="checkbox"]:not(:checked) + label:after,
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        left: 17px;
        top: 9px;
      }
    }
    &.experience-pg {
      [type="checkbox"]:checked + label:after,
      [type="checkbox"]:not(:checked) + label:after,
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        left: 2px;
        top: 8px;
      }
    }
    [type="checkbox"]:not(:checked) + label:after,
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="checkbox"]:checked + label:after,
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before,
    [type="radio"]:checked + label:after {
      border-radius: 20px;
    }
    [type="radio"]:disabled + label:before,
    [type="radio"]:disabled + label:after {
      cursor: default;
      opacity: 0.5;
    }
    &.radio-image {
      & > div[class^="col-"],
      div[class*=" col-"] {
        label {
          display: flex;
          padding: 0;
          width: 100%;
          margin: 30px 0;
          border-radius: 10px;
          height: 160px;
          background-color: rgba(255, 255, 255, 0.63);
          box-shadow: 0 2px 6px 1px rgba(197, 192, 212, 0.19);
          & > div {
            overflow: hidden;
            display: flex;
            & > div {
              padding: 24px 30px;
              h6 {
                @extend .primaryFont;
                @extend .ternary-title;
                font-weight: normal;
                color: $checkbox-radio;
                margin: 0 0 8px;
                letter-spacing: normal;
              }
              p {
                color: $para-primary;
                font-size: 16px;
                line-height: 22px;
                @extend .secondaryFont;
                margin: 0;
                letter-spacing: normal;
              }
            }
            img {
              width: 98px;
            }
          }
        }
        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
          right: 0;
          margin: auto;
          top: -40px;
          width: 32px;
          height: 32px;
        }
        [type="radio"]:checked + label {
          box-shadow: 0 0 0px 2px #5862a3;
        }
        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after {
          right: 0;
          margin: auto;
          top: -31px;
          left: 0;
          height: 14px;
          width: 14px;
        }
      }
      &.radio-image-required {
        div[class^="col-"],
        div[class*=" col-"] {
          label {
            box-shadow: 0 2px 6px 1px rgba(255, 123, 123, 0.45);
          }
          [type="radio"]:not(:checked) + label:before {
            border-color: #ff7a7a;
          }
        }
      }
    }
    &.open-sans {
      [type="checkbox"]:checked + label,
      [type="checkbox"]:not(:checked) + label,
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label,
      [type="checkbox"]:checked + label a,
      [type="checkbox"]:not(:checked) + label a,
      [type="radio"]:checked + label a,
      [type="radio"]:not(:checked) + label a {
        @extend .secondaryFont;
        font-size: 16px;
        line-height: 28px;
      }
    }
  }

  .custom-check.wrap-tight {
    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label {
      padding-top: 4px;
      line-height: 22px;
    }
  }

  @mixin rating-dynamic($chk-bg-color, $color, $font-family, $font-size) {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: hidden;
    li {
      width: 44px;
      height: 44px;
      float: left;
      position: relative;
      @extend .form-element-border;
      background: $white;
      box-shadow: inset 1px 1px 7px 0 rgba(232, 234, 247, 0.05);
      [type="checkbox"]:checked,
      [type="checkbox"]:not(:checked),
      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
      }
      [type="checkbox"]:checked + label,
      [type="checkbox"]:not(:checked) + label,
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label,
      [type="checkbox"]:checked + label:after,
      [type="checkbox"]:not(:checked) + label:after,
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: "";
        cursor: pointer;
        -webkit-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
      }
      [type="checkbox"]:checked + label,
      [type="checkbox"]:not(:checked) + label,
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        i {
          color: $color;
          position: relative;
          z-index: 1;
          display: block;
          line-height: 40px;
          text-align: center;
          font-style: normal;
          font-family: $font-family;
          font-size: $font-size;
        }
      }
      [type="checkbox"]:checked + label:after,
      [type="checkbox"]:not(:checked) + label:after,
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        background: $chk-bg-color;
        opacity: 0;
      }
      [type="checkbox"]:checked + label:after,
      [type="radio"]:checked + label:after,
      [type="checkbox"]:checked + label i,
      [type="radio"]:checked + label i {
        opacity: 1;
        color: $white;
      }
    }
  }
  .ratings {
    @include rating-dynamic($checkbox-radio-light, $text-secondary, "Open Sans", "inherit");
  }
  .ratings-v2 {
    @include rating-dynamic($blue-dark, $dark-gray, "Jost", 16px);
  }
  .select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
  }
  .select-disabled-dim {
    .Select.is-disabled {
      .Select-control {
        color: #535353;
        background: #e1e5f0;
      }
    }
  }
  .Select {
    &.sm {
      .Select-control {
        height: 40px;
        .Select-value {
          .Select-value-label {
            line-height: 35px;
          }
        }
        .Select-multi-value-wrapper {
          height: 35px;
        }
        .Select-placeholder {
          line-height: 37px;
        }
        .Select-arrow-zone:after {
          top: 10px;
        }
      }
    }
    &.is-focused:not(.is-open) > .Select-control {
      box-shadow: none;
    }
    .Select-control {
      transition: all 0.2s ease-in;
      @extend .form-element-border;
      background: $white;
      font-size: 16px;
      color: $text-secondary;
      width: 100%;
      height: 55px;
      &:hover {
        box-shadow: none;
      }
      .Select-multi-value-wrapper {
        height: 50px;
      }
      .Select-placeholder {
        line-height: 52px;
        padding-left: 12px;
        padding-right: 12px;
      }
      .Select-arrow-zone {
        position: relative;
        width: 34px;
        &:after {
          content: "";
          width: 26px;
          height: 16px;
          background: url("../images/dropdown_arrow.svg") no-repeat center center;
          position: absolute;
          top: 17px;
          right: 10px;
          @extend .transition;
          transform-style: preserve-3d;
        }
        .Select-arrow {
          display: none;
        }
      }
      .Select-clear-zone {
        display: none;
      }
      .Select-input {
        & > input {
          line-height: 31px;
        }
      }
      .Select-value {
        .Select-value-label {
          @extend .secondaryFont;
          line-height: 44px;
          color: $text-secondary;
        }
      }
    }
    &.is-open {
      .Select-control {
        border-color: #a9b0db;
        .Select-arrow-zone {
          &:after {
            transform: rotateX(-180deg);
          }
        }
      }
      .Select-menu-outer {
        margin-top: -2px;
        border: 2px solid #a9b0db;
        overflow: hidden;
        right: 0;
        left: 0;
        width: calc(100% - 1px);
        margin-right: 0;
        z-index: 9;
        .Select-option {
          @extend .secondaryFont;
          font-size: 16px;
          margin: 0;
          padding: 6px 15px;
          color: $text-secondary;
          transition: all 0.15s ease-in;
          &.is-selected,
          &.is-focused {
            background: $title-secondary-lightest;
            color: $text-secondary;
          }
          &:hover {
            background: $title-secondary-lightest;
          }
        }
      }
    }
  }
  .Select--multi {
    .Select-control {
      .Select-multi-value-wrapper {
        height: auto;
        padding: 0 0 8px;
        min-height: 51px;
        .Select-value {
          background: #edeef2;
          border-radius: 3px;
          border: #edeef2;
          margin: 9px 0 0 8px;
          .Select-value-icon {
            position: relative;
            top: 3px;
            border: 0;
            font-size: 20px;
            font-weight: normal;
            padding: 0 8px 0 0;
            color: #585858;
            &:hover,
            &:focus {
              background: transparent;
              opacity: 0.8;
            }
          }
          .Select-value-label {
            min-height: 33px;
            line-height: 29px;
            // color: #535353;
            font-size: 16px;
            padding: 2px 10px;
            float: left;
            @extend .secondaryFont;
            color: green;
          }
        }
      }
    }
  }
  .range-slider-wrapper {
    padding: 16px 116px;
    position: relative;
    height: 40px;
    margin-top: 16px;
    & > span {
      position: absolute;
      top: 7px;
      color: $text-secondary;
      font-size: 16px;
    }
    & > div.label {
      display: inline-block;
      // text-align: center;
      position: absolute;
      top: 7px;
      color: $text-secondary;
      font-size: 16px;
    }
    .lft-label {
      left: 0;
    }
    .rgt-label {
      right: 0;
    }
    .slider-down-label {
      position: absolute;
      top: 35px;
      color: black;
    }
  }
  .rc-slider {
    height: 2px;
    box-shadow: none;
    position: relative;
    margin: auto;
    width: 95%;
    &:after,
    &:before {
      content: "";
      height: 20px;
      width: 2px;
      background: #d9d7d7;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
    .rc-slider-fill {
      //background:#007a4c;
      background: transparent;
      box-shadow: none;
    }
    .rc-slider-track {
      background: #d9d7d7;
      z-index: 0;
    }
    .rc-slider-handle {
      top: 0;
      height: 34px;
      width: 34px;
      background: $green-dark;
      box-shadow: 0px 2px 5px rgba(134, 134, 134, 0.375);
      border: 0;
      &:after {
        display: none;
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
  .select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 16px;
    color: $text-secondary;
    width: 100%;
    height: 55px;
  }
  .select-styled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    padding: 12px;
    line-height: 30px;
    transition: all 0.2s ease-in;
    @extend .form-element-border;
    &:after {
      content: "";
      width: 26px;
      height: 16px;
      background: url("../images/dropdown_arrow.svg") no-repeat center center;
      position: absolute;
      top: 16px;
      right: 10px;
      @extend .transition;
      transform-style: preserve-3d;
    }
    &:hover {
      //background: $title-secondary-lightest;
    }
    &:active,
    &.active {
      background: $white;
      border: 2px solid #a9b0db;
      &:after {
        transform: rotateX(-180deg);
      }
    }
  }
  .select-options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    margin-top: -2px;
    padding: 0;
    list-style: none;
    background: $white;
    border: 2px solid #a9b0db;
    max-height: 135px;
    overflow: auto;
    &.at-bottom {
      top: auto;
      bottom: 100%;
      margin-bottom: -2px;
    }
    li {
      @extend .secondaryFont;
      font-size: 16px;
      margin: 0;
      padding: 10px 15px;
      transition: all 0.15s ease-in;
      &:hover {
        background: $title-secondary-lightest;
      }
      &[rel="hide"] {
        display: none;
      }
    }
  }
  .rank-wrapper {
    @extend .secondaryFont;
    color: $text-secondary;
    font-size: 16px;
    line-height: 22px;
  }
  .ratings-wrapper {
    &.profile-ratings {
      padding: 0 100px;
      position: relative;
      & > span {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 90px;
        display: flex;
        align-items: center;
        @extend .secondaryFont;
        color: $text-secondary;
        font-size: 16px;
        line-height: 22px;
        &.lft-lable {
          left: 0;
        }
        &.rht-lable {
          right: 0;
        }
      }
      .ratings {
        display: flex;
        justify-content: center;
        li {
          margin: 0 10px;
        }
      }
      &.edit-profile {
        padding: 0 74px;
        & > span {
          width: 74px;
        }
        .ratings {
          li {
            margin: 0 8px;
          }
        }
      }
    }
  }
  .aaha-logo {
    .custom-check {
      position: relative;
      .message {
        top: 5px;
      }
    }
  }
  .ratings-modal {
    .top-right {
      position: absolute;
      top: 30px;
    }
    & > h3 {
      font-size: 24px;
      line-height: 40px;
      font-weight: normal;
      color: $title-primary;
      margin-bottom: 10px;
    }
    & > h6 {
      font-size: 18px;
      line-height: 40px;
      font-weight: normal;
      color: $title-primary;
      margin-bottom: 10px;
    }
    & > p {
      color: $text-secondary;
      line-height: 30px;
      strong {
        font-weight: 600;
      }
      span {
        color: #412894;
        font-weight: 600px;
      }
    }
    .ratings-wrapper {
      padding: 0 0 34px;
      .ratings {
        justify-content: left;
        overflow: visible;
        li {
          width: 52px;
          height: 52px;
          margin: 0 20px;
          position: relative;
          &:first-child {
            margin-left: 0;
          }
          label {
            i {
              line-height: 50px;
            }
          }
        }
        .box-label {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          margin-top: 10px;
          color: $text-secondary;
          @extend .secondaryFont;
        }
      }
    }
    .profile-avatar {
      height: 60px;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
  .horizontal-form {
    & .data-list > .row {
      padding-right: 80px;
      position: relative;
      .specialty-label {
        color: #949494;
        font-size: 16px;
        line-height: 26px;
        position: relative;
        display: block;
        top: 16px;
      }
      .delete-row {
        position: absolute;
        top: 0;
        right: 14px;
        bottom: 0;
        margin: auto;
        height: 20px;
        width: 20px;
        cursor: pointer;
        background: url("../images/close-red.png") right center no-repeat;
        background-size: 100%;
        @include transition;
        &:hover {
          opacity: 0.8;
          @include transition;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #root,
  body {
    .custom-check {
      [type="checkbox"]:checked + label,
      [type="checkbox"]:not(:checked) + label,
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        font-size: 14px;
        margin-bottom: 8px;
      }
      &.reg-check-mobile {
        [type="checkbox"]:checked + label,
        [type="checkbox"]:not(:checked) + label,
        [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label {
          font-family: "Poppins", sans-serif;
          letter-spacing: 0px;
          font-size: 12px;
          .br-200 {
            line-height: 18px;
            width: 200px;
          }
        }
      }
      &.open-sans {
        [type="checkbox"]:checked + label,
        [type="checkbox"]:not(:checked) + label,
        [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label,
        [type="checkbox"]:checked + label a,
        [type="checkbox"]:not(:checked) + label a,
        [type="radio"]:checked + label a,
        [type="radio"]:not(:checked) + label a {
          font-size: 14px;
        }
      }
      &.sm-check {
        padding: 0 15px;
        div[class^="col-"],
        div[class*=" col-"] {
          padding: 0 0 0 30px;
          float: left;
          width: auto;
          &:first-child {
            padding: 0;
          }
        }
      }
      &.radio-image {
        margin-bottom: 20px;
        & > div[class^="col-"],
        div[class*=" col-"] {
          label {
            width: calc(100% - 50px);
            height: 94px;
            margin: 8px 0 8px 50px;
            & > div {
              & > div {
                padding: 12px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                h6 {
                  font-size: 16px;
                  line-height: 26px;
                  margin: 0;
                }
                p {
                  font-size: 14px;
                  line-height: 19px;
                }
              }
              img {
                width: 68px;
              }
            }
          }
          [type="radio"]:checked + label:before,
          [type="radio"]:not(:checked) + label:before {
            right: auto;
            left: -46px;
            margin: auto;
            top: 0;
            bottom: 0;
            width: 32px;
            height: 32px;
          }
          [type="radio"]:checked + label:after,
          [type="radio"]:not(:checked) + label:after {
            right: auto;
            margin: auto;
            top: 0;
            left: -37px;
            bottom: 0;
          }
        }
      }
    }
    .form-title {
      font-size: 20px;
      line-height: 32px;
    }
    .form-secondary-title {
      font-size: 14px;
      line-height: 23px;
    }
    .form-super-label {
      font-size: 18px;
      margin-bottom: 10px;
    }
    .default-form .form-group .form-control,
    form .form-group .form-control {
      height: 40px;
    }
    .default-form,
    form {
      .form-group,
      .form-group-sm-mb {
        margin-bottom: 24px;
        label {
          font-size: 14px;
          line-height: 24px;
        }
      }
      .btn-2-group {
        div[class^="col-"],
        div[class*=" col-"] {
          &:first-child {
            padding-right: 10px;
          }
          &:last-child {
            padding-left: 10px;
          }
        }
      }
    }
    .select {
      height: 40px;
      .select-styled:after {
        width: 16px;
        top: 9px;
      }
    }
    .inner-container {
      .form-steps-line {
        width: 180px;
        margin: 0 auto 24px;
        i {
          top: 8px;
          height: 12px;
          width: 12px;
          &:nth-child(2) {
            left: 55px;
          }
          &:nth-child(3) {
            left: 112px;
          }
        }
        &.sm-steps {
          width: 120px;
        }
      }
      .horizontal-form .data-list > .row {
        padding-right: 10px;
        margin-top: 30px;
        border-top: 1px solid #eaeaea;
        margin-bottom: 30px;
        padding-top: 30px;
        &.sm-row {
          padding: 30px 20px 0 0;
          margin-top: 30px;

          &:first-child {
            border: 0;
            padding-top: 0;
          }
        }
        .specialty-label {
          top: 0;
        }
        .delete-row {
          //top:10px;
          right: 0;
          //bottom:auto;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  #root,
  body {
    .custom-check {
      .input-row-cell-wrapper {
        position: relative;
        top: -30px;
      }
      .input-row-label-wrapper {
        height: 48px;
        font-size: 14px;
        color: $text-secondary;
        line-height: 18px;
      }
    }
  }
}

@media (max-width: 321px) {
  #root,
  body {
    .custom-check {
      &.sm-check {
        padding: 0 15px;
        div[class^="col-"],
        div[class*=" col-"] {
          padding: 0 0 0 10px;
          float: left;
          width: auto;
          &:first-child {
            padding: 0;
          }
        }
      }
    }
  }
}

.photo-upload-text {
  font-size: 16px !important;
  line-height: 26px !important;
}

.registration-form-group {
  position: relative;

  &.onboarding {
    label {
      color: #412894 !important;
    }
  }
  label {
    color: #303031 !important;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }
  &.no-mb {
    input {
      margin-bottom: 0;
    }
    textarea {
      margin-bottom: 0;
    }
  }
  &.sm-mt {
    margin-top: 24px;
  }
  &.small-mb {
    input {
      margin-bottom: 14px;
    }
  }
  input {
    border: 1px solid #dbdffa;
    border-radius: 4px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 42px;
    margin-bottom: 24px;
    padding: 12px 8px;
  }
  textarea {
    border: 1px solid #dbdffa;
    border-radius: 4px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 130px;
    margin-bottom: 24px;
    padding: 12px 8px;
    resize: none;
  }
  input ::placeholder {
    color: #acacad;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }
  .note {
    font-size: 12px;
    color: #949494;
    margin: 0;
    padding-top: 4px;
    @extend .secondaryFont;
    margin-bottom: 24px;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #acacad;
    margin-left: 2px;
  }
  .message {
    left: 0;
  }
}

.registration-dropdown {
  > div {
    border: 1px solid #dbdffa;
    border-radius: 4px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    padding: 0;
    > div {
      width: 100%;
      border: none;
      padding: 0;
      > div {
        > div {
          height: 32px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  &.med {
    > div {
      width: 400px;
      > div {
        > div {
          > div {
            height: 48px;
          }
        }
      }
    }
  }
  &.no-mb {
    > div {
      margin-bottom: 0px;
    }
  }
  @media (max-width: 480px) {
    &.med {
      > div {
        width: 300px;
        > div {
          > div {
            > div {
              height: 48px;
            }
          }
        }
      }
    }
  }
}

.registration-text-s {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #59595a;
  font-family: "Poppins", sans-serif;
  a {
    color: #007a4c !important;
    &:hover,
    &::after {
      opacity: 0.8;
    }
  }
}

.registration-text {
  color: #59595a;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  &.link {
    font-weight: 600;
    color: #007a4c !important;
    text-decoration: underline !important;
  }
  div {
    color: #007a4c;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-decoration: underline;
    margin-left: 8px;
  }
}

.registration-input {
  label {
    font-family: "Jost", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #412894;
    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 20px;
    }
  }
  input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 8px;
    width: 700px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #dbdffa;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  &.small {
    input {
      width: 250px;
    }
  }
  &.mobile {
    input {
      width: 100%;
    }
  }
  &.sm-mb {
    input {
      margin-bottom: 12px;
    }
  }
  &.auto {
    input {
      width: auto;
    }
  }
}

.registration-checkbox-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #59595a;
  @media (max-width: 991px) {
    font-size: 12px !important;
  }
}

.registration-subtitle {
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #412894;
  margin: 24px 0 12px 0;
  @media (max-width: 991px) {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0px;
  }
}

.registration-ul {
  padding-left: 0;
  width: 100%;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  @media (max-width: 991px) {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}

.registration-ul li {
  display: inline-block;
  @media (min-width: 1300px) {
    width: 400px;
  }
}

.connect-title {
  font-family: "Jost", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  color: #412894;
}

.form-label-grey {
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #303031 !important;
}

.small-text-area {
  height: 48px;
  width: 230px;
  background: #ffffff;
  border: 1px solid #dbdffa;
  border-radius: 4px;
  resize: none;
  position: relative;
  top: -20px;
  left: 20px;
  outline: none;
  &.align-right {
    left: 260px;
  }
}

.survey-form {
  div {
    font-family: "Jost", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3747ab;
    margin: 8px 0 2px 0;
  }
  textarea {
    width: 100%;
    height: 114px;
    background: #ffffff;
    border: 1px solid #dbdffa;
    border-radius: 4px;
    resize: none;
    &.text-area-error {
      border-color: #ed4545;
    }
  }
}
