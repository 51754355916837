.rating-title {
  // position: absolute; // width: 253px; // height: 36px; // left: 24px; // top: 32px;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px; /* identical to box height */
  color: #412894;
  margin-bottom: 32px;
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
}
.rating-count {
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  color: #412894 !important;
}
.rating-photo {
  margin-bottom: 28px;
}
.rating-prof-details {
  padding-left: 24px;
}
.rating-img {
  width: 116px;
  height: 116px;
  border-radius: 50%;
  object-fit: cover;
  min-width: 116px;
  min-height: 116px;
  @media (max-width: 767px) {
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
  }
}

.rating-para {
  color: #5862a3;
  @media (max-width: 767px) {
    margin-bottom: 28px !important;
  }
}
.rating-compliment {
  background: #ffffff;
  border: 2px solid #e8eaf7 !important;
  box-sizing: border-box;
  box-shadow: none !important;
  border-radius: 2px;
  min-height: 86px !important;
  font-size: 16px;
  line-height: 22px;
  color: #535353;
}
.rating-note {
  color: $title-primary !important;
  margin-bottom: 16px !important;
}
.rating-span {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #5862a3;
  display: inline-block;
  margin-right: 4px;
}
.rating-textarea-span {
  font-size: 14px;
  line-height: 19px;
  color: #5862a3;
  text-align: right;
  margin-top: 4px;
  display: block;
}

.info-button-sm {
  top: 2px;
  height: 16px;
  width: 16px;
}
.rating-name {
  font-size: 16px;
  line-height: 22px;
  color: #535353;
  padding-left: 13px;
  vertical-align: middle;
  display: inline-block;
  @media (max-width: 767px) {
    padding-left: 0px;
    padding-top: 10px;
    display: block;
  }
}
.rating-not-happen {
  padding-left: 40px !important;
  // @media (max-width: 767px) {
  //   padding-left: 0px;
  //   padding-top: 10px;
  // }
}
