body {
  #root {
    .referral-header {
      .curr-payout {
        margin-bottom: 32px;
        @media all and (min-width: 320px) and (max-width: 767px) {
          font-size: 14px !important;
        }
      }
      .reg-forms {
        padding-bottom: 63px;
      }
    }
    .table-container .referralList-table tbody tr td {
      font-family: Poppins, sans-serif;
    }
  }
}

.ref-secondary-title {
  font-size: 18px;
  line-height: 30px;
  color: #535353;
  margin-bottom: 63px;
}

.text-with-icon {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #5862a3;
  margin-bottom: 32px;
  img {
    display: inline-block;
    margin-right: 15px;
  }
}

.ref-label-link {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #5862a3;
  display: block;
  margin-bottom: 0px;
}

.ref-input {
  background: rgba(148, 148, 148, 0.099035);
  border: 2px solid #e8eaf7;
  box-sizing: border-box;
  box-shadow: inset 1px 1px 7px rgba(232, 234, 247, 0.659307);
  border-radius: 2px 0 0 2px;
  height: 55px;
  padding: 16px 15px;
}

.invite-message {
  font-size: 16px;
  line-height: 22px;
  color: #535353;
  margin: 24px 0 32px;
}

.ref-td-checkbox {
  margin-top: -20px;
}

.no-ref-text {
  margin-top: 60px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #535353;
  @media screen and (min-width: 768px) {
    max-width: 500px;
    margin: auto;
    padding: 0 2.5px;
  }
}

.ref-td-text {
  font-family: Poppins, sans-serif;
}

.referralList-table {
  thead th {
    border-top: 2px solid #dddddd !important;
  }
}

.text-referral-box {
  color: #535353;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  max-width: 100px;
  line-height: normal;
  &.text-invited-box {
    background-color: #ffc107;
  }
  &.text-signedup-box {
    background-color: #17a2b8;
  }
  &.text-paid-box {
    background-color: #007a4c;
  }
  &.text-shiftfree-box {
    background-color: #a2ecd0;
    color: #226049;
    max-width: 122px;
  }
  &.text-shiftnotfree-box {
    background-color: #b9c1ec;
    max-width: 136px;
  }
}

.green-info-container {
  background-color: #007a4c;
  color: white;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
