.notification-page-content {
    display: flex;
    justify-content: space-between;
    h1 {
        font-family: 'Poppins', sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        margin: 0 !important;
    }

    @media screen and (max-width: 900px) {
        h1 {
            font-size: 28px;
            text-align: center;
            width: 100%;
        }
    }
}
.accordition-text {
    font-weight: 500;
    font-family: 'Jost', sans-serif;
    @media screen and (max-width: 900px) {
        font-size: 24px !important;

    }
}

.new-shift-title {
    margin-left: 1.5rem;
    color:  #303031;
    font-family: 'Jost', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    @media screen and (max-width: 900px) {
        margin-left: 0;
        font-size: 24px;
    }
}

.noti-divider {
    background-color: #D6D6D6;
    height: 1px;
    width: 73%;
    min-width: 550px;
    margin-left: 1.5rem;
    margin-bottom: 24px;
    @media screen and (max-width: 900px) {
        margin-left: 0;
        width: 100%;
    }
}

.shift-pref-heading {
    margin-left: 1.5rem;
    font-family: 'Jost', sans-serif !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400 !important;
    color: #303031;
    @media screen and (max-width: 900px) {
        margin-left: 0;
        font-size: 20px !important;
    }
}

.ant-tooltip-inner {
    font-family: 'Poppins', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.side-nav-item-wrapper {
    height: 47px;
    
    h2 {
        font-family: 'Jost', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
    }
}

.shift-pref-content {
    padding: 0px 0px 28px 1.5rem;
    margin-bottom: 24px;
    border-bottom: 1px solid  #D6D6D6;
    @media screen and (max-width: 900px) {
        padding: 1.5rem 0;
        p {
            margin-left: 0 !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
        }
    }
    
}
.notification-row-wrapper {
    .notification-title{
        margin-bottom: 10px;
        display: flex;
        justify-content: start;
        gap: 10px;
    }

    padding-left: 1.5rem;
    padding-bottom: 1rem;
  

    h3 {
        color:  #303031;
        font-family: 'Jost', sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        margin: 0;

        @media screen and (max-width: 900px) {
            font-size: 20px;
        }
    }

    p {
        margin-top: 8px;
        font-family: 'Poppins', sans-serif;
    }

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 550px;
        margin-top: 3px;
        p {
            margin: 0;
            height: 1.5rem;
            line-height: 30px;
        }
    }

    @media screen and (max-width: 900px) {
        padding-left: 0;
    }
}

.notification-row-show-border {
    border-bottom: 1px solid var(--grey-lightest, #D6D6D6);
    margin-bottom: 24px;
}

.shift-preference-wrapper {
    max-width: 550px;
    .shift-preference-title {
        color:  #3747AB;
        font-size: 16px;
        font-family: Jost;
        margin: 0;
        margin-left: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }
    .shift-pref-options {
        margin-left: 1.5rem;
    }
    .shift-pref-toggle {
        margin-top: -1.5rem;
    }
    .shift-pref-checkboxes {
        display: grid;
        grid-template-columns: auto auto;
        @media screen and (max-width: 900px) {
            grid-template-columns: auto;
        }
    }
}

.shift-pref-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
    margin-left: 1.5rem;
}

.shift-pref-title {
    max-width: 550px;
    display: flex;
    justify-content: space-between;
    img {
        cursor: pointer;
    }
}
