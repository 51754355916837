#root,
body {
  .tab-container {
    .tab-line-search {
      position: absolute;
      top: 0;
      right: 0;
    }
    &.tab-w-auto {
      .react-tabs__tab {
        width: auto;
      }
    }
    position: relative;
    .link {
      position: absolute;
      top: 10px;
      right: 10px;
      letter-spacing: 1px;
      font-weight: 500;
    }
    .react-tabs__tab,
    .table-tab,
    .table-tab-map {
      list-style: none;
      bottom: 0;
      padding: 8px 12px;
      font-size: 22px;
      font-size: 16px;
      font-weight: 500;
      color: $text-secondary;
      border: 0;
      margin-right: 12px;
      border-radius: 10px 10px 0 0;
      width: 170px;
      text-align: center;
      &.react-tabs__tab--selected,
      &.selected {
        color: #2b1a61;
        background: #e8eaf7;
        border: 1px solid #a9b0db;
      }
    }
    .table-tab-list {
      margin: 22px 0;
      padding-bottom: 10px;
    }
    .inner-tab-list {
      margin-top: 0;
      border-radius: 0;
      padding-bottom: 0px;
      .table-tab {
        display: inline-block;
        border-radius: 0;
        width: 266px;
        background: #fff;
        margin-right: 0;
        border-color: #007a4c;
        &.selected {
          background: #007a4c;
          color: #fff;
        }
      }
      .table-tab-map {
        display: inline-block;
        border-radius: 0;
        width: 150px;
        background: #fff;
        margin-right: 0;
        border-color: #007a4c;
        &.selected {
          background: #007a4c;
          color: #fff;
        }
      }
    }
    .react-tabs__tab-list,
    .table-tab-list {
      border-bottom: 2px solid #e6e7ea;
    }
    .table-container .table {
      thead th {
        border-bottom: 1px solid #e6e7ea;
      }
      tbody td {
        .normal-btn {
          padding: 10px 30px !important;
          width: auto !important;
          float: none !important;
        }
        .btn {
          float: right;
          width: 180px;
          min-width: 180px;
          margin-left: 12px;
          padding: 6px 24px;
        }
        &.white-space-no-wrap {
          white-space: nowrap !important;
        }
        &.two-btns {
          & > div {
            width: 390px;
            white-space: nowrap;
            .btn {
              width: 160px;
              min-width: 160px;
            }
          }
        }
      }
    }
    .border-bottom {
      border-bottom: 1px solid #949494;
    }
  }
  .light-tabs {
    border: 1px solid #cfd1e0;
    .inner-tab-list {
      margin-top: 0;
      border-radius: 0;
      padding-bottom: 0px;
      .table-tab,
      .table-tab-map {
        width: 50%;
        background: unset;
        margin-right: 0;
        border: unset;
        color: #5862a3;
        box-shadow: unset;
        &.selected {
          background: unset;
          border-bottom: 3px solid #5862a3;
          color: #5862a3;
        }
      }
    }
  }
}

.summary-card {
  width: 336px;
  height: 112px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: #303031 !important;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 20px 24px;
  border-radius: 16px;
  cursor: pointer;
  max-width: 240px;
  box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
  span {
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    color: #412894;
  }
  div {
    height: 20px;
    color: #fff;
    width: 101px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    padding: 0px 8px;
    border-radius: 4px;
    background: #412894;
    margin: 12px 0;
  }
  &.disabled {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: none;
    cursor: not-allowed;
  }
  &.selected {
    color: #fff !important;
    background-color: #412894;
    span {
      color: #fff;
    }
  }
}

.summary-card-container {
  display: flex;
  gap: 24px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 24px;
  }
  &.hidden {
    opacity: 30%;
  }
}

.roo-tab {
  width: 100%;
  height: 60px;
  border-radius: 12px;
  padding: 8px 12px;
  color: #303031;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  &.selected {
    color: #fff;
    background-color: #412894;
  }
}

.roo-tab-text {
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  color: #303031;
}

.tab-menu-container {
  position: absolute;
  z-index: 999;
  width: 95%;
  top: 181px;
  @media screen and (max-width: 520px) {
    width: 94%;
  }
  @media screen and (max-width: 450px) {
    width: 93%;
  }
  @media screen and (max-width: 400px) {
    width: 92%;
  }
}
