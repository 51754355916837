.login {
  .md-modal {
    max-width: 380px!important;
    height: 281px !important;
    .modal-body {
      width: auto;
      height: 281px;
    }
  }
  .sign-up-container-modal {
    position: relative;
    top: -20px;
    left: 16px;
  }
  .sign-up-container-modal-buttons {
    > div {
      height: 33px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Jost", sans-serif;
      font-size: 20px;
      line-height: 28.9px;
      box-shadow: 0px 2px 4px rgba(65, 40, 148, 0.2);
      :hover {
        opacity: 0.8;
      }
    }
    #cancel-button {
      background: #fff;
      border: 2px solid #412894;
      width: 125px;
      color: #412894;
      margin: 24px 16px 0 16px;
      cursor: pointer;
      @media all and (max-width: 350px) {
        width: 100px;
      }
    }
    #sign-up-button {
      background: #412894;
      width: 135px;
      color: white;
      margin-top: 24px;
      cursor: pointer;
      @media all and (max-width: 350px) {
        width: 110px;
      }
    }
  }
  h2.signup-header {
    color: #412894;
    // margin-top: 10px;
    font-size: 20px;
    line-height: 28.9px;
    text-align: start !important;
    font-weight: 400;
    font-family: "Jost", sans-serif;
    margin-bottom: 0;
    padding: 8px 16px;
    img {
      position: relative;
      left: 205px;
      @media all and (max-width: 350px) {
        left: 120px;
      }
    }
  }
  .sign-up-text-container {
    height: 24px;
    width: 220px;
    margin-bottom: 16px;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    label {
      background: #fff;
      border: 1px solid #3747AB;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      position: relative;
      top: 35px;
      left: -1px;
    }
    div {
      white-space: nowrap;
      margin-left: 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #59595A;
      cursor: pointer;
      position: relative;
    }
    input:checked ~ label {
      background: #3747AB;
      height: 16px;
      width: 16px;
      border-radius: 50%;
    }

  }
}

@media all and (max-width: 576px) {
  .login {
    .modal-body {
      padding: 10px !important;
    }
    h2.signup-header {
      font-size: 16px;
      line-height: 21px;
    }
    .cards-container {
      .card-button {
        padding: 15px 0px !important;
      }
    }
    .card-button {
      margin: 15px 5px 40px;
      padding: 15px 10px !important;
      width: 70px;
      height: 109px;
      font-weight: 500;
      font-size: 7px;
      line-height: 15px;

      img {
        height: 75%;
        margin-bottom: 6px;
        &.vet {
          height: 92%;
          margin-bottom: -5px;
        }
      }
    }
  }
}
