.my-hospitals {
  .hospital-group {
    border-bottom: 1px solid $blue-200;
    margin-bottom: 15px;
    .label-text {
      color: $blue-dark;
    }
  }
  .arrow-nav {
    &.blue {
      background: url("../images/arrow-right-blue.svg") center center no-repeat;
    }
    &.green {
      background: url("../images/arrow-right-blue.svg") center center no-repeat;
    }
  }
}

